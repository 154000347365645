import React, { Component } from 'react';
import {
    Modal,
    Button,
    Icon,
    Message
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Content from '../Content/Content';
import '../Import.scss';
import './ImportModal.scss';

class ImportModal extends Component {
    hasFiles() {
        const { files } = this.props;
        return files.length > 0;
    }

    render() {
        const {
            files,
            isOpen,
            onClose,
            onChange,
            removeFile
        } = this.props;

        return (
            <Modal className="ImportModal" open={isOpen} closeIcon onClose={onClose}>
                <Modal.Header>{`File Import (${files.length})`}</Modal.Header>
                <Modal.Content>
                    {files && files.length ?
                        <Content className="ImportModal__content" isOpen={isOpen} files={files} removeFile={removeFile} /> :
                        <Message className="ImportModal__message">No Files</Message>}
                </Modal.Content>
                <Modal.Actions>
                    <Button as="label" primary icon labelPosition="right" htmlFor="file">
                        Add File
                        <Icon name="upload" />
                    </Button>
                    <input id="file" accept=".pptx" hidden multiple type="file" onChange={onChange} />
                </Modal.Actions>
            </Modal>
        );
    }
}

ImportModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    files: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired
};

export default ImportModal;
