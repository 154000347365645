import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';

const withControlledInput = WrappedComponent => {
    const ControlledInput = class extends React.Component {
        constructor(props) {
            super(props);
            const { value, onChange, delay } = props;
            this.state = {
                value: value !== undefined ? value : '',
                debouncedOnChange: debounce(onChange, delay)
            };
            this.onChange = this.onChange.bind(this);
            this.onBlur = this.onBlur.bind(this);
            this.onError = this.onError.bind(this);
            this.updateDisplay = this.updateDisplay.bind(this);
        }

        // eslint-disable-next-line camelcase
        UNSAFE_componentWillReceiveProps(nextProps) {
            const { value, onChange, delay } = nextProps;
            this.setState({
                value,
                debouncedOnChange: debounce(onChange, delay)
            });
        }

        onChange(event, data) {
            const { value } = data;
            const { debouncedOnChange } = this.state;
            this.updateDisplay(value);
            return debouncedOnChange(event, data);
        }

        updateDisplay(value) {
            if (value !== undefined) {
                this.setState({
                    value
                });
            }
        }

        onBlur(event) {
            const { onBlur } = this.props;
            this.updateDisplay(event.target.value);
            onBlur(event);
        }

        onError(error) {
            const { onError } = this.props;
            const { debouncedOnChange } = this.state;
            debouncedOnChange.cancel();
            onError(error);
        }

        render() {
            const { value } = this.state;
            const otherProps = omit(this.props, ['defaultValue', 'delay']);
            return (
                <WrappedComponent
                    {...otherProps}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onError={this.onError}
                    updateDisplay={this.updateDisplay}
                />
            );
        }
    };

    ControlledInput.propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string,
            PropTypes.number
        ]),
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onError: PropTypes.func,
        delay: PropTypes.number
    };

    ControlledInput.defaultProps = {
        value: '',
        onChange: () => { },
        onBlur: () => { },
        onError: () => { },
        delay: 0
    };

    return ControlledInput;
};

export default withControlledInput;
