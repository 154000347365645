import React, { Component } from 'react';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import {
    Checkbox,
    Icon,
    Button
} from 'semantic-ui-react';
import './Toggle.scss';

const checkboxPropsNames = [
    'label',
    'onMouseEnter',
    'onMouseLeave',
    'toggle'
];

const buttonPropsNames = [
    'onMouseEnter',
    'onMouseLeave'
];

const iconPropsNames = [
    'size',
    'disabled'
];

class DecksignToggle extends Component {
    constructor() {
        super();

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    getCurrentClassName() {
        const { className, toggledClassName, toggled } = this.props;

        if (toggled) {
            return !toggledClassName ? className : toggledClassName;
        }

        return className;
    }

    getCurrentIcon() {
        const { toggledIcon, icon, toggled } = this.props;

        if (toggled) {
            return !toggledIcon ? icon : toggledIcon;
        }

        return icon;
    }

    onFocus() {
        const { onFocus } = this.props;
        onFocus();
    }

    onBlur() {
        const { onBlur } = this.props;
        onBlur();
    }

    onChange() {
        const { onChange, disabled } = this.props;

        if (!disabled) {
            onChange();
        }
    }

    render() {
        const {
            icon,
            toggled
        } = this.props;
        const checkBoxProps = pick(this.props, checkboxPropsNames);
        const buttonProps = pick(this.props, buttonPropsNames);
        const iconProps = pick(this.props, iconPropsNames);

        if (icon) {
            // className={this.getCurrentClassName()}
            return (
                <Button
                    icon
                    toggle
                    active={toggled}
                    onClick={this.onChange}
                    className="toolbar"
                    {...buttonProps}
                >
                    <Icon
                        name={this.getCurrentIcon()}
                        {...iconProps}
                    />
                </Button>
            );
        }

        return (
            <Checkbox
                className={this.getCurrentClassName()}
                onChange={this.onChange}
                checked={toggled}
                {...checkBoxProps}
            />
        );
    }
}

DecksignToggle.defaultProps = {
    toggled: false,
    icon: undefined,
    toggledIcon: undefined,
    className: undefined,
    toggledClassName: undefined,
    label: undefined,
    size: undefined,
    color: 'black',
    toggledColor: 'blue',
    disabled: false,
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {}
};

DecksignToggle.propTypes = {
    toggled: PropTypes.bool,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    toggledIcon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    size: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    toggledClassName: PropTypes.string,
    toggledColor: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
};

export default DecksignToggle;
