import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

const TabMenu = ({
    activeOptionId,
    options
}) => {
    const optionsToRender = [];
    options.forEach(option => optionsToRender.push((
        <Menu.Item
            key={option.id}
            name={option.label}
            active={activeOptionId === option.id}
            onClick={element => option.action(element)}
        />
    )));

    return (
        <Menu pointing secondary>
            {optionsToRender}
        </Menu>
    );
};

TabMenu.propTypes = {
    activeOptionId: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired
    }))
};

TabMenu.defaultProps = {
    activeOptionId: '',
    options: []
};

export default TabMenu;
