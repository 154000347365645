import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';
import Content from '../Content/Content';
import '../Import.scss';
import './ImportPanel.scss';

class ImportPanel extends Component {
    hasFiles() {
        const { files } = this.props;
        return files.length > 0;
    }

    render() {
        const {
            toggleImportPanel,
            isOpen,
            files,
            removeFile,
            handleFiles,
            progress
        } = this.props;

        return (
            <div className={`ImportPanel ImportPanel--panel ImportPanel${this.hasFiles() ? '--isVisible' : ''}`}>
                <Header className="ImportPanel__header" size="medium" onClick={toggleImportPanel}>
                    <div className="ImportPanel__header__actions">
                        <label htmlFor="files">
                            <Icon name="upload" />
                            <input
                                type="file"
                                id="files"
                                name="fileImport"
                                accept=".pptx"
                                multiple
                                onChange={e => { handleFiles(e.target.files); }}
                                disabled={progress === 'Working'}
                            />
                        </label>
                        <Icon className="ImportPanel__header__actions__indicator" name={isOpen ? 'angle down' : 'angle up'} />
                    </div>
                    <div>
                        {`File Import (${files.length})`}
                    </div>
                </Header>
                <Content className={`ImportPanel__content ImportPanel__content${isOpen ? '--isOpen' : ''}`} files={files} removeFile={removeFile} />
            </div>
        );
    }
}

ImportPanel.defaultProps = {
    files: []
};

ImportPanel.propTypes = {
    toggleImportPanel: PropTypes.func.isRequired,
    handleFiles: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    files: PropTypes.array,
    removeFile: PropTypes.func.isRequired,
    progress: PropTypes.string.isRequired
};

export default ImportPanel;
