import React from 'react';
import { Tab, Segment, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InvoicesTable from '../Tables/InvoicesTable';

const InvoicesPane = ({
    invoices, isLoading, error, errorMessage
}) => (
    <Tab.Pane>
        <Segment basic>
            <div className="invoicesContent">
                <div className="invoicesTable">
                    <Message negative content={errorMessage} hidden={!error} />
                    <InvoicesTable invoices={invoices} isLoading={isLoading} />
                </div>
            </div>
        </Segment>
    </Tab.Pane>
);

InvoicesPane.propTypes = {
    invoices: PropTypes.array,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired
};

InvoicesPane.defaultProps = {
    invoices: []
};

export default InvoicesPane;
