import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Form } from 'semantic-ui-react';
import './Toggles.scss';

const Toggles = ({
    id,
    isContainerHidden,
    isContainerLocked,
    isHidden,
    isLocked,
    onShapeLockChange,
    onShapeVisibilityChange,
    isHovering
}) => (

    <Form.Group inline className="navigation__shapes--formGroup">
        <Form.Checkbox
            control="input"
            type="checkbox"
            name="visibility"
            onChange={() => { onShapeVisibilityChange(id); }}
            label={(
                <Icon>
                    {(isHovering && !((isContainerHidden || isHidden))) && (
                        <i className="material-icons">
                            visibility
                        </i>
                    )}

                    {(isContainerHidden || isHidden) ? (
                        <i className="material-icons">
                            visibility_off
                        </i>
                    ) : ''}
                </Icon>
            )}
            hidden
        />
        <Form.Checkbox
            control="input"
            type="checkbox"
            name="lock"
            onChange={() => { onShapeLockChange(id); }}
            label={(
                <Icon>
                    {(isHovering && (!(isContainerLocked || isLocked))) && (
                        <i className="material-icons">
                            lock_open
                        </i>
                    )}

                    {(isContainerLocked || isLocked) ? (
                        <i className="material-icons">
                            lock
                        </i>
                    ) : ''}
                </Icon>
            )}
            hidden
        />
    </Form.Group>
);

Toggles.propTypes = {
    id: PropTypes.string,
    isContainerHidden: PropTypes.bool,
    isContainerLocked: PropTypes.bool,
    isHidden: PropTypes.bool,
    isLocked: PropTypes.bool,
    isHovering: PropTypes.bool,
    onShapeLockChange: PropTypes.func.isRequired,
    onShapeVisibilityChange: PropTypes.func.isRequired
};

Toggles.defaultProps = {
    id: '',
    isContainerHidden: false,
    isContainerLocked: false,
    isHovering: false,
    isHidden: false,
    isLocked: false
};

export default Toggles;
