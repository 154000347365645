import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const Toggle = ({
    onToggle
}) => (
    <div className="settings__container">
        <Button
            size="medium"
            icon
            onClick={() => onToggle('settings')}

        >
            <i className="material-icons">tune</i>
        </Button>
    </div>
);

Toggle.propTypes = {
    onToggle: PropTypes.func.isRequired
};

Toggle.defaultProps = {
};

export default Toggle;
