import React from 'react';
import { Grid, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InteractiveInput from '../../BuildSection/Toolbar/Tools/InteractiveInput/InteractiveInput';

const AccountPaneRow = props => {
    const {
        label,
        name,
        children,
        onSubmit,
        onChange,
        value,
        onCancel
    } = props;
    const rowId = `profileModal-${name}`;
    return (
        <Grid.Row as="form" className={`AccountPane__Form__${name}`}>
            <Grid.Column width={3}>
                <label htmlFor={rowId}>
                    {label}
                </label>
            </Grid.Column>
            <Grid.Column>
                <Form.Input
                    name={name}
                    id={rowId}
                    control={InteractiveInput}
                    value={value}
                    delay={0}
                    onChange={(e, data) => onChange(data.value)}
                    onBlur={onSubmit}
                    onCancel={onCancel}
                    inputType="string"
                />
                {children}
            </Grid.Column>
        </Grid.Row>
    );
};

AccountPaneRow.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
    value: PropTypes.string.isRequired,
    onCancel: PropTypes.func
};

AccountPaneRow.defaultProps = {
    children: null,
    onCancel: Function.prototype
};

export default AccountPaneRow;
