import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Sidebar,
    Ref,
    Tab
} from 'semantic-ui-react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import './TabbedSidebar.scss';

const DraggableTab = DragDropContext(HTML5Backend)(Tab);

class TabbedSidebar extends Component {
    static propTypes = {
        activePaneId: PropTypes.string,
        animation: PropTypes.oneOf(['overlay', 'push', 'scale down', 'uncover', 'slide out', 'slide along']),
        children: PropTypes.node,
        direction: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
        isDraggable: PropTypes.bool,
        onActivePaneChange: PropTypes.func,
        panes: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            menuItem: PropTypes.string.isRequired,
            paneContent: PropTypes.node.isRequired
        })),
        ref: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.element })
        ]),
        renderActiveOnly: PropTypes.bool,
        visible: PropTypes.bool,
        width: PropTypes.oneOf(['very thin', 'thin', 'wide', 'very wide'])
    }

    static defaultProps = {
        activePaneId: '',
        animation: 'push',
        children: null,
        direction: 'left',
        isDraggable: false,
        onActivePaneChange: () => {},
        ref: undefined,
        renderActiveOnly: true,
        visible: false,
        panes: [],
        width: undefined
    }

    constructor(props) {
        super(props);

        this.handleActiveIndexChange = this.handleActiveIndexChange.bind(this);

        this.state = {
            activePaneId: props.activePaneId
        };
    }

    componentDidUpdate(prevProps) {
        const {
            activePaneId
        } = this.props;

        const {
            activePaneId: currentStateActivePaneId
        } = this.state;
        if (prevProps.activePaneId !== activePaneId && activePaneId !== currentStateActivePaneId) {
            // eslint-disable-next-line
            this.setState({
                activePaneId
            });
        }
    }

    get activePaneId() {
        if (this.state) {
            const {
                activePaneId
            } = this.state;

            return activePaneId;
        }

        const {
            activePaneId
        } = this.props;

        return activePaneId;
    }

    get activeIndex() {
        const {
            panes
        } = this.props;

        return panes.findIndex(pane => pane.id === this.activePaneId);
    }

    get isTabMenuVertical() {
        const {
            direction
        } = this.props;

        return ['bottom', 'top'].includes(direction);
    }

    handleActiveIndexChange(event, { activeIndex }) {
        const {
            onActivePaneChange,
            panes
        } = this.props;

        const activePaneId = panes[activeIndex].id;

        onActivePaneChange(activePaneId);

        this.setState({ activePaneId });
    }

    renderPanes() {
        const {
            panes,
            renderActiveOnly
        } = this.props;

        return panes.map(({ menuItem, paneContent, id }) => {
            const paneRenderer = renderActiveOnly ?
                {
                    render: () => (
                        <Tab.Pane key={id} basic>
                            {paneContent}
                        </Tab.Pane>
                    )
                } :
                {
                    pane: (
                        <Tab.Pane key={id} basic>
                            {paneContent}
                        </Tab.Pane>
                    )
                };

            return {
                menuItem,
                active: id === this.activePaneId,
                ...paneRenderer
            };
        });
    }

    render() {
        const {
            animation,
            children,
            direction,
            isDraggable,
            ref,
            renderActiveOnly,
            visible,
            width
        } = this.props;

        const TabParent = isDraggable ? DraggableTab : Tab;

        return (
            <Sidebar.Pushable className="tabbed-sidebar">
                <Ref innerRef={ref}>
                    <Sidebar
                        animation={animation}
                        visible={visible}
                        direction={direction}
                        width={width}
                    >
                        <TabParent
                            menu={{
                                vertical: this.isTabMenuVertical,
                                pointing: true,
                                secondary: true,
                                color: 'blue'
                            }}
                            panes={this.renderPanes()}
                            activeIndex={this.activeIndex}
                            renderActiveOnly={renderActiveOnly}
                            onTabChange={this.handleActiveIndexChange}
                        />
                    </Sidebar>
                </Ref>
                <Sidebar.Pusher>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}

export default TabbedSidebar;
