import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const Download = ({ disabled, onExportAsPDF }) => (
    <div className="download__container">
        <Button
            size="tiny"
            onClick={e => {
                e.currentTarget.blur();
                return onExportAsPDF();
            }}
            disabled={disabled}
        >
            <span>
                DOWNLOAD
            </span>
        </Button>
    </div>
);

Download.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onExportAsPDF: PropTypes.func.isRequired
};

export default Download;
