import React from 'react';
import { AuthorizationTokenContext } from '../AuthorizationTokenProvider/AuthorizationTokenProvider';

const withAuthorizationToken = WrappedComponent => props => (
    <AuthorizationTokenContext.Consumer>
        {authorizationToken => (
            <WrappedComponent
                {...props}
                authorizationToken={authorizationToken}
                isLoadingAuthorizationToken={!authorizationToken}
            />
        )}
    </AuthorizationTokenContext.Consumer>
);

export default withAuthorizationToken;
