import React from 'react';
import PropTypes from 'prop-types';
import withAuthorizationToken from '../withAuthorizationToken/withAuthorizationToken';

const withSignedSrc = WrappedComponent => {
    const WithSignedSrc = class extends React.Component {
        static propTypes = {
            isLoadingAuthorizationToken: PropTypes.bool.isRequired,
            authorizationToken: PropTypes.string,
            src: PropTypes.string
        };

        static defaultProps = {
            authorizationToken: undefined,
            src: undefined
        };

        state = {
            isSignedSrcLoading: false,
            signedSrc: undefined
        }

        componentDidMount() {
            this.fetchSignedUrl();
        }

        componentDidUpdate(prevProps) {
            const { src, authorizationToken } = this.props;
            if (prevProps.src !== src || prevProps.authorizationToken !== authorizationToken) {
                this.setState({ signedSrc: undefined });
            }
            this.fetchSignedUrl();
        }

        fetchSignedUrl() {
            const { isLoadingAuthorizationToken, src, authorizationToken } = this.props;
            const { isSignedSrcLoading, signedSrc } = this.state;
            if (isLoadingAuthorizationToken || isSignedSrcLoading || signedSrc || !src) {
                return undefined;
            }
            if (!src || src.startsWith('data:image')) {
                return this.setState({ signedSrc: src });
            }
            this.setState({ isSignedSrcLoading: true });
            return fetch(src, { headers: { authorization: `Bearer ${authorizationToken}` } })
                .then(response => {
                    if (!response.ok) {
                        return this.setState({ signedSrc: src, isSignedSrcLoading: false });
                    }
                    return response.text();
                })
                .then(url => this.setState({ signedSrc: url.startsWith('http') ? url : src, isSignedSrcLoading: false }))
                .catch(() => this.setState({ signedSrc: src, isSignedSrcLoading: false }));
        }

        render() {
            const { authorizationToken, isLoadingAuthorizationToken, ...props } = this.props;
            const { signedSrc, isSignedSrcLoading } = this.state;
            return (
                <WrappedComponent
                    {...props}
                    src={signedSrc}
                    isLoading={isLoadingAuthorizationToken || isSignedSrcLoading}
                />
            );
        }
    };

    return withAuthorizationToken(WithSignedSrc);
};

export default withSignedSrc;
