import React from 'react';
import PropTypes from 'prop-types';
import './ProgressLabel.scss';
import { Label } from 'semantic-ui-react';

const ProgressLabel = ({ color, text }) => (
    <Label className="Import__content__file__item__progress__status" circular color={color}>
        {text}
    </Label>
);

ProgressLabel.propTypes = {
    color: PropTypes.string,
    text: PropTypes.string
};

ProgressLabel.defaultProps = {
    color: 'grey',
    text: ''
};

export default ProgressLabel;
