import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { labelSizeInPx } from '../feedbackDisplay.config';
import './NegativeFeedbackBar.scss';

const NegativeFeedbackBar = ({
    data, barWidth, offset, needsPopup, totalOfFeedbacks
}) => (
    <div
        style={{
            left: offset
        }}
        className="share-table__feedbacks-graph__negative"
    >
        <div
            style={{
                width: barWidth || 0
            }}
            className="share-table__feedbacks-graph__negative__bar"
        />
        <div
            style={{
                width: labelSizeInPx
            }}
            className="share-table__feedbacks-graph__negative__indicator"
        >
            {
                needsPopup ?
                    (
                        <Popup
                            position="top center"
                            trigger={(
                                <p
                                    className="share-table__feedbacks-graph__negative__popup-trigger"
                                >
                                    ...
                                </p>
                            )}
                            content={totalOfFeedbacks <= 0 ? '' : data}
                        />
                    ) :
                    (
                        <p className="share-table__feedbacks-graph__negative__indicator__count">
                            {totalOfFeedbacks <= 0 ? '' : data}
                        </p>
                    )
            }
        </div>
    </div>
);

NegativeFeedbackBar.propTypes = {
    data: PropTypes.number,
    barWidth: PropTypes.number,
    needsPopup: PropTypes.bool,
    totalOfFeedbacks: PropTypes.number,
    offset: PropTypes.number
};

NegativeFeedbackBar.defaultProps = {
    data: 0,
    barWidth: 5,
    needsPopup: false,
    totalOfFeedbacks: 0,
    offset: 0
};

export default NegativeFeedbackBar;
