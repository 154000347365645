import React from 'react';
import PropTypes from 'prop-types';
import { withFixedIncrement } from './withFixedIncrement';

const arrowIncrementHandler = WrappedComponent => {
    const wrapped = props => {
        const {
            onKeyUp,
            onKeyDown,
            handleIncrement,
            handleDecrement,
            ...cleanedProps
        } = props;
        const { value } = props;
        const onArrowUpDown = event => {
            if (event.key === 'ArrowUp') {
                handleIncrement(value);
                event.preventDefault();
            }
            if (event.key === 'ArrowDown') {
                handleDecrement(value);
                event.preventDefault();
            }
            onKeyDown(event);
        };
        const disableHTML5Arrows = event => {
            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                event.preventDefault();
            }
            onKeyUp(event);
        };
        return <WrappedComponent {...cleanedProps} onKeyUp={disableHTML5Arrows} onKeyDown={onArrowUpDown} />;
    };
    wrapped.propTypes = {
        onKeyUp: PropTypes.func,
        onKeyDown: PropTypes.func,
        handleIncrement: PropTypes.func.isRequired,
        handleDecrement: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ])
    };
    wrapped.defaultProps = {
        onKeyUp: () => { },
        onKeyDown: () => { },
        value: undefined
    };
    return wrapped;
};

export default WrappedComponent => withFixedIncrement(arrowIncrementHandler(WrappedComponent));
