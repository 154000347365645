import React from 'react';
import PropTypes from 'prop-types';

const withValidInputMemory = WrappedComponent => {
    const ValidInputMemory = class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                lastValidInput: props.value
            };
            this.onValidChange = this.onValidChange.bind(this);
            this.onBlur = this.onBlur.bind(this);
            this.onError = this.onError.bind(this);
        }

        // eslint-disable-next-line camelcase
        UNSAFE_componentWillReceiveProps(nextProps) {
            const { error, value } = nextProps;
            if (!error) {
                this.setState({
                    lastValidInput: value
                });
            }
        }

        onValidChange(event, data) {
            const { value } = data;
            const { onChange } = this.props;
            this.setState({ lastValidInput: value });
            if (onChange) {
                onChange(event, data);
            }
        }

        onBlur(event) {
            const { onBlur } = this.props;
            const newEvent = {
                ...event,
                target: {
                    ...event.target,
                    value: this.getLastValidInput()
                }
            };
            if (onBlur) {
                onBlur(newEvent);
            }
        }

        getLastValidInput() {
            const { lastValidInput } = this.state;
            const {
                input, max, min
            } = this.props;
            if (lastValidInput !== undefined) {
                return lastValidInput;
            }
            if (input === 'number') {
                return 0;
            }
            if (input === 'range') {
                return ((max - min) / 2) || 0;
            }
            return '';
        }

        onError(error, fallback, value) {
            const { onError } = this.props;
            if (fallback !== undefined) {
                this.setState({ lastValidInput: fallback });
            }
            if (onError) {
                onError(error, fallback, value);
            }
        }

        render = () => (
            <WrappedComponent
                {...this.props}
                onChange={this.onValidChange}
                onError={this.onError}
                onBlur={this.onBlur}
            />
        );
    };
    ValidInputMemory.propTypes = {
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        input: PropTypes.string.isRequired,
        max: PropTypes.number,
        min: PropTypes.number,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool
        ]),
        error: PropTypes.bool
    };
    ValidInputMemory.defaultProps = {
        max: Number.MAX_SAFE_INTEGER,
        min: Number.MIN_SAFE_INTEGER,
        value: undefined,
        error: undefined
    };
    return ValidInputMemory;
};

export default withValidInputMemory;
