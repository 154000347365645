import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Form,
    Message,
    Grid
} from 'semantic-ui-react';
import AuthHeader from '../Common/AuthHeader/AuthHeader';
import '../index.scss';

const Login = ({
    loading,
    globalError,
    inputChanged,
    authNavConfig,
    passwordError,
    emailError,
    loginSubmit,
    onRedirect
}) => (
    <div className="auth-wrapper">
        <Grid>
            <Grid.Row>
                <Form error onSubmit={loginSubmit}>
                    <Card fluid className="login_form-card">
                        <Card.Content>

                            <AuthHeader
                                onRedirect={onRedirect}
                                pathName="Sign up"
                                path={authNavConfig.signup.path}
                            />

                            <div id="loginFormHeader" className="card-header_content">Sign in to Decksign</div>
                            <Form.Input
                                size="huge"
                                type="email"
                                onChange={inputChanged('email')}
                                label="Email"
                                tabIndex="0"
                                id="email_input_field"
                                required
                            />
                            {emailError && <span className="signup_error_message">This is not a valid email</span>}

                            <div className="password_form">
                                <Form.Input
                                    className="forgot-password_input"
                                    id="password_input_field"
                                    type="password"
                                    label={(
                                        <label htmlFor="password_input" id="password_label">
                                            <span>Password</span>

                                            <a
                                                className="forgot-password"
                                                tabIndex="-1"
                                                role="button"
                                                onClick={() => onRedirect(authNavConfig.forgot.path)}
                                            >
                                                Forgot password?
                                            </a>
                                        </label>
                                    )}
                                    size="huge"
                                    tabIndex="0"
                                    onChange={inputChanged('password')}

                                />
                                {passwordError && <span className="signup_error_message">Password cannot be blank</span>}

                            </div>
                            <Button
                                type="submit"
                                loading={loading}
                                fluid
                                size="large"
                                color="blue"
                                id="loginFormSubmit"
                            >
                                Sign In
                            </Button>
                            {globalError && globalError.type === 'form' && <Message error header={globalError.message} id="loginFormErrorMessage" />}

                        </Card.Content>
                    </Card>
                </Form>
            </Grid.Row>
        </Grid>
    </div>
);

Login.propTypes = {
    authNavConfig: PropTypes.shape({
        forgot: PropTypes.shape({
            path: PropTypes.string
        }),
        signup: PropTypes.shape({
            path: PropTypes.string
        })
    }),
    emailError: PropTypes.bool,
    globalError: PropTypes.shape({
        message: PropTypes.string,
        type: PropTypes.string
    }),
    inputChanged: PropTypes.func,
    loading: PropTypes.bool,
    logo: PropTypes.any,
    passwordError: PropTypes.bool,
    loginSubmit: PropTypes.func,
    onRedirect: PropTypes.func
}.isRequired;

export default Login;
