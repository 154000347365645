import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ToggleDisplay = ({ options, currentView, toggleView }) => (
    <Button.Group
        className="Plan__storyboard__pages__sort"
    >
        {options.map(option => (
            <Button
                key={option.view}
                basic
                icon
                active={option.view === currentView}
                className="Plan__storyboard__pages__sort__btn"
                onClick={() => toggleView(option.view)}
            >
                <Icon
                    name={option.icon}
                    size="large"
                />
            </Button>
        ))}
    </Button.Group>
);

ToggleDisplay.propTypes = {
    options: PropTypes.array.isRequired,
    currentView: PropTypes.string.isRequired,
    toggleView: PropTypes.func.isRequired
};

export default ToggleDisplay;
