import React from 'react';
import PropTypes from 'prop-types';
import LayerShapes from './ShapesLayer/ShapesLayer';
import './Shapes.scss';

const Shapes = ({
    layeredShapes,
    onLayoutShapesCollapseToggle,
    onLayoutShapesLockToggle,
    onGroupCollapseToggle,
    onShapeSelect,
    onShapesSelectionAppend,
    onShapesSelecteAndGrouped,
    onShapeLockChange,
    onShapeVisibilityChange,
    onChangeName,
    onGroupSelectedObjects,
    onSelectAndGroupObjects,
    onShapeDrag,
    onShapeDrop,
    selectedCanvasItems
}) => (
    <div className="buildSection__navigation__shapes">
        <div>
            <LayerShapes
                className="buildSection__navigation__shapes_object"
                key="pageShapes"
                selectedCanvasItems={selectedCanvasItems}
                shapes={layeredShapes.pageShapes.shapes}
                onGroupCollapseToggle={onGroupCollapseToggle}
                onShapeSelect={onShapeSelect}
                onShapesSelectionAppend={onShapesSelectionAppend}
                onShapeLockChange={onShapeLockChange}
                onShapeVisibilityChange={onShapeVisibilityChange}
                onShapeDrag={onShapeDrag}
                onShapeDrop={onShapeDrop}
                onChangeName={onChangeName}
                onGroupSelectedObjects={onGroupSelectedObjects}
                onSelectAndGroupObjects={onSelectAndGroupObjects}
                onShapesSelecteAndGrouped={onShapesSelecteAndGrouped}
                isCollapsed={layeredShapes.layoutShapes.isCollapsed}
            />
        </div>

        <div className="buildSection__navigation__shapes_bg_pl">
            <hr className="divider" />
            <LayerShapes
                key="layoutShapes"
                selectedCanvasItems={selectedCanvasItems}
                shapes={layeredShapes.layoutShapes.shapes}
                onCollapseToggle={onLayoutShapesCollapseToggle}
                onLockToggle={onLayoutShapesLockToggle}
                isLocked={layeredShapes.layoutShapes.isLocked}
                title="Background & Placeholders"
                onGroupCollapseToggle={onGroupCollapseToggle}
                onShapeSelect={onShapeSelect}
                onShapesSelectionAppend={onShapesSelectionAppend}
                onShapeLockChange={onShapeLockChange}
                onShapeVisibilityChange={onShapeVisibilityChange}
                onShapeDrag={onShapeDrag}
                onShapeDrop={onShapeDrop}
                onChangeName={onChangeName}
                onGroupSelectedObjects={onGroupSelectedObjects}
                onSelectAndGroupObjects={onSelectAndGroupObjects}
                isCollapsed={layeredShapes.layoutShapes.isCollapsed}
            />
        </div>
    </div>
);

const shapePropTypes = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    fill: PropTypes.object,
    isCollapsed: PropTypes.bool,
    isLocked: PropTypes.bool,
    isHidden: PropTypes.bool,
    shapes: PropTypes.arrayOf(PropTypes.object)
});

const shapeListLayer = PropTypes.shape({
    isCollapsed: PropTypes.bool.isRequired,
    isLocked: PropTypes.bool.isRequired,
    shapes: PropTypes.arrayOf(shapePropTypes)
});

Shapes.propTypes = {
    layeredShapes: PropTypes.shape({
        layoutShapes: shapeListLayer,
        pageShapes: shapeListLayer
    }),
    onLayoutShapesCollapseToggle: PropTypes.func.isRequired,
    onLayoutShapesLockToggle: PropTypes.func.isRequired,
    onGroupCollapseToggle: PropTypes.func.isRequired,
    onShapeSelect: PropTypes.func.isRequired,
    onShapesSelectionAppend: PropTypes.func.isRequired,
    onShapeLockChange: PropTypes.func.isRequired,
    onShapeVisibilityChange: PropTypes.func.isRequired,
    onShapeDrag: PropTypes.func.isRequired,
    onShapeDrop: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onGroupSelectedObjects: PropTypes.func.isRequired,
    onShapesSelecteAndGrouped: PropTypes.func.isRequired,
    onSelectAndGroupObjects: PropTypes.func.isRequired,
    selectedCanvasItems: PropTypes.arrayOf(PropTypes.string)
};

Shapes.defaultProps = {
    layeredShapes: {
        layoutShapes: {
            isCollapsed: true,
            isLocked: true,
            shapes: []
        },
        pageShapes: {
            isCollapsed: false,
            isLocked: false,
            shapes: []
        }
    },
    selectedCanvasItems: []
};

export default Shapes;
