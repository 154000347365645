import React from 'react';
import PropTypes from 'prop-types';
import {
    List, Divider, Dropdown
} from 'semantic-ui-react';
import { DragSource, DropTarget } from 'react-dnd';
import get from 'lodash/get';
import './Topic.scss';

const Types = {
    TOPIC: 'topic'
};

const topicTarget = {
    hover(props, monitor) {
        if (props.id === monitor.getItem().id) {
            props.hoverChange(null, null);
            return;
        }
        const source = monitor.getItem();
        props.hoverChange(props.id, (source.index > props.index ? 'above' : 'below'));
    },
    drop(props) {
        return {
            id: props.id,
            index: props.index
        };
    }
};

const topicSource = {
    beginDrag(props) {
        props.onDrag(props.id);
        return {
            id: props.id,
            index: props.index
        };
    },
    endDrag(props, monitor) {
        props.hoverChange(null, null);
        if (!monitor.didDrop()) {
            return;
        }
        const source = monitor.getItem();
        const target = monitor.getDropResult();
        if (source.id === target.id) {
            return;
        }
        props.onDrop(source.id, target.id, (source.index > target.index ? 'above' : 'below'));
    }
};

const collectTarget = connect => ({
    connectDropTarget: connect.dropTarget()
});

const collectSource = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
});

const ContextualMenus = ({
    open, item, options, side
}) => (
    <Dropdown
        options={options}
        open={open}
        id={`${item?._id}-left`}
        className={`Dropdown__Contexual__${side}`}
        icon={null}
    />
);

ContextualMenus.propTypes = {
    item: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ).isRequired,
    side: PropTypes.oneOf(['Left', 'Right']).isRequired
};

const Topic = ({
    connectDragSource,
    connectDropTarget,
    currentTopicHoverId,
    hoverRelativePosition,
    id,
    isDisabled,
    isDragging,
    isSelected,
    leftSideMenuOptions,
    onActivePageChange,
    onMouseEnter,
    pageNumber,
    page,
    topic,
    open
}) => connectDropTarget(
    <div className="dropTarget">
        {connectDragSource(
            <div className="dragSource">
                {(currentTopicHoverId === id && hoverRelativePosition === 'above') ?
                    <Divider /> :
                    null}
                <Dropdown
                    options={leftSideMenuOptions}
                    open={open}
                    onMouseEnter={() => onMouseEnter(page)}
                    className="Dropdown__Contexual__Left"
                    icon={null}
                    onClick={e => { e.stopPropagation(); onActivePageChange(id); }}
                    closeOnEscape
                    trigger={(
                        <List.Item
                            className={
                                `buildSection__navigation__topic ${isSelected ? 'buildSection__navigation__topic--selected' : ''
                                }${isDragging ? 'buildSection__navigation__topic--dragging' : ''
                                }`.trim()
                            }
                            disabled={isDisabled}

                        >
                            <List.Content className="PageNumber">{isDisabled ? '' : pageNumber}</List.Content>
                            <List.Content
                                className="buildSection__navigation__topic__content"
                            >
                                {isDisabled ? <span> Hidden Topic</span> : (
                                    <div>
                                        {get(topic, 'label', '')}

                                    </div>
                                )}
                            </List.Content>
                        </List.Item>
                    )}
                />
                {(currentTopicHoverId === id && hoverRelativePosition === 'below') ?
                    <Divider /> :
                    null}
            </div>
        )}
    </div>
);

Topic.propTypes = {
    id: PropTypes.string,
    isSelected: PropTypes.bool,
    leftSideMenuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    onActivePageChange: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    onDrag: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    onDrop: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    currentTopicHoverId: PropTypes.string.isRequired,
    hoverRelativePosition: PropTypes.string.isRequired,
    isDragging: PropTypes.bool.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    pageNumber: PropTypes.number,
    topic: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string
    }),
    isDisabled: PropTypes.bool
};

Topic.defaultProps = {
    id: '',
    leftSideMenuOptions: [],
    pageNumber: 0,
    topic: {
        id: '',
        label: ''
    },
    isSelected: false,
    isDisabled: false
};

export default DropTarget(Types.TOPIC, topicTarget, collectTarget)(
    DragSource(Types.TOPIC, topicSource, collectSource)(Topic)
);
