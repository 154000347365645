import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Tab } from 'semantic-ui-react';
import './Insert.scss';

const Insert = ({ isOpen, panes, onClose }) => (
    <Modal centered={false} className="WorkspaceHeader__insert" open={isOpen} onClose={onClose}>
        <Tab className="WorkspaceHeader__insert__tab" menu={{ secondary: true, pointing: true }} panes={panes} defaultActiveIndex={2} />
    </Modal>
);

Insert.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    panes: PropTypes.array.isRequired
};

export default Insert;
