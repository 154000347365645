import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import Button from '../Button';
import './ButtonWithPopup.scss';

class ButtonWithPopup extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        header: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]),
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        image: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string
        }),
        isOpen: PropTypes.bool,
        materialIcon: PropTypes.string,
        onClose: PropTypes.func,
        onOpen: PropTypes.func,
        position: PropTypes.oneOf([
            'bottom center',
            'bottom left',
            'bottom right',
            'left center',
            'right center',
            'top center',
            'top left',
            'top right'
        ]),
        size: PropTypes.string,
        triggerText: PropTypes.string
    }

    static defaultProps = {
        children: null,
        className: '',
        disabled: false,
        header: '',
        icon: '',
        iconColor: '',
        image: undefined,
        isOpen: false,
        materialIcon: '',
        onClose: () => {},
        onOpen: () => {},
        position: 'bottom left',
        size: '',
        triggerText: ''
    }

    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            isOpen: props.isOpen
        };
    }

    componentDidUpdate(prevProps) {
        const {
            isOpen: isOpenState
        } = this.state;

        const {
            isOpen
        } = this.props;

        if (prevProps.isOpen !== isOpen && isOpen !== isOpenState) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isOpen
            });
        }
    }

    handleOpen() {
        const {
            onOpen
        } = this.props;

        this.setState({ isOpen: true }, onOpen);
    }

    handleClose() {
        const {
            onClose
        } = this.props;
        this.setState({ isOpen: false }, onClose);
    }

    render() {
        const {
            children,
            className,
            disabled,
            header,
            icon,
            iconColor,
            image,
            materialIcon,
            position,
            size,
            triggerText
        } = this.props;

        const {
            isOpen
        } = this.state;

        return (
            <Popup
                basic
                className={`${className} buttonWithPopup`.trim()}
                flowing
                on="click"
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                open={isOpen}
                position={position}
                trigger={(
                    <Button
                        color={iconColor}
                        className={className ? `${className} buttonWithPopup__trigger` : ''}
                        disabled={disabled}
                        icon={icon}
                        image={image}
                        materialIcon={materialIcon}
                        size={size}
                        text={triggerText}
                    />
                )}
            >
                {header && (
                    <Popup.Header
                        className={className ? `${className}__header` : ''}
                    >
                        {header}
                    </Popup.Header>
                )}
                <Popup.Content
                    className={className ? `${className}__content` : ''}
                >
                    {children}
                </Popup.Content>
            </Popup>
        );
    }
}

export default ButtonWithPopup;
