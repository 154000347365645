import React from 'react';
import { PropTypes } from 'prop-types';
import omit from 'lodash/omit';
import { Button } from 'semantic-ui-react';

import IntInput from './InputTypes/IntInput';
import FloatInput from './InputTypes/FloatInput';
import StringInput from './InputTypes/StringInput';
import ListInput from './InputTypes/ListInput';
import SliderInput from './InputTypes/SliderInput';
import SelectionInput from './InputTypes/SelectionInput';
import TextAreaInput from './InputTypes/TextAreaInput';
import './InteractiveInput.scss';

const generateTopLabel = content => (content.length > 0 ?
    ({
        content, basic: true, attached: 'top', className: 'toolbar'
    }) :
    undefined);

const cancelIcon = onCancel => (
    onCancel ?
        (
            <Button
                className="interactiveInputCancel"
                icon="cancel"
                onMouseDown={onCancel} // Needs to be on Mouse down to fire before the input onBlur
                onClick={event => event.preventDefault()}
            />
        ) :
        null
);

const InteractiveInput = props => {
    const {
        inputType,
        label,
        onCancel,
        ...cleanedProps
    } = omit(props, ['inputSize']);
    switch (inputType) {
        case 'int':
            return (
                <IntInput
                    {...cleanedProps}
                    input="number"
                    label={generateTopLabel(label)}
                    precision={0}
                    action={cancelIcon(onCancel)}
                    onCancel={onCancel}
                />
            );
        case 'string':
            return (
                <StringInput
                    {...cleanedProps}
                    input="text"
                    label={generateTopLabel(label)}
                    action={cancelIcon(onCancel)}
                    onCancel={onCancel}
                />
            );
        case 'textArea':
            return (
                <TextAreaInput
                    {...cleanedProps}
                    input="text"
                    label={generateTopLabel(label)}
                    action={cancelIcon(onCancel)}
                    onCancel={onCancel}
                />
            );
        case 'list':
            return (
                <ListInput
                    {...cleanedProps}
                    label={label}
                />
            );
        case 'selection':
            return (
                <SelectionInput
                    {...cleanedProps}
                    input="file"
                    label={generateTopLabel(label)}
                    action={cancelIcon(onCancel)}
                    onCancel={onCancel}
                />
            );
        case 'slider':
            return (
                <SliderInput
                    {...cleanedProps}
                    input="range"
                    label={generateTopLabel(label)}
                    onCancel={onCancel}
                />
            );
        case 'float':
        default:
            return (
                <FloatInput
                    {...cleanedProps}
                    input="number"
                    label={generateTopLabel(label)}
                    action={cancelIcon(onCancel)}
                    onCancel={onCancel}
                />
            );
    }
};

InteractiveInput.propTypes = {
    inputType: PropTypes.oneOf(['int', 'float', 'string', 'list', 'slider', 'selection', 'textArea']),
    min: PropTypes.number,
    max: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.array
};
InteractiveInput.defaultProps = {
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    minLength: 0,
    maxLength: 100,
    label: '',
    inputType: 'string',
    disabled: false,
    options: []
};

export default React.memo(InteractiveInput);
