import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-unknown-property */
const Trapezoid = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" className="Common__SideNav__Container__object__img">
        <polygon fill={fill} fillRule="evenodd" points="10 0 0 55 55 55 45 0" />
    </svg>
);
/* eslint-enable react/no-unknown-property */

Trapezoid.propTypes = {
    fill: PropTypes.string
};

Trapezoid.defaultProps = {
    fill: ''
};

export default Trapezoid;
