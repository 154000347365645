import React from 'react';
import PropTypes from 'prop-types';
import isFinite from 'lodash/isFinite';
import isInteger from 'lodash/isInteger';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';

const greaterThan = (value, props) => {
    const numericalValue = toNumber(value);
    if (isNumber(numericalValue) && numericalValue >= props.min) {
        return true;
    }
    return false;
};
const minFallback = props => props.min;
const MIN_VALIDATION_ERROR = (value, props) => `Input ${value} must be greater or equal to ${props.min}.`;

const lesserThan = (value, props) => {
    const numericalValue = toNumber(value);
    if (isNumber(numericalValue) && numericalValue <= props.max) {
        return true;
    }
    return false;
};
const maxFallback = props => props.max;
const MAX_VALIDATION_ERROR = (value, props) => `Input ${value} must be lesser or equal to ${props.max}.`;

const isInt = value => isInteger(Number(value)) && value !== null;
const ISINT_VALIDATION_ERROR = value => `Input ${value} is not an integer.`;

const isNotEmptyString = value => !((typeof value === 'string') && value.length <= 0);
const ISEMPTY_VALIDATION_ERROR = () => 'Input is an empty string.';

const isString = value => (typeof value === 'string');
const IS_STRING_VALIDATION_ERROR = () => 'Input must be a string.';

const isMaxLengthString = (value, props) => (value.length <= props.maxLength);
const IS_MAXLENGTH_VALIDATION_ERROR = (value, props) => `Input length must be at most ${props.maxLength} characters long.`;

const isMinLengthString = (value, props) => (value.length >= props.minLength);
const IS_MINLENGTH_VALIDATION_ERROR = (value, props) => `Input length must be at least ${props.minLength} characters long.`;

const isFloat = value => isFinite(Number(value)) && value !== null;
const ISFLOAT_VALIDATION_ERROR = value => `Input ${value} is not a number.`;

const withValidator = (
    condition = () => true,
    errorMessage = () => 'Unknown Validation Error.',
    fallback = () => { }
) => WrappedComponent => {
    const wrapped = props => {
        const { onChange, onError } = props;
        const validate = (event, data) => {
            const { value } = data;
            if (!condition(value, props)) {
                if (onError) {
                    onError(errorMessage(value, props), fallback(props), value);
                } else {
                    throw new Error(errorMessage(value, props));
                }
            } else {
                onChange(event, data);
            }
        };
        return (
            <WrappedComponent {...props} onChange={(e, d) => validate(e, d)} />
        );
    };
    wrapped.propTypes = {
        onChange: PropTypes.func,
        onError: PropTypes.func
    };
    wrapped.defaultProps = {
        onChange: () => { },
        onError: undefined
    };
    return wrapped;
};

const withCustomValidators = WrappedComponent => {
    const wrapped = props => {
        const { validators = [], ...cleanedProps } = props;
        const { onError, onChange } = props;
        const validateCustom = (
            event,
            data,
            condition = () => true,
            errorMessage = () => 'Unknown Validation Error.',
            fallback = () => { }
        ) => {
            const { value } = data;
            if (condition(value, props)) {
                return false;
            }
            return {
                errorMessage: errorMessage(value, props),
                fallback: fallback(props),
                value
            };
        };
        const validateAll = (event, data) => {
            const validationError = validators.reduce((error, validator) => {
                if (error) return error;
                const { condition, errorMessage, fallback } = validator;
                return validateCustom(event, data, condition, errorMessage, fallback);
            }, false);
            if (!validationError) {
                if (onChange) {
                    onChange(event, data);
                }
            } else {
                const { errorMessage, fallback, value } = validationError;
                if (onError) {
                    onError(errorMessage, fallback, value);
                } else {
                    throw new Error(errorMessage);
                }
            }
        };

        return (
            <WrappedComponent {...cleanedProps} onChange={(e, d) => validateAll(e, d)} />
        );
    };
    wrapped.propTypes = {
        onChange: PropTypes.func,
        onError: PropTypes.func,
        validators: PropTypes.arrayOf(PropTypes.shape({
            condition: PropTypes.func,
            errorMessage: PropTypes.func,
            fallback: PropTypes.func
        }))
    };
    wrapped.defaultProps = {
        onChange: () => { },
        onError: undefined,
        validators: []
    };
    return wrapped;
};

const withIsNotEmpty = withValidator(isNotEmptyString, ISEMPTY_VALIDATION_ERROR);
const withInputGreaterThan = withValidator(greaterThan, MIN_VALIDATION_ERROR, minFallback);
const withInputLesserThan = withValidator(lesserThan, MAX_VALIDATION_ERROR, maxFallback);
const withIsString = withValidator(isString, IS_STRING_VALIDATION_ERROR);
const withStringLengthGreaterThan =
    WrappedComponent => withValidator(isMinLengthString, IS_MINLENGTH_VALIDATION_ERROR)(withIsString(WrappedComponent));
const withStringLengthLesserThan =
    WrappedComponent => withValidator(isMaxLengthString, IS_MAXLENGTH_VALIDATION_ERROR)(withIsString(WrappedComponent));
const withInt =
    WrappedComponent => withValidator(isInt, ISINT_VALIDATION_ERROR)(withIsNotEmpty(WrappedComponent));
const withFloat =
    WrappedComponent => withValidator(isFloat, ISFLOAT_VALIDATION_ERROR)(withIsNotEmpty(WrappedComponent));
const withBoundedInput =
    WrappedComponent => withInputGreaterThan(withInputLesserThan(WrappedComponent));
const withBoundedString =
    WrappedComponent => withStringLengthGreaterThan(withStringLengthLesserThan(WrappedComponent));

export {
    withInt,
    withBoundedInput,
    withInputGreaterThan,
    withInputLesserThan,
    withFloat,
    withIsNotEmpty,
    withBoundedString,
    withStringLengthGreaterThan,
    withStringLengthLesserThan,
    withIsString,
    withCustomValidators
};
