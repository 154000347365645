import React from 'react';
import PropTypes from 'prop-types';
import Navigation from './Navigation/Navigation';
import Pages from './Navigation/Pages/Pages';
import NavigationMenu from './Navigation/NavigationMenu/NavigationMenu';
import Toolbar from './Toolbar/ToolBar';
import InteractiveInput from './Toolbar/Tools/InteractiveInput/InteractiveInput';
import Button from './Toolbar/Tools/Button/Button';
import ButtonWithPopup from './Toolbar/Tools/Button/ButtonWithPopup/ButtonWithPopup';
import ButtonWithForm from './Toolbar/Tools/Button/ButtonWithForm/ButtonWithForm';
import Toggle from './Toolbar/Tools/Toggle/Toggle';
import ToolGroupLayout from './Toolbar/Tools/ToolGroupLayout';
import FitAndZoom from './WorkspaceHeader/FitAndZoom/FitAndZoom';
import WorkspaceHeader from './WorkspaceHeader/WorkspaceHeader';

const BuildSection = ({ children, action, pages }) => (
    <div style={{ display: 'flex', height: '100vh', width: '100vw' }}>
        <div style={{ width: '235px' }}>
            <div className="BuildGrid__Header__Toolbar">
                <NavigationMenu onActiveViewChange={action('onActiveViewChange')} activeView="pages" isNavigationVisible />
            </div>
            <div className="BuildGrid__Content">
                <Navigation
                    activeView="pages"
                    visible
                    PageComponent={(
                        <Pages
                            onActivePageChange={action('onActivePageChange')}
                            leftSideMenuOptions={[
                                {
                                    icon: 'copy outline',
                                    key: 'DUPLICATE',
                                    type: 'action',
                                    onClick: action('duplicatePage')
                                },
                                {
                                    icon: 'hide',
                                    key: 'HIDE',
                                    type: 'action',
                                    onClick: action('hidePage')
                                },
                                {
                                    icon: 'delete',
                                    key: 'DELETE',
                                    type: 'action',
                                    onClick: action('deletePage')
                                }
                            ]}
                            pages={pages}
                            onPageDrag={action('onPageDrag')}
                            onPageDrop={action('onPageDrop')}
                            activePageId={{
                                control: {
                                    type: 'select'
                                },
                                options: ['']
                            }}
                        />
                    )}
                />
            </div>
            ;
        </div>
        <Toolbar>
            {children}
        </Toolbar>
    </div>
);

BuildSection.propTypes = {
    action: PropTypes.func,
    children: PropTypes.node,
    pages: PropTypes.array
};

BuildSection.defaultProps = {
    action: {},
    children: null,
    pages: []
};

BuildSection.Navigation = Navigation;
BuildSection.InteractiveInput = InteractiveInput;
BuildSection.ToolGroupLayout = ToolGroupLayout;
BuildSection.Button = Button;
BuildSection.Toggle = Toggle;
BuildSection.ButtonWithPopup = ButtonWithPopup;
BuildSection.ButtonWithForm = ButtonWithForm;
BuildSection.WorkspaceHeader = WorkspaceHeader;
BuildSection.FitAndZoom = FitAndZoom;

export default BuildSection;
