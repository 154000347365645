import { Input } from 'semantic-ui-react';
import { withFloat, withBoundedInput, withCustomValidators } from '../Validators/Validators';
import { withFixedPrecision, withCustomTransformers, toNumberInput } from '../Transformers/Transformers';
import withErrorRegistering from '../Validators/withErrorRegistering';
import withValidInputMemory from '../Validators/withValidInputMemory';
import withControlledInput from './withControlledInput';
import arrowIncrementHandler from '../Modifiers/withArrowIncrementHandler';
import withWheelIncrementHandler from '../Modifiers/withWheelIncrementHandler';
import withDragIncrementHandler from '../Modifiers/withDragIncrementHandler';
import withExitOnReturn from '../Modifiers/withExitOnReturn';
import withCancelOnEsc from '../Modifiers/withCancelOnEsc';
import withActivatable from './withActivatable';

const withIncrements =
    withWheelIncrementHandler(
        withDragIncrementHandler(
            arrowIncrementHandler(
                withCancelOnEsc(withExitOnReturn(Input))
            )
        )
    );
const withValidation =
    withBoundedInput(
        withCustomValidators(
            withFloat(withIncrements)
        )
    );
const withTransforms =
    withCustomTransformers(
        withFixedPrecision(
            toNumberInput(withValidation)
        )
    );
const withMemory = withValidInputMemory(withTransforms);
const withErrorHandling = withErrorRegistering(withMemory);

export default withActivatable(withControlledInput(withErrorHandling));
