import React from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    Header,

    List
} from 'semantic-ui-react';
import './Comments.scss';
import Comment from './Comment/Comment';

class Comments extends React.Component {
    static propTypes = {
        comments: PropTypes.arrayOf(PropTypes.string),
        onComment: PropTypes.func.isRequired,
        onDeleteComment: PropTypes.func.isRequired,
        onEditComment: PropTypes.func.isRequired,
        permission: PropTypes.bool
    }.isRequired;

    static defaultProps = {
        comments: [],
        permission: true
    };

    constructor(props) {
        super(props);
        this.state = {
            text: '',
            oldMessage: '',
            editing: false,
            textAreaRows: 1,
            textAreaMinRows: 1,
            textAreaMaxRows: 3
        };

        this.onComment = this.onComment.bind(this);
        this.onEditComment = this.onEditComment.bind(this);
    }

    componentDidUpdate() {
        const commentsList = document.getElementById('comments__list');
        commentsList.scrollTop = commentsList.scrollHeight;
    }

    onComment(text, editing) {
        if (!editing) {
            const { onComment } = this.props;
            onComment(text);
        } else {
            const { onEditComment } = this.props;
            const { oldMessage } = this.state;
            onEditComment(oldMessage, text);
        }

        this.setState({
            text: '',
            editing: false,
            oldMessage: ''
        });
    }

    onEditComment(message) {
        this.setState({
            text: message.message,
            oldMessage: message,
            editing: true
        });
    }

    onSubmit = e => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            const { text, editing } = this.state;
            this.onComment(text, editing);
        }
    }

    handleChange(e) {
        const event = e;
        const { textAreaMinRows, textAreaMaxRows } = this.state;

        const prevRows = event.target.rows;
        event.target.rows = textAreaMinRows; // reset number of rows in textarea
        /* eslint no-bitwise: [2, { allow: ["~"] }] */

        const currentRows = ~~(event.target.scrollHeight / 24);

        if (currentRows === prevRows) {
            event.target.textAreaRows = currentRows;
        }

        if (currentRows >= textAreaMaxRows) {
            event.target.rows = textAreaMaxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        this.setState({
            textAreaRows: currentRows < textAreaMaxRows ? currentRows : textAreaMaxRows,
            text: event.target.value
        });
    }

    render() {
        const {
            comments,
            permission,
            onDeleteComment
        } = this.props;
        const { text, textAreaRows } = this.state;
        return (
            <Form.Field className="comments__container">
                <Header>
                    <Header.Content>
                        Comments (
                        <span>{comments.length}</span>
                        )
                    </Header.Content>
                </Header>

                <List id="comments__list">
                    {comments.map(data => (
                        <Comment
                            onDeleteComment={onDeleteComment}
                            comment={data}
                            onEditComment={this.onEditComment}
                            key={data?.id}
                            message={data?.message}
                        />
                    ))}
                </List>

                <Form.TextArea
                    className="comments__container__textarea"
                    disabled={!permission}
                    onKeyDown={this.onSubmit}
                    rows={textAreaRows}
                    onChange={e => { this.handleChange(e); }}
                    value={text}
                />
            </Form.Field>
        );
    }
}

export default Comments;
