import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import PageCard from '../../../Common/PageCard/PageCard';

const getLayerStyles = (x, y) => ({
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: `-${x}px`,
    top: `-${y}px`,
    width: '100%',
    height: '100%'
});

const getItemStyles = props => {
    const { initialOffset, clientOffset } = props;
    if (!initialOffset || !clientOffset) {
        return {
            display: 'none'
        };
    }
    const { x, y } = clientOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform
    };
};

const CustomDragLayer = props => {
    const { item, isDragging } = props;
    const [containerOffset, setContainerOffset] = useState({ x: 0, y: 0 });

    const getOffset = element => {
        let el = element;

        let offsetLeft = 0;
        let offsetTop = 0;

        while (el) {
            offsetLeft += el.offsetLeft;
            offsetTop += el.offsetTop;

            el = el.offsetParent;
        }

        return [offsetLeft, offsetTop];
    };

    useEffect(() => {
        const elem = document.getElementsByClassName('Plan__storyboard__content__wrapper').item(0);
        const [offsetLeft, offsetTop] = getOffset(elem);
        setContainerOffset({ x: offsetLeft, y: offsetTop });
    }, [isDragging]);

    if (!isDragging) {
        return null;
    }

    return (
        <div style={getLayerStyles(containerOffset.x, containerOffset.y)}>
            <div style={getItemStyles(props, containerOffset)}>
                <PageCard item={item.page} isSelected={item.isSelected} fullWidth={item.fullWidth} withTextContent />
            </div>
        </div>
    );
};

CustomDragLayer.propTypes = {
    item: PropTypes.object.isRequired,
    isDragging: PropTypes.bool
};

CustomDragLayer.defaultProps = {
    isDragging: false
};

export const PageCardDragLayer = DragLayer(monitor => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    clientOffset: monitor.getClientOffset()
}))(CustomDragLayer);
