import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import get from 'lodash/get';

const IconFromPath = ({
    iconProps, fill, Path, ...props
}) => (
    <Icon {...iconProps}>
        <Path fill={get(fill, 'color.value', '#6f97df')} {...props} />
    </Icon>
);

IconFromPath.propTypes = {
    iconProps: PropTypes.object,
    Path: PropTypes.elementType.isRequired, // eslint-disable-line
    fill: PropTypes.shape({
        color: PropTypes.shape({
            value: PropTypes.string
        })
    })
};

IconFromPath.defaultProps = {
    iconProps: {},
    fill: {
        color: {
            value: '#6f97df'
        }
    }
};

export default IconFromPath;
