import BuildSection from './BuildSection/BuildSection';
import AsideDetails from './AsideDetails/AsideDetails';
import ImportPanel from './Import/ImportPanel/ImportPanel';
import ImportModal from './Import/ImportModal/ImportModal';
import Profile from './Profile';
import ShareSection from './ShareSection/ShareSection';
import TabbedSidebar from './Common/TabbedSidebar/TabbedSidebar';
import DraggableContextProvider from './Common/DraggableContextProvider/DraggableContextProvider';
import StoryboardList from './PlanStoryboard/StoryboardList/StoryboardList';
import DetailsPanel from './DetailsPanel/DetailsPanel';
import Insert from './BuildSection/WorkspaceHeader/Insert/Insert';
import ShapePane from './BuildSection/WorkspaceHeader/Insert/ShapePane/ShapePane';
import PagePane from './BuildSection/WorkspaceHeader/Insert/PagePane/PagePane';
import ImagePane from './BuildSection/WorkspaceHeader/Insert/ImagePane/ImagePane';
import TablePane from './BuildSection/WorkspaceHeader/Insert/TablePane/TablePane';
import QuickInsert from './BuildSection/WorkspaceHeader/Insert/QuickInsert/QuickInsert';
import EyeDropper from './ColorPicker/EyeDropper/EyeDropper';
import Viewer from './Viewer/Viewer';
import ExportModal from './ExportModal/ExportModal';
import LogoDropdown from './Header/LogoDropdown/LogoDropdown';
import PageCard from './Common/PageCard/PageCard';
import ImportImageFile from './BuildSection/ImportImageFile/ImportImageFile';
import Login from './Authentication/Login/Login';
import SignUp from './Authentication/SignUp/SignUp';
import BackToLogin from './Authentication/Common/BackToLogin';
import SuccessPage from './Authentication/SuccessPage/SuccessPage';
import ForgotPassword from './Authentication/ForgotPassword/ForgotPassword';
import RecoverPassword from './Authentication/RecoverPassword/RecoverPassword';
import Welcome from './Authentication/Welcome/Welcome';
import Header from './Header/Header';
import SubNav from './Header/SubNav/SubNav';
import withAuthorizationToken from './withAuthorizationToken/withAuthorizationToken';
import AuthorizationTokenProvider from './AuthorizationTokenProvider/AuthorizationTokenProvider';
import Navigation from './BuildSection/Navigation/Navigation';
import EditableTitle from './Header/EditableTitle/EditableTitle';
import HandledImage from './HandledImage/HandledImage';

export default {
    AsideDetails,
    AuthorizationTokenProvider,
    BuildSection,
    BackToLogin,
    DetailsPanel,
    DraggableContextProvider,
    EditableTitle,
    ExportModal,
    EyeDropper,
    ForgotPassword,
    Header,
    HandledImage,
    ImagePane,
    ImportImageFile,
    ImportModal,
    ImportPanel,
    Insert,
    LogoDropdown,
    Login,
    Navigation,
    PageCard,
    PagePane,
    RecoverPassword,
    Profile,
    QuickInsert,
    ShapePane,
    SignUp,
    SubNav,
    SuccessPage,
    ShareSection,
    StoryboardList,
    TabbedSidebar,
    TablePane,
    Viewer,
    Welcome,
    withAuthorizationToken
};
