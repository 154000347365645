import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import ArrowIcon from './ArrowIcon/ArrowIcon';
import ArrowsFavoriteIcon from './ArrowsFavoriteIcon/ArrowsFavoriteIcon';
import CalloutIcon from './CalloutIcon/CalloutIcon';
import ChevronIcon from './ChevronIcon/ChevronIcon';
import EllipseIcon from './EllipseIcon/EllipseIcon';
import FunnelIcon from './FunnelIcon/FunnelIcon';
import GroupIcon from './GroupIcon/GroupIcon';
import ImageIcon from './ImageIcon/ImageIcon';
import LineIcon from './LineIcon/LineIcon';
import ListIcon from './ListIcon/ListIcon';
import PolygonIcon from './PolygonIcon/PolygonIcon';
import RectangleIcon from './RectangleIcon/RectangleIcon';
import RegularPolygonIcon from './RegularPolygonIcon/RegularPolygonIcon';
import TableIcon from './TableIcon/TableIcon';
import TextboxIcon from './TextboxIcon/TextboxIcon';
import TrapezoidIcon from './TrapezoidIcon/TrapezoidIcon';
import TriangleIcon from './TriangleIcon/TriangleIcon';
import TrianglesFavoriteIcon from './TrianglesFavoriteIcon/TrianglesFavoriteIcon';
import UnknownTypeIcon from './UnknownTypeIcon/UnknownTypeIcon';
import './ShapeIcon.scss';

const pathNameRegexps = [
    {
        regexp: /\btext/i,
        class: TextboxIcon
    },
    {
        regexp: /\b(rect(angle)?|diamond|parallelogram)\b/i,
        class: RectangleIcon
    },
    {
        regexp: /\b(circle|ellipse|oval)\b/i,
        class: EllipseIcon
    },
    {
        regexp: /\b(line|connector)\b/,
        class: LineIcon
    },
    {
        regexp: /\Bagon\b/,
        class: PolygonIcon
    },
    {
        regexp: /\btriangle\b/i,
        class: TriangleIcon
    }
];

class ShapeIcon extends React.Component {
    getPathIconClass() {
        const {
            textBody,
            name
        } = this.props;

        const regexpMatch = pathNameRegexps.find(({ regexp }) => regexp.test(name));

        if (regexpMatch) {
            return regexpMatch.class;
        }

        if (textBody && textBody.text) {
            return TextboxIcon;
        }

        return LineIcon;
    }

    getIconClass() {
        const {
            type
        } = this.props;

        switch (type.toLowerCase()) {
            case 'arrow':
                return ArrowIcon;
            case 'arrows':
                return ArrowsFavoriteIcon;
            case 'callout':
                return CalloutIcon;
            case 'chevron':
                return ChevronIcon;
            case 'circle':
            case 'ellipse':
            case 'oval':
                return EllipseIcon;
            case 'connector':
            case 'line':
                return LineIcon;
            case 'diamond':
            case 'parallelogram':
            case 'rect':
            case 'rectangle':
                return RectangleIcon;
            case 'funnel':
                return FunnelIcon;
            case 'group':
                return GroupIcon;
            case 'image':
                return ImageIcon;
            case 'list':
                return ListIcon;
            case 'path':
                return this.getPathIconClass();
            case 'polygon':
                return PolygonIcon;
            case 'regularpolygon':
                return RegularPolygonIcon;
            case 'table':
                return TableIcon;
            case 'text':
            case 'textbox':
                return TextboxIcon;
            case 'trapezoid':
                return TrapezoidIcon;
            case 'triangle':
                return TriangleIcon;
            case 'triangles':
                return TrianglesFavoriteIcon;
            default:
                return UnknownTypeIcon;
        }
    }

    render() {
        const {
            onClick
        } = this.props;

        const IconClass = this.getIconClass();
        const iconProps = {};

        return (
            <Button
                basic
                compact
                onClick={onClick}
                className="buildSection__navigation__shape__icon"
            >
                <IconClass {...this.props} iconProps={iconProps} />
            </Button>
        );
    }
}

ShapeIcon.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    textBody: PropTypes.shape({
        text: PropTypes.string
    }),
    type: PropTypes.string
};

ShapeIcon.defaultProps = {
    name: '',
    onClick: () => {},
    textBody: null,
    type: ''
};

export default ShapeIcon;
