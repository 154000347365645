import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import TableForm from './TableForm/TableForm';
import './TablePane.scss';

const TablePane = props => (
    <Tab.Pane>
        <TableForm {...props} />
    </Tab.Pane>
);

TablePane.propTypes = {
    defaultTableStructures: PropTypes.shape({
        horizontal: PropTypes.shape({
            columns: PropTypes.number,
            hasBandedColumns: PropTypes.bool,
            hasBandedRows: PropTypes.bool,
            hasHeaderColumn: PropTypes.bool,
            hasHeaderRow: PropTypes.bool,
            hasTotalColumn: PropTypes.bool,
            hasTotalRow: PropTypes.bool,
            rows: PropTypes.number
        }),
        vertical: PropTypes.shape({
            columns: PropTypes.number,
            hasBandedColumns: PropTypes.bool,
            hasBandedRows: PropTypes.bool,
            hasHeaderColumn: PropTypes.bool,
            hasHeaderRow: PropTypes.bool,
            hasTotalColumn: PropTypes.bool,
            hasTotalRow: PropTypes.bool,
            rows: PropTypes.number
        })
    }),
    onAddTable: PropTypes.func.isRequired
};

TablePane.defaultProps = {
    defaultTableStructures: {
        horizontal: {
            columns: 3,
            hasBandedColumns: false,
            hasBandedRows: false,
            hasHeaderColumn: false,
            hasHeaderRow: false,
            hasTotalColumn: false,
            hasTotalRow: false,
            rows: 3
        },
        vertical: {
            columns: 3,
            hasBandedColumns: false,
            hasBandedRows: false,
            hasHeaderColumn: false,
            hasHeaderRow: false,
            hasTotalColumn: false,
            hasTotalRow: false,
            rows: 3
        }
    }
};

TablePane.TableForm = TableForm;

export default TablePane;
