import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, List, Form } from 'semantic-ui-react';

const PresentationControlsSettings = ({
    onMouseButtonsControlSetting,
    onArrowKeysControlSetting,
    onSpaceBarBackspaceControlSetting,
    onPageUpPageDownControlSetting,
    mouseButtonsControlSetting,
    arrowKeysControlSetting,
    spaceBarBackspaceControlSetting,
    pageUpPageDownControlSetting
}) => (
    <Form.Group>
        <List className="presentationcontrolssettings__container">

            <List.Item className="presentationcontrolssettings__container__item">
                <div>
                    <span>Arrow keys</span>
                </div>
                <Form.Field>
                    <Checkbox
                        defaultChecked={arrowKeysControlSetting}
                        onChange={(e, data) => onArrowKeysControlSetting(data.checked)}
                    />
                </Form.Field>
            </List.Item>
            <List.Item className="presentationcontrolssettings__container__item">
                <div>
                    <span>Mouse or trackpads clicks</span>
                </div>
                <Form.Field>
                    <Checkbox
                        defaultChecked={mouseButtonsControlSetting}
                        onChange={(e, data) => onMouseButtonsControlSetting(data.checked)}
                    />
                </Form.Field>
            </List.Item>
            <List.Item className="presentationcontrolssettings__container__item">
                <div>
                    <span>Page down / Page up</span>
                </div>
                <Form.Field>
                    <Checkbox
                        defaultChecked={pageUpPageDownControlSetting}
                        onChange={(e, data) => onPageUpPageDownControlSetting(data.checked)}
                    />
                </Form.Field>
            </List.Item>
            <List.Item className="presentationcontrolssettings__container__item">
                <div>
                    <span>Space bar / Backspace</span>
                </div>
                <Form.Field>
                    <Checkbox
                        checked={spaceBarBackspaceControlSetting}
                        onChange={(e, data) => onSpaceBarBackspaceControlSetting(data.checked)}
                    />
                </Form.Field>
            </List.Item>
        </List>
    </Form.Group>
);
PresentationControlsSettings.propTypes = {
    onMouseButtonsControlSetting: PropTypes.func.isRequired,
    onArrowKeysControlSetting: PropTypes.func.isRequired,
    onSpaceBarBackspaceControlSetting: PropTypes.func.isRequired,
    onPageUpPageDownControlSetting: PropTypes.func.isRequired,
    mouseButtonsControlSetting: PropTypes.bool,
    arrowKeysControlSetting: PropTypes.bool,
    spaceBarBackspaceControlSetting: PropTypes.bool,
    pageUpPageDownControlSetting: PropTypes.bool
};

PresentationControlsSettings.defaultProps = {
    mouseButtonsControlSetting: false,
    arrowKeysControlSetting: false,
    spaceBarBackspaceControlSetting: false,
    pageUpPageDownControlSetting: false
};

export default PresentationControlsSettings;
