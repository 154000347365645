import React from 'react';
import {
    Button, Grid, Dimmer, Loader
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../BillingPane.scss';

const CardHolder = ({
    isLoading, cardFormVisibility, card, showCard, children
}) => (

    <Grid columns={2} className="cardholder">
        <Grid.Row>
            <Grid.Column width={3}>
                <span>Payment Info</span>
            </Grid.Column>
            <Grid.Column width={5}>
                {isLoading && (
                    <Dimmer active inverted>
                        <Loader inverted />
                    </Dimmer>
                )}
                {!cardFormVisibility ?
                    <Button type="button" onClick={() => showCard()}>{`${card.brand || 'XXXX'} ending in ${card.last4 || 'XXXX'}, exp: ${card.exp_month || 'XX'}/${card.exp_year || 'XX'}`}</Button> :
                    children}
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

CardHolder.propTypes = {
    isLoading: PropTypes.bool,
    cardFormVisibility: PropTypes.bool,
    card: PropTypes.shape({
        brand: PropTypes.string,
        last4: PropTypes.string,
        exp_month: PropTypes.number,
        exp_year: PropTypes.number
    }).isRequired,
    showCard: PropTypes.func,
    children: PropTypes.node.isRequired
};

CardHolder.defaultProps = {
    isLoading: false,
    cardFormVisibility: false,
    showCard: Function.prototype
};

export default CardHolder;
