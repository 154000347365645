import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import Topic from './Topic/Topic';
import './Topics.scss';

class Topics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTopicHoverId: null,
            hoverRelativePosition: null,
            open: false
        };
    }

    hoverChange = (hoverTopicId, hoverRelativePosition) => {
        this.setState({
            currentTopicHoverId: hoverTopicId,
            hoverRelativePosition
        });
    }

    render() {
        const {
            activePageId,
            leftSideMenuOptions,
            onMouseEnter,
            onActivePageChange,
            onPageDrag,
            onPageDrop,
            pages
        } = this.props;
        const { currentTopicHoverId, hoverRelativePosition, open } = this.state;
        return (
            <List className="buildSection__navigation__topics">
                {
                    pages.map((data, index) => (
                        <Topic
                            key={data.id}
                            index={index}
                            id={data.id}
                            pageNumber={data.pageNumber}
                            page={data}
                            topic={data.topic}
                            isSelected={data.id === activePageId}
                            onActivePageChange={onActivePageChange}
                            currentTopicHoverId={currentTopicHoverId}
                            hoverRelativePosition={hoverRelativePosition}
                            leftSideMenuOptions={leftSideMenuOptions}
                            hoverChange={this.hoverChange}
                            open={open}
                            onMouseEnter={onMouseEnter}
                            onDrag={onPageDrag}
                            onDrop={onPageDrop}
                            isDisabled={data.hidden}
                        />
                    ))}
            </List>
        );
    }
}

Topics.propTypes = {
    activePageId: PropTypes.string,
    leftSideMenuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    onActivePageChange: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onPageDrag: PropTypes.func.isRequired,
    onPageDrop: PropTypes.func.isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        pageNumber: PropTypes.number,
        topic: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string
        })
    }))
};

Topics.defaultProps = {
    activePageId: '',
    leftSideMenuOptions: [],
    pages: []
};

export default Topics;
