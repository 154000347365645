import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid, Item, List
} from 'semantic-ui-react';
import { adjustTimeAndDateFromUTC } from '../../config/DateFormatter';
import HandledImage from '../HandledImage/HandledImage';
import InteractiveInput from '../BuildSection/Toolbar/Tools/InteractiveInput/InteractiveInput';
import Carousel from './Carousel/Carousel';
import MetadataList from './Tab/MetadataList';
import ListAsideDetails from './List/ListAsideDetails';
import './AsideDetails.scss';

const AsideDetails = ({
    deckMetaData,
    deckPageThumbnails,
    pageNumberToDisplay,
    deckId,
    isDeckEditable,
    onTitleBlur,
    paginationSize,
    shareMetaData
}) => (
    <Grid columns={1} className="AsideDetails__SideBar">
        <div className="carousel-section">
            <Carousel
                key={deckId}
                paginationSize={paginationSize}
                initialIndexToDisplay={pageNumberToDisplay}
                elements={deckPageThumbnails.map(thumbnail => (
                    <HandledImage
                        className="AsideDetails__HandledImage"
                        src={thumbnail}
                    />
                ))}
            />
        </div>
        <div className="content-section">
            <div className="title-section">
                <InteractiveInput
                    className="AsideDetails__EditableTitle"
                    inputType="textArea"
                    disabled={!isDeckEditable}
                    value={deckMetaData.description.value}
                    onBlur={e => onTitleBlur(e.target.value)}
                />
            </div>
            <div className="info-section">
                <ListAsideDetails
                    list={[deckMetaData.author, deckMetaData.organization]}
                />
            </div>
            <div className="metadata-section">
                <MetadataList
                    label={shareMetaData.length > 0 ? 'Share Analytics' : null}
                    list={shareMetaData}
                />
            </div>
            <List>
                <List.Item>
                    <Item.Meta>{deckMetaData.lastModifiedDate.label}</Item.Meta>
                    <Item.Description className="AsideDetails__Description__date">
                        {adjustTimeAndDateFromUTC(deckMetaData.lastModifiedDate.value).date}
                        <span className="AsideDetails__Description__time">
                            {adjustTimeAndDateFromUTC(deckMetaData.lastModifiedDate.value).time}
                        </span>
                    </Item.Description>
                </List.Item>
                <List.Item>
                    <Item.Meta>{deckMetaData.createdDate.label}</Item.Meta>
                    <Item.Description className="AsideDetails__Description__date">
                        {adjustTimeAndDateFromUTC(deckMetaData.createdDate.value).date}
                        <span className="AsideDetails__Description__time">
                            {adjustTimeAndDateFromUTC(deckMetaData.createdDate.value).time}
                        </span>
                    </Item.Description>
                </List.Item>
            </List>
        </div>
    </Grid>
);

AsideDetails.propTypes = {
    deckId: PropTypes.string,
    deckMetaData: PropTypes.object,
    deckPageThumbnails: PropTypes.arrayOf(PropTypes.string),
    isDeckEditable: PropTypes.bool,
    paginationSize: PropTypes.string,
    onTitleBlur: PropTypes.func,
    pageNumberToDisplay: PropTypes.number,
    shareMetaData: PropTypes.arrayOf(PropTypes.object)
};

AsideDetails.defaultProps = {
    deckId: '',
    deckMetaData: {
        description: {
            label: 'Description',
            value: ''
        },
        author: {
            label: 'Author',
            value: ''
        },
        organization: {
            label: 'Organization',
            value: ''
        },
        lastModifiedDate: {
            label: 'Last Modified',
            value: {
                date: '',
                time: ''
            }
        },
        createdDate: {
            label: 'Created',
            value: {
                date: '',
                time: ''
            }
        },
        keywords: {
            label: 'Keywords',
            value: []
        }
    },
    deckPageThumbnails: [],
    isDeckEditable: false,
    onTitleBlur: () => { },
    paginationSize: 'small',
    shareMetaData: [],
    pageNumberToDisplay: 1
};

export default AsideDetails;
