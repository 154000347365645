import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';
import './MainNav.scss';

const MainNav = ({ mainNavConfig, pageNumber }) => (
    <nav className="App__mainNav">
        <ul className="App__mainNav__list">
            {Object.values(mainNavConfig).map(({ path, label }) => (
                <li className={`App__mainNav__item App__mainNav__item--${camelCase(label)}`} key={`app-nav-link-${label}`}>
                    <NavLink
                        to={path === 'build' ? `/${path}?pageNumber=${pageNumber}` : `/${path}`}
                        className="App__mainNav__link"
                        activeClassName="App__mainNav__link--isSelected"
                    >
                        <FormattedMessage
                            id={`App.Navigation.${label}`}
                            description="name of the different navMenu"
                            defaultMessage={label}
                        />
                    </NavLink>
                </li>
            ))}
            <li className="App__mainNav__item">
                <div className="ui fluid category search">
                    <div className="ui icon input">
                        <i className="search icon" />
                        <input className="prompt" type="text" placeholder="Search" />
                    </div>
                    <div className="results" />
                </div>
            </li>
        </ul>
    </nav>
);

MainNav.propTypes = {
    mainNavConfig: PropTypes.object.isRequired,
    pageNumber: PropTypes.number
};

MainNav.defaultProps = {
    pageNumber: 1
};

export default MainNav;
