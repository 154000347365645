import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-unknown-property */
const RegularPolygon = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 55" className="Common__SideNav__Container__object__img">
        <polygon id="Polygon" fill={fill} transform="translate(32.000000, 28.000000) rotate(90.000000) translate(-32.000000, -28.000000) " points="32 -4 59.7128129 12 59.7128129 44 32 60 4.28718708 44 4.28718708 12" />
    </svg>
);
/* eslint-enable react/no-unknown-property */

RegularPolygon.propTypes = {
    fill: PropTypes.string
};

RegularPolygon.defaultProps = {
    fill: ''
};

export default RegularPolygon;
