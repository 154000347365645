import React from 'react';
import PropTypes from 'prop-types';

const getShapePoints = isDouble => (
    isDouble ?
        '40 0 64 28 40 56 40 38 24 38 24 56 0 28 24 0 24 18 40 18' :
        '40 0 64 28 40 56 40 38 0 38 0 18 40 18'
);

/* eslint-disable react/no-unknown-property */
const Arrow = ({ fill, isDouble }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 56" className="Common__SideNav__Container__object__img">
        <polygon fill={(fill) || 'url(#chevron-a)'} fillRule="evenodd" points={getShapePoints(isDouble)} />
    </svg>
);
/* eslint-enable react/no-unknown-property */

Arrow.propTypes = {
    fill: PropTypes.string,
    isDouble: PropTypes.bool
};

Arrow.defaultProps = {
    fill: '#ffffff',
    isDouble: false
};

export default Arrow;
