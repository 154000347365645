import React from 'react';
import PropTypes from 'prop-types';
import isFinite from 'lodash/isFinite';
import toNumber from 'lodash/toNumber';
import round from 'lodash/round';

const updateValue = (value, updateStep, min, max) => {
    if (value === '') return value;
    const numericValue = toNumber(value);
    if (!isFinite(updateStep)) {
        return {
            value: round(numericValue, 10)
        };
    }
    let updatedValue = numericValue + updateStep;
    if (isFinite(min)) {
        updatedValue = Math.max(updatedValue, min);
    }
    if (isFinite(max)) {
        updatedValue = Math.min(updatedValue, max);
    }
    return {
        value: round(updatedValue, 10)
    };
};

const withRelativeIncrement = WrappedComponent => {
    const wrapped = props => {
        const {
            onChange, min, max, step
        } = props;
        const update = (
            value,
            relativeIncrement
        ) => onChange(
            {},
            updateValue(value, step * round(relativeIncrement), min, max)
        );
        return (<WrappedComponent {...props} handleRelativeIncrement={update} />);
    };
    wrapped.propTypes = {
        step: PropTypes.number,
        onChange: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number
    };
    wrapped.defaultProps = {
        step: 0,
        onChange: () => { },
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
    };
    return wrapped;
};

export { withRelativeIncrement };
