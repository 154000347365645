import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-unknown-property */
const Polygon = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" className="Common__SideNav__Container__object__img">
        <polygon fill={fill} fillRule="evenodd" points="323 197 352 218.008 340.923 252 305.077 252 294 218.008" transform="translate(-294 -197)" />
    </svg>
);
/* eslint-enable react/no-unknown-property */

Polygon.propTypes = {
    fill: PropTypes.string
};

Polygon.defaultProps = {
    fill: ''
};

export default Polygon;
