import React from 'react';
import { Tab } from 'semantic-ui-react';
import ImportImageFile from '../../../ImportImageFile/ImportImageFile';
import './ImagePane.scss';

const ImagePane = props => (
    <Tab.Pane>
        <ImportImageFile {...props} />
    </Tab.Pane>
);

export default ImagePane;
