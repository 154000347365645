import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Pages from './Pages/Pages';
import Shapes from './Shapes/Shapes';
import Topics from './Topics/Topics';
import DraggableContextProvider from '../../Common/DraggableContextProvider/DraggableContextProvider';
import './Navigation.scss';

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollState: {}
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { activeView } = this.props;
        if (prevProps.activeView !== activeView) {
            const { scrollState } = this.state;
            this.scrollRef.current.scrollTo(0, scrollState[activeView] || 0);
        }
    }

    handleScroll() {
        const { activeView } = this.props;
        const { scrollState } = this.state;
        scrollState[activeView] = this.scrollRef.current.scrollTop;
        this.setState({ scrollState });
    }

    getTabToRender() {
        const {
            activeView, PageComponent, TopicsComponent, ShapesComponent
        } = this.props;
        switch (activeView) {
            case 'pages':
                return PageComponent;
            case 'topics':
                return TopicsComponent;
            case 'shapes':
                return ShapesComponent;
            default:
                return PageComponent;
        }
    }

    render() {
        const { visible } = this.props;
        return (
            visible && (
                <Grid.Column className="BuildGrid__Content__Toolbar">
                    <div className="Navigation" onScroll={this.handleScroll} ref={this.scrollRef}>
                        <div className="Navigation__Content">
                            <DraggableContextProvider>
                                {this.getTabToRender()}
                            </DraggableContextProvider>
                        </div>
                    </div>
                </Grid.Column>
            )
        );
    }
}

Navigation.propTypes = {
    activeView: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    PageComponent: PropTypes.element.isRequired,
    TopicsComponent: PropTypes.element.isRequired,
    ShapesComponent: PropTypes.element.isRequired
};

Navigation.Pages = Pages;
Navigation.Shapes = Shapes;
Navigation.Topics = Topics;

export default Navigation;
