import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

const Play = ({ autoplay, onPlay }) => (

    <div className="play__container">
        <Button
            size="tiny"
            icon
            onClick={(e, data) => {
                e.currentTarget.blur();
                return onPlay(e, data);
            }}
            tabIndex={undefined}
        >
            <i className="material-icons">{autoplay ? 'stop' : 'play_arrow'}</i>
        </Button>
    </div>

);

Play.propTypes = {
    onPlay: PropTypes.func.isRequired,
    autoplay: PropTypes.bool
};

Play.defaultProps = {
    autoplay: false
};

export default Play;
