import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import HandledImage from '../../../../../../../HandledImage/HandledImage';

const IconFromImage = ({ iconProps, src, type }) => (
    <Icon {...iconProps}>
        <HandledImage src={src} alt={`${type} icon`} />
    </Icon>
);

IconFromImage.propTypes = {
    iconProps: PropTypes.object,
    src: PropTypes.string,
    type: PropTypes.string
};

IconFromImage.defaultProps = {
    iconProps: {},
    src: '',
    type: ''
};

export default IconFromImage;
