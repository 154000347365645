import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import Page from './Page/Page';
import './Pages.scss';

class Pages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageHoverId: null,
            hoverRelativePosition: null
        };
    }

    hoverChange = (hoverPageId, hoverRelativePosition) => {
        this.setState({
            currentPageHoverId: hoverPageId,
            hoverRelativePosition
        });
    }

    render() {
        const {
            activePageId,
            imageOptions,
            leftSideMenuOptions,
            onActivePageChange,
            onMouseEnter,
            onPageDrag,
            onPageDrop,
            pages
        } = this.props;
        const { currentPageHoverId, hoverRelativePosition } = this.state;
        return (
            <List className="buildSection__navigation__pages">
                {pages.map((data, index) => (
                    <Page
                        currentPageHoverId={currentPageHoverId}
                        hoverChange={this.hoverChange}
                        hoverRelativePosition={hoverRelativePosition}
                        id={data.id}
                        imageOptions={imageOptions}
                        index={index}
                        isDisabled={data.hidden}
                        isSelected={data.id === activePageId}
                        key={data.id}
                        leftSideMenuOptions={leftSideMenuOptions}
                        onActivePageChange={onActivePageChange}
                        onDrag={onPageDrag}
                        onDrop={onPageDrop}
                        onMouseEnter={onMouseEnter}
                        pageNumber={data.pageNumber}
                        thumbnailSrc={data.thumbnailSrc}
                    />
                ))}
            </List>
        );
    }
}

Pages.propTypes = {
    activePageId: PropTypes.string,
    imageOptions: PropTypes.object,
    leftSideMenuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    onActivePageChange: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onPageDrag: PropTypes.func.isRequired,
    onPageDrop: PropTypes.func.isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        pageNumber: PropTypes.number,
        thumbnailSrc: PropTypes.string
    }))
};

Pages.defaultProps = {
    activePageId: '',
    imageOptions: {},
    leftSideMenuOptions: [],
    pages: []
};

export default Pages;
