import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import './Definition.scss';

const Definition = ({ handleDefinitionInput, rowsNumber, columnsNumber }) => (
    <Form.Group className="TableForm__definition" widths="equal">
        <Form.Input
            type="number"
            min={1}
            max={40}
            value={rowsNumber}
            label="row"
            onChange={handleDefinitionInput}
            id="rows"
        />
        <Form.Input
            type="number"
            min={1}
            max={40}
            value={columnsNumber}
            label="column"
            onChange={handleDefinitionInput}
            id="columns"
        />
    </Form.Group>
);

Definition.propTypes = {
    handleDefinitionInput: PropTypes.func.isRequired,
    rowsNumber: PropTypes.number.isRequired,
    columnsNumber: PropTypes.number.isRequired
};

export default Definition;
