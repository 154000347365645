import React from 'react';
import PropTypes from 'prop-types';
import { List, Grid } from 'semantic-ui-react';

const MetadataList = ({
    label,
    list
}) => (
    <List>
        <List.Item>
            <span className="AsideDetails__info__item__label">
                <span>{label}</span>
            </span>
            <div className="list-content">
                <Grid columns={2}>
                    <Grid.Row>
                        {list.map(item => (
                            <Grid.Column key={item.label}>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <p className="AsideDetails__info__item__data">
                                                {item.label}
                                            </p>
                                        </Grid.Column>
                                        <Grid.Column width={5} className="number">
                                            <span>{item.value}</span>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                </Grid>
            </div>
        </List.Item>
    </List>
);

MetadataList.defaultProps = {
    label: '',
    list: []
};

MetadataList.propTypes = {
    label: PropTypes.string,
    list: PropTypes.array
};

export default MetadataList;
