import React from 'react';
import PropTypes from 'prop-types';

const carriageReturnHandler = WrappedComponent => {
    const wrapped = props => {
        const {
            onKeyDown,
            ...cleanedProps
        } = props;

        const handleEnter = event => {
            if (event.key === 'Enter') {
                event.stopPropagation();
                event.preventDefault();
                event.target.blur(event);
                return false;
            }
            onKeyDown(event);
            return true;
        };
        return <WrappedComponent {...cleanedProps} onKeyDown={handleEnter} />;
    };
    wrapped.propTypes = {
        onKeyDown: PropTypes.func
    };
    wrapped.defaultProps = {
        onKeyDown: () => { }
    };
    return wrapped;
};

export default WrappedComponent => carriageReturnHandler(WrappedComponent);
