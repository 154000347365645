import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import Draggable from '../Draggable/Draggable';
import PageCard from '../../../Common/PageCard/PageCard';
import { PageCardDragLayer } from '../PageCardDragLayer/PageCardDragLayer';

const MixLayout = ({
    pages,
    selectPage,
    selectedPage,
    menuIsOpen,
    moveItem,
    onMouseEnter,
    onMouseLeave,
    leftSideMenuOptions
}) => (
    <List className="Plan__storyboard__pages__list Plan__storyboard__pages__list--mix">
        {pages.map((page, index) => (
            <Draggable
                key={page._id}
                index={index}
                moveItem={moveItem}
            >
                <PageCard
                    className="Plan__storyboard__pages__list__item"
                    item={page}
                    isSelected={selectedPage.id === page.id}
                    menuIsOpen={menuIsOpen}
                    onClick={selectPage}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    fullWidth
                    withContextualMenu
                    withTextContent
                    leftSideMenuOptions={leftSideMenuOptions}
                />
            </Draggable>
        ))}
        <PageCardDragLayer />
    </List>
);

MixLayout.defaultProps = {
    leftSideMenuOptions: [],
    menuIsOpen: false,
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    selectedPage: null
};

MixLayout.propTypes = {
    leftSideMenuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    menuIsOpen: PropTypes.bool,
    moveItem: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    pages: PropTypes.array.isRequired,
    selectedPage: PropTypes.object,
    selectPage: PropTypes.func.isRequired
};

export default MixLayout;
