import { Input } from 'semantic-ui-react';
import { withCustomValidators } from '../Validators/Validators';
import { withCustomTransformers } from '../Transformers/Transformers';
import withErrorRegistering from '../Validators/withErrorRegistering';
import withValidInputMemory from '../Validators/withValidInputMemory';
import withControlledInput from './withControlledInput';
import withActivatable from './withActivatable';

const withValidation = withCustomValidators(Input);
const withTransformers = withCustomTransformers(withValidation);
const withMemory = withValidInputMemory(withTransformers);
const withErrorHandling = withErrorRegistering(withMemory);

export default withActivatable(withControlledInput(withErrorHandling));
