import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import get from 'lodash/get';

const Topic = ({
    onActivePageChange,
    id,
    pageNumber,
    topic,
    isSelected
}) => (
    <List.Item className="navigation__page__section" onClick={() => onActivePageChange(id)}>
        <div className="navigation__page__section_pageNumberId"><span className={isSelected ? 'navigation__page-selected navigation__page__section_pageNumber' : 'navigation__page__section_pageNumber'}>{pageNumber}</span></div>
        <div className="navigation__page__topic">
            <div className={isSelected ? 'navigation__page-selected navigation__page__section_label' : 'navigation__page__section_label'}>{get(topic, 'label', '')}</div>
        </div>
    </List.Item>
);

Topic.propTypes = {
    onActivePageChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    pageNumber: PropTypes.number,
    topic: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string
    }),
    isSelected: PropTypes.bool
};

Topic.defaultProps = {
    id: '',
    pageNumber: 0,
    topic: {
        id: '',
        label: ''
    },
    isSelected: false
};

export default Topic;
