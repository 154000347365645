import React from 'react';
import {
    Tab, Segment, Button,
    Grid, Form, Message, List
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import AccountPaneRow from './AccountPaneRow';
import AccountPaneRowDropdown from './AccountPaneRowDropdown';
import './AccountPane.scss';

const AccountPane = (
    {
        passwordStrengthRequirements,
        email,
        onNameChange,
        updateName,
        updateOccupation,
        name,
        occupation,
        occupations,
        emailMessage,
        passwordMessage,
        editingPassword,
        isDeletingAccount,
        setSendMessage,
        onCancelDeleteAccount,
        onDeleteAccount,
        onConfirmDeleteAccount,
        onOldPasswordChange,
        onNewPasswordChange,
        updatePassword,
        updateEmail,
        onEmailChange,
        setPasswordMessage,
        onOpenEditPassword,
        onCancelEditPassword,
        onCancel
    }
) => (
    <Tab.Pane>
        <Segment basic>
            <Grid columns={2} className="accountContent">
                <AccountPaneRow
                    label="Preferred Name"
                    name="name"
                    onSubmit={updateName}
                    value={name}
                    onChange={onNameChange}
                    onCancel={onCancel}
                />
                <AccountPaneRowDropdown
                    label="Occupation"
                    name="occupation"
                    occupation={occupation}
                    occupations={occupations}
                    updateOccupation={updateOccupation}
                />
                <AccountPaneRow
                    label="Email"
                    name="email"
                    onSubmit={updateEmail}
                    value={email}
                    onChange={onEmailChange}
                    onCancel={onCancel}
                >
                    {emailMessage &&
                            (
                                <Message
                                    onDismiss={setSendMessage(null)}
                                    size="tiny"
                                    className="sent-message"
                                >
                                    {emailMessage}
                                </Message>
                            )}
                </AccountPaneRow>
                <Grid.Row className="password">
                    <Grid.Column width={3}>
                        <span>Password</span>
                    </Grid.Column>
                    <Grid.Column>
                        {!editingPassword ? <Button type="button" className="btnChangePassword" onClick={onOpenEditPassword}>Change Password</Button> :
                            (
                                <Form className="AccountPane__Form__password" onSubmit={updatePassword}>
                                    <Form.Input
                                        required
                                        type="password"
                                        name="password"
                                        label="Old Password"
                                        onChange={(e, data) => onOldPasswordChange(data.value)}
                                    />
                                    <Form.Input
                                        required
                                        minLength={1}
                                        type="password"
                                        name="password"
                                        label="New Password"
                                        onChange={(e, data) => onNewPasswordChange(data.value)}
                                    />
                                    <List>
                                        {passwordStrengthRequirements
                                            .map(requirement => (
                                                <Message.Item
                                                    key={requirement}
                                                >
                                                    {requirement}
                                                </Message.Item>
                                            ))}
                                    </List>
                                    <Button className="formcancel" onClick={onCancelEditPassword}>Cancel</Button>
                                    <Button type="submit" className="formsubmit" disabled={!!passwordStrengthRequirements.length}>Confirm</Button>
                                </Form>
                            )}
                        {passwordMessage &&
                                (
                                    <Message
                                        onDismiss={setPasswordMessage}
                                        size="tiny"
                                        className="password-message"
                                    >
                                        {passwordMessage}

                                    </Message>
                                )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="delete">
                    <Grid.Column width={3}>
                        <span>Data</span>
                    </Grid.Column>
                    <Grid.Column>
                        {!isDeletingAccount ?
                            <Button type="button" className="btnDelete" onClick={onDeleteAccount}>Delete All Presentation Data</Button> :
                            (
                                <Grid>
                                    <Grid.Row>
                                        <Message
                                            negative
                                            size="tiny"
                                            className="delete-message"
                                        >
                                            Click to confirm permanent deletion of all data associated to this account.
                                        </Message>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Button type="button" className="formcancel" onClick={onCancelDeleteAccount}>
                                            Cancel
                                        </Button>
                                        <Button
                                            negative
                                            content="Delete"
                                            onClick={onConfirmDeleteAccount}
                                        />
                                    </Grid.Row>
                                </Grid>
                            )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </Tab.Pane>
);

AccountPane.propTypes = {
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    occupation: PropTypes.string.isRequired,
    occupations: PropTypes.array.isRequired,
    emailMessage: PropTypes.string,
    passwordMessage: PropTypes.string,
    editingPassword: PropTypes.bool,
    isDeletingAccount: PropTypes.bool,
    passwordStrengthRequirements: PropTypes.arrayOf(PropTypes.string),
    updateEmail: PropTypes.func,
    updatePassword: PropTypes.func,
    onNameChange: PropTypes.func,
    updateName: PropTypes.func,
    updateOccupation: PropTypes.func,
    setSendMessage: PropTypes.func,
    onCancelDeleteAccount: PropTypes.func,
    onDeleteAccount: PropTypes.func,
    onConfirmDeleteAccount: PropTypes.func,
    onOldPasswordChange: PropTypes.func,
    onNewPasswordChange: PropTypes.func,
    onEmailChange: PropTypes.func,
    setPasswordMessage: PropTypes.func,
    onOpenEditPassword: PropTypes.func,
    onCancelEditPassword: PropTypes.func,
    onCancel: PropTypes.func
};

AccountPane.defaultProps = {
    emailMessage: '',
    passwordMessage: '',
    editingPassword: false,
    isDeletingAccount: false,
    passwordStrengthRequirements: [],
    updateEmail: Function.prototype,
    updatePassword: Function.prototype,
    onNameChange: Function.prototype,
    updateName: Function.prototype,
    updateOccupation: Function.prototype,
    setSendMessage: Function.prototype,
    onCancelDeleteAccount: Function.prototype,
    onDeleteAccount: Function.prototype,
    onConfirmDeleteAccount: Function.prototype,
    onOldPasswordChange: Function.prototype,
    onNewPasswordChange: Function.prototype,
    onEmailChange: Function.prototype,
    setPasswordMessage: Function.prototype,
    onOpenEditPassword: Function.prototype,
    onCancelEditPassword: Function.prototype,
    onCancel: Function.prototype
};

export default AccountPane;
