import ProfileModal from './ProfileModal';

import AccountPane from './ContentPanes/AccountPane';
import BillingPane from './ContentPanes/BillingPane';
import InvoicesPane from './ContentPanes/InvoicesPane';
import InvitesPane from './ContentPanes/InvitesPane';

export default {
    ProfileModal,
    ContentPanes: {
        AccountPane, BillingPane, InvoicesPane, InvitesPane
    }
};
