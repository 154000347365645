import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import Draggable from '../Draggable/Draggable';
import { PageCardDragLayer } from '../PageCardDragLayer/PageCardDragLayer';
import PageCard from '../../../Common/PageCard/PageCard';

const TileLayout = ({
    pages,
    selectPage,
    selectedPage,
    moveItem,
    onMouseEnter,
    onMouseLeave,
    leftSideMenuOptions
}) => (
    <Card.Group className="Plan__storyboard__pages__list Plan__storyboard__pages__list--tiles">
        {pages.map((page, index) => (
            <Draggable
                key={page._id}
                index={index}
                moveItem={moveItem}
                disabled={page.waitForUpdate}
            >
                <PageCard
                    className="Plan__storyboard__pages__list__item"
                    item={page}
                    onClick={selectPage}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    isSelected={selectedPage && selectedPage.id === page.id}
                    withTextContent
                    withContextualMenu
                    leftSideMenuOptions={leftSideMenuOptions}
                />
            </Draggable>
        ))}
        <PageCardDragLayer />
    </Card.Group>
);

TileLayout.defaultProps = {
    leftSideMenuOptions: [],
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    selectedPage: null
};

TileLayout.propTypes = {
    leftSideMenuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    moveItem: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    pages: PropTypes.array.isRequired,
    selectedPage: PropTypes.object,
    selectPage: PropTypes.func.isRequired
};

export default TileLayout;
