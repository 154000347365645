import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'semantic-ui-react';
import Toggle from './Toggle/Toggle';
import Menu from './Menu/Menu';
import './Settings.scss';

const Settings = ({
    onSpaceBarAutoplayControlSettingChange,
    onBlackPageEndSettingChange,
    onAutoplayDelayChange,
    onAutoplayToggle,
    autoplay,
    autoplayDelay,
    spaceBarAutoplayControlSetting,
    onMouseButtonsControlSetting,
    onArrowKeysControlSetting,
    onSpaceBarBackspaceControlSetting,
    onPageUpPageDownControlSetting,
    mouseButtonsControlSetting,
    arrowKeysControlSetting,
    spaceBarBackspaceControlSetting,
    pageUpPageDownControlSetting,
    onToggle,
    isDropdownMenuVisible,
    pageOptions,
    endPresentationPage,
    onPlay
}) => (
    <div className="settings__container">
        <Portal open={isDropdownMenuVisible}>
            <div className="settings__container__menu">
                <Menu
                    onSpaceBarAutoplayControlSettingChange={onSpaceBarAutoplayControlSettingChange}
                    onBlackPageEndSettingChange={onBlackPageEndSettingChange}
                    onAutoplayDelayChange={onAutoplayDelayChange}
                    onAutoplayToggle={onAutoplayToggle}
                    autoplay={autoplay}
                    autoplayDelay={autoplayDelay}
                    spaceBarAutoplayControlSetting={spaceBarAutoplayControlSetting}
                    onMouseButtonsControlSetting={onMouseButtonsControlSetting}
                    onArrowKeysControlSetting={onArrowKeysControlSetting}
                    onSpaceBarBackspaceControlSetting={onSpaceBarBackspaceControlSetting}
                    onPageUpPageDownControlSetting={onPageUpPageDownControlSetting}
                    mouseButtonsControlSetting={mouseButtonsControlSetting}
                    arrowKeysControlSetting={arrowKeysControlSetting}
                    spaceBarBackspaceControlSetting={spaceBarBackspaceControlSetting}
                    pageUpPageDownControlSetting={pageUpPageDownControlSetting}
                    onToggle={onToggle}
                    onPlay={onPlay}
                    pageOptions={pageOptions}
                    endPresentationPage={endPresentationPage}
                />

            </div>
        </Portal>
        <Toggle onToggle={onToggle} />
    </div>
);

Settings.propTypes = {
    onSpaceBarAutoplayControlSettingChange: PropTypes.func.isRequired,
    onBlackPageEndSettingChange: PropTypes.func.isRequired,
    onAutoplayDelayChange: PropTypes.func.isRequired,
    onAutoplayToggle: PropTypes.func.isRequired,
    autoplay: PropTypes.bool,
    autoplayDelay: PropTypes.number,
    spaceBarAutoplayControlSetting: PropTypes.bool,
    onMouseButtonsControlSetting: PropTypes.func.isRequired,
    onArrowKeysControlSetting: PropTypes.func.isRequired,
    onSpaceBarBackspaceControlSetting: PropTypes.func.isRequired,
    onPageUpPageDownControlSetting: PropTypes.func.isRequired,
    mouseButtonsControlSetting: PropTypes.bool,
    arrowKeysControlSetting: PropTypes.bool,
    spaceBarBackspaceControlSetting: PropTypes.bool,
    pageUpPageDownControlSetting: PropTypes.bool,
    isDropdownMenuVisible: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    pageOptions: PropTypes.array.isRequired,
    endPresentationPage: PropTypes.string.isRequired,
    onPlay: PropTypes.func.isRequired
};

Settings.defaultProps = {
    autoplay: false,
    autoplayDelay: 0,
    spaceBarAutoplayControlSetting: false,
    mouseButtonsControlSetting: false,
    arrowKeysControlSetting: false,
    spaceBarBackspaceControlSetting: false,
    pageUpPageDownControlSetting: false,
    isDropdownMenuVisible: false
};

export default Settings;
