import React from 'react';
import PropTypes from 'prop-types';
import { labelSizeInPx, maxValToDisplay, minPipSizeInPx } from './feedbackDisplay.config';
import PositiveFeedbackBar from './PositiveFeedbackBar/PositiveFeedbackBar';
import NegativeFeedbackBar from './NegativeFeedbackBar/NegativeFeedbackBar';
import './FeedbackDisplay.scss';

const FeedbackDisplay = ({ data, height, maxWidth }) => {
    const maxFeedbackWidth = (maxWidth - (2 * labelSizeInPx)) / 2;
    const totalOfFeedbacks = data.positive + data.negative;
    const positiveWidth = Math.max((data.positive * maxFeedbackWidth) / totalOfFeedbacks, minPipSizeInPx);
    const negativeWidth = Math.max((data.negative * maxFeedbackWidth) / totalOfFeedbacks, minPipSizeInPx);
    const positiveValNeedsPopup = data.positive > maxValToDisplay;
    const negativeValNeedsPopup = data.negative > maxValToDisplay;

    return (
        <div
            className="share-table__feedbacks-graph"
            style={{
                width: maxWidth,
                height
            }}
        >
            <PositiveFeedbackBar
                height={height}
                barWidth={positiveWidth}
                needsPopup={positiveValNeedsPopup}
                totalOfFeedbacks={totalOfFeedbacks}
                offset={maxFeedbackWidth + labelSizeInPx}
                data={data.positive}
            />
            <NegativeFeedbackBar
                height={height}
                barWidth={negativeWidth}
                needsPopup={negativeValNeedsPopup}
                totalOfFeedbacks={totalOfFeedbacks}
                offset={maxFeedbackWidth + labelSizeInPx}
                data={data.negative}
            />
        </div>
    );
};

FeedbackDisplay.propTypes = {
    data: PropTypes.object,
    height: PropTypes.number,
    maxWidth: PropTypes.number
};

FeedbackDisplay.defaultProps = {
    data: { positive: 0, negative: 0 },
    height: 30,
    maxWidth: 200
};

export default FeedbackDisplay;
