import React from 'react';
import {
    Tab,
    Segment,
    Button,
    Grid,
    Form,
    Message,
    Label
} from 'semantic-ui-react';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import PropTypes from 'prop-types';
import InvitesTable from '../Tables/InvitesTable';
import './InvitesPane.scss';

const InvitesPane = ({
    invites,
    onResendInvite,
    onCancelInvite,
    onViewAllInvites,
    onViewPendingInvites,
    openSendInvite,
    closeSendInvite,
    showOnlyPending,
    sendInviteOpen,
    onEmailChange,
    onMessageChange,
    onSendInvite,
    inviteMessage,
    errorMessage,
    recipients
}) => (
    <Tab.Pane>
        <Segment basic className="invitesContent">
            <Grid columns={2} className="OptionHeader">
                <Grid.Row>
                    <Grid.Column width={3}>
                        {sendInviteOpen ? (<span>Send to</span>) : (<span>Send to</span>)}
                    </Grid.Column>
                    <Grid.Column>
                        {sendInviteOpen ? (
                            <Form onSubmit={onSendInvite}>
                                <Label basic content="Recipient emails" />
                                <ReactMultiEmail
                                    placeholder=""
                                    emails={recipients}
                                    onChange={(emails = []) => onEmailChange(emails)}
                                    validateEmail={email => isEmail(email)}
                                    getLabel={(
                                        email,
                                        index,
                                        removeEmail
                                    ) => (
                                        <div data-tag key={index}>
                                            {email}
                                            {/* eslint-disable-next-line */}
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                                ×
                                            </span>
                                        </div>
                                    )}
                                    id="createShareRecipients"
                                />
                                <Form.TextArea label="Message" value={inviteMessage} onChange={(e, data) => onMessageChange(data.value)} />
                                <Button floated="right" type="submit" className="formsubmit">Confirm</Button>
                                <Button floated="right" className="formcancel" onClick={closeSendInvite}>Cancel</Button>

                            </Form>
                        ) :
                            <Button floated="left" type="button" onClick={openSendInvite}>Invite to Decksign</Button>}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <span>Showing</span>
                    </Grid.Column>
                    <Grid.Column>
                        {showOnlyPending ?
                            <Button type="button" onClick={onViewAllInvites}>Pending Invites</Button> :
                            <Button type="button" onClick={onViewPendingInvites}>All Invites</Button>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            { errorMessage && (
                <Message negative>
                    {errorMessage}
                </Message>
            )}
            <div className="pendingTable">
                <InvitesTable
                    onlyShowPendingInvites={showOnlyPending}
                    invites={invites}
                    onResendInvite={onResendInvite}
                    onCancelInvite={onCancelInvite}
                />
            </div>
        </Segment>
    </Tab.Pane>
);

InvitesPane.propTypes = {
    onSendInvite: PropTypes.func,
    onResendInvite: PropTypes.func,
    onCancelInvite: PropTypes.func,
    onViewAllInvites: PropTypes.func,
    onViewPendingInvites: PropTypes,
    openSendInvite: PropTypes.func,
    closeSendInvite: PropTypes.func,
    onEmailChange: PropTypes.func,
    onMessageChange: PropTypes.func,
    showOnlyPending: PropTypes.bool,
    sendInviteOpen: PropTypes.bool,
    invites: PropTypes.array,
    inviteMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.string)
};

InvitesPane.defaultProps = {
    invites: [],
    showOnlyPending: false,
    sendInviteOpen: false,
    inviteMessage: '',
    onSendInvite: Function.prototype,
    onResendInvite: Function.prototype,
    onCancelInvite: Function.prototype,
    onViewAllInvites: Function.prototype,
    onViewPendingInvites: Function.prototype,
    openSendInvite: Function.prototype,
    closeSendInvite: Function.prototype,
    onEmailChange: Function.prototype,
    onMessageChange: Function.prototype,
    errorMessage: '',
    recipients: []
};

export default InvitesPane;
