import React from 'react';
import PropTypes from 'prop-types';

const getShapePoints = isFull => (
    isFull ?
        '0 0 40 0 64 28 40 56 0 56' :
        '0 0 40 0 64 28 40 56 0 56 24 28'
);

/* eslint-disable react/no-unknown-property */
const Chevron = ({ fill, isFull }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 56" className="Common__SideNav__Container__object__img">
        <polygon fill={(fill) || 'url(#chevron-a)'} fillRule="evenodd" points={getShapePoints(isFull)} />
    </svg>
);
/* eslint-enable react/no-unknown-property */

Chevron.propTypes = {
    fill: PropTypes.string,
    isFull: PropTypes.bool
};

Chevron.defaultProps = {
    fill: '',
    isFull: false
};

export default Chevron;
