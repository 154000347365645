import PropTypes from 'prop-types';
import React from 'react';
import {
    Button, List, Tab, Image
} from 'semantic-ui-react';

const ShapePane = ({ shapes, onItemClick }) => (
    <Tab.Pane>
        <List className="WorkspaceHeader__insert__content__list" horizontal>
            {shapes.map(item => (
                <List.Item key={item.displayName} className="WorkspaceHeader__insert__content__list__item">
                    <Button onClick={() => onItemClick(item)}>
                        {item.icon ?
                            <i className="material-icons">{item.icon}</i> :
                            <Image src={item.imageSrc} />}
                        <p>{item.displayName}</p>
                    </Button>
                </List.Item>
            ))}
        </List>
    </Tab.Pane>
);

ShapePane.propTypes = {
    shapes: PropTypes.arrayOf(PropTypes.object).isRequired,
    onItemClick: PropTypes.func.isRequired
};

export default ShapePane;
