import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
    List,
    Divider,
    Accordion,
    Input
} from 'semantic-ui-react';
import { DragSource, DropTarget } from 'react-dnd';
import Toggles from './Toggles/Toggles';
import ShapeIcon from './ShapeIcon/ShapeIcon';
import './Shape.scss';

const flatMapShapes = shape => [
    shape,
    ...(shape.shapes || []).reduce((acc, cursor) => [
        ...acc,
        ...flatMapShapes(cursor)
    ], [])
];

const getNodeRelativePosition = (node, clientOffset, dragIndex, hoverIndex) => {
    const hoverBoundingRect = node.getBoundingClientRect();
    const hoverElementHeight = 28;
    const hoverUpperTrigger = hoverElementHeight * 0.25;
    const hoverLowerTrigger = hoverElementHeight - hoverUpperTrigger;
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (hoverClientY <= hoverLowerTrigger && hoverClientY >= hoverUpperTrigger) {
        return 'over';
    }
    if (hoverIndex !== dragIndex + 1 && hoverClientY < hoverUpperTrigger) {
        return 'above';
    }
    if (hoverIndex !== dragIndex - 1 && hoverClientY > hoverLowerTrigger) {
        return 'below';
    }
    return '';
};

const Types = {
    SHAPE: 'shape'
};
let sourceHovering = '';

const shapeTarget = {
    hover(targetProps, monitor, component) {
        if (!monitor.getItem().validateDropTarget(targetProps)) {
            targetProps.hoverChange(null, null);
            return;
        }

        const node = component?.getNode();

        if (!node) {
            return;
        }

        const clientOffset = monitor.getClientOffset();
        const source = monitor.getItem();

        const relativePosition = getNodeRelativePosition(node, clientOffset, source.index, targetProps.index);
        component.setDragRelativePosition(relativePosition);

        if (!relativePosition) {
            return;
        }

        targetProps.hoverChange(targetProps.id, relativePosition);
    },
    drop(targetProps) {
        return {
            id: targetProps.id,
            index: targetProps.index,
            selectedShapesParentGroupsPath: targetProps.selectedShapesParentGroupsPath
        };
    }
};

const shapeSource = {
    beginDrag(sourceProps) {
        sourceProps.onDrag(sourceProps.id);
        return {
            id: sourceProps.id,
            index: sourceProps.index,
            selectedShapesParentGroupsPath: sourceProps.selectedShapesParentGroupsPath,
            validateDropTarget: target => {
                const isGroupDroppingInsideItself = flatMapShapes(sourceProps)
                    .some(({ id }) => id === target.id);

                const isTargetAndDragSourceSame = target.id === sourceProps.id;

                const isTargetValid = (
                    !isGroupDroppingInsideItself &&
                    !isTargetAndDragSourceSame &&
                    !target.isBackground
                );

                return isTargetValid;
            }
        };
    },
    endDrag(sourceProps, monitor) {
        if (!monitor.didDrop()) {
            return;
        }
        const source = monitor.getItem();
        const target = monitor.getDropResult();

        if (monitor.getItem().validateDropTarget(target)) {
            sourceProps.onDrop(
                source.id,
                source.selectedShapesParentGroupsPath || [],
                target.selectedShapesParentGroupsPath || []
            );
        }
        sourceProps.hoverChange(null, null);
    }
};

const collectTarget = (connect, monitor) => ({
    isOver: monitor.isOver(),
    connectDropTarget: connect.dropTarget()
});

const collectSource = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
});

class Shape extends React.Component {
    static propTypes = {
        assignedStyles: PropTypes.shape({
            shape: PropTypes.object
        }),
        connectDragSource: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        hoverChange: PropTypes.func.isRequired,
        fill: PropTypes.object,
        id: PropTypes.string,
        isBackground: PropTypes.bool,
        isCollapsed: PropTypes.bool,
        isDragging: PropTypes.bool,
        isSelected: PropTypes.bool,
        isContainerHidden: PropTypes.bool,
        isContainerLocked: PropTypes.bool,
        isHidden: PropTypes.bool,
        isLocked: PropTypes.bool,
        name: PropTypes.string,
        onDrag: PropTypes.func,
        onDrop: PropTypes.func.isRequired,
        onGroupCollapseToggle: PropTypes.func,
        onShapeLockChange: PropTypes.func.isRequired,
        onShapeSelect: PropTypes.func.isRequired,
        onShapesSelectionAppend: PropTypes.func.isRequired,
        onShapesSelecteAndGrouped: PropTypes.func.isRequired,
        onShapeVisibilityChange: PropTypes.func.isRequired,
        onChangeName: PropTypes.func.isRequired,
        onGroupSelectedObjects: PropTypes.func.isRequired,
        onSelectAndGroupObjects: PropTypes.func.isRequired,
        shapes: PropTypes.arrayOf(PropTypes.object),
        selectedCanvasItems: PropTypes.arrayOf(PropTypes.string),
        selectedShapesParentGroupsPath: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.string,
        isOver: PropTypes.bool
    }

    static defaultProps = {
        assignedStyles: {
            shape: {}
        },
        fill: undefined,
        id: '',
        isBackground: false,
        isCollapsed: true,
        isSelected: false,
        isContainerHidden: false,
        isContainerLocked: false,
        isDragging: false,
        isHidden: false,
        isLocked: false,
        name: '',
        shapes: [],
        type: '',
        onDrag: () => { },
        onGroupCollapseToggle: () => { },
        selectedCanvasItems: [],
        selectedShapesParentGroupsPath: [],
        isOver: false
    }

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.submitChange = this.submitChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.handleClicks = this.handleClicks.bind(this);
        this.setHovering = this.setHovering.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.state = {
            name: props.name,
            isCollapsed: props.isCollapsed,
            isClicked: false,
            isEditing: false,
            isHovering: false,
            collapseTimeOut: undefined,
            dragRelativePosition: ''
        };
        this.elementRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { name } = nextProps;
        const { name: currentName, isEditing } = prevState;

        return {
            name: (name !== currentName && !isEditing) ? nextProps.name : currentName
        };
    }

    getNode() {
        return this.elementRef.current;
    }

    onCollapse() {
        const { id, onGroupCollapseToggle } = this.props;

        const { isCollapsed } = this.state;
        this.setState({ isCollapsed: !isCollapsed });

        if (onGroupCollapseToggle) {
            onGroupCollapseToggle(id);
        }
    }

    onDragStart = e => {
        sourceHovering = e.target;
    }

    onDragEnd = () => {
        sourceHovering = '';
    }

    onDragEnter(e) {
        e.preventDefault();
        if (e.target !== sourceHovering) {
            this.setState({
                collapseTimeOut: setTimeout(() => { this.setState({ isCollapsed: false }); }, 1000)
            });
        }
    }

    onDragLeave(e) {
        const { collapseTimeOut } = this.state;
        if (e.target !== sourceHovering) clearTimeout(collapseTimeOut);
        this.setState({ collapseTimeOut: undefined });
    }

    onClick(e) {
        const {
            id,
            onShapesSelectionAppend,
            onShapeSelect,
            selectedShapesParentGroupsPath
        } = this.props;

        if (e.shiftKey) {
            onShapesSelectionAppend(id, selectedShapesParentGroupsPath);
        } else {
            onShapeSelect(id, selectedShapesParentGroupsPath);
        }
    }

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.submitChange();
        }
    }

    changeName() {
        const { id, onChangeName } = this.props;
        const { name } = this.state;
        onChangeName(id, name);
    }

    submitChange() {
        this.changeName();
        this.setState({
            isEditing: false
        });
    }

    onChange(e) {
        this.setState({
            name: e.target.value
        });
    }

    onDoubleClick(e) {
        this.onClick(e);
        this.setState({
            isEditing: true
        });
    }

    handleClicks(e, modifyName = true) {
        const {
            isClicked
        } = this.state;

        e.persist();

        if (isClicked) {
            clearTimeout(this.clickTimeout);
            this.setState({
                isClicked: false
            });
            if (modifyName) this.onDoubleClick(e);
            else this.onCollapse();
        } else {
            this.setState({
                isClicked: true
            });
            this.clickTimeout = setTimeout(() => {
                this.setState({
                    isClicked: false
                });
                this.onClick(e);
            }, 500);
        }
    }

    setHovering(value) {
        this.setState({ isHovering: value });
    }

    setDragRelativePosition(value) {
        const { dragRelativePosition } = this.state;
        if (dragRelativePosition !== value) {
            this.setState({ dragRelativePosition: value });
        }
    }

    getPropertiesToPassToGroupChild() {
        const {
            connectDragSource,
            connectDropTarget,
            hoverChange,
            isHidden,
            isLocked,
            onDrag,
            onDrop,
            onGroupCollapseToggle,
            onShapesSelectionAppend,
            onShapesSelecteAndGrouped,
            onShapeVisibilityChange,
            onShapeLockChange,
            onShapeSelect,
            selectedCanvasItems,
            selectedShapesParentGroupsPath,
            onChangeName,
            id,
            onSelectAndGroupObjects
        } = this.props;

        return {
            connectDragSource,
            connectDropTarget,
            isContainerHidden: isHidden,
            isContainerLocked: isLocked,
            hoverChange,
            onDrag,
            onDrop,
            onGroupCollapseToggle,
            onShapesSelectionAppend,
            onShapesSelecteAndGrouped,
            onShapeVisibilityChange,
            onShapeLockChange,
            onShapeSelect,
            selectedCanvasItems,
            selectedShapesParentGroupsPath: [...selectedShapesParentGroupsPath, id],
            onChangeName,
            onSelectAndGroupObjects
        };
    }

    render() {
        const {
            assignedStyles,
            fill,
            id,
            isSelected,
            isContainerHidden,
            isContainerLocked,
            isDragging,
            isHidden,
            isLocked,
            onShapeVisibilityChange,
            onShapeLockChange,
            connectDragSource,
            connectDropTarget,
            shapes,
            type,
            selectedCanvasItems,
            isOver
        } = this.props;

        const {
            name,
            isCollapsed,
            isEditing,
            isHovering,
            dragRelativePosition
        } = this.state;

        const renderedFill = fill || get(assignedStyles, 'shape.fill', {});

        return type === 'Group' ?
            (
                <React.Fragment>

                    <Accordion
                        className={
                            `buildSection__navigation__shape buildSection__navigation__group 
                            ${isSelected ? 'buildSection__navigation__shape--selected' : ''} 
                            ${isDragging ? 'buildSection__navigation__shape--dragging' : ''}`
                                .trim()
                        }
                    >
                        {
                            connectDropTarget(
                                <div
                                    className="dropTarget"
                                    onMouseOver={() => this.setHovering(true)}
                                    onMouseLeave={() => this.setHovering(false)}
                                    onFocus={() => {}}
                                    ref={this.elementRef}
                                >
                                    {
                                        connectDragSource(
                                            <div
                                                className="dragSource"
                                            >
                                                {(isOver && dragRelativePosition === 'above') ? (
                                                    <Divider
                                                        onDragEnter={e => { e.preventDefault(); }}
                                                        onDragOver={e => { e.preventDefault(); }}
                                                        onDrop={this.onSelectAction}
                                                        className="buildSection__navigation__shape__border divider"
                                                    />
                                                ) : null}
                                                <Accordion.Title
                                                    as={List.Item}
                                                    active={!isCollapsed}
                                                    className="navigation__shapes__accordion__title"
                                                >
                                                    <List.Icon
                                                        name="dropdown"
                                                        onClick={() => this.onCollapse()}
                                                    />
                                                    <ShapeIcon
                                                        className="navigation__shapes__accordion_group_shape"
                                                        {...this.props}
                                                        fill={renderedFill}
                                                        onClick={e => { this.handleClicks(e, false); }}
                                                        onDragEnter={this.onDragEnter}
                                                        onDragLeave={this.onDragLeave}
                                                        onDragStart={this.onDragStart}
                                                        onDragEnd={this.onDragEnd}
                                                        onDragOver={e => e.preventDefault()}
                                                        onDrop={this.onSelectAction}
                                                        isCollapsed={isCollapsed}

                                                    />

                                                    {!isEditing ? (

                                                        <List.Content
                                                            onClick={this.handleClicks}
                                                            className="buildSection__navigation__shape__name"
                                                            onDragEnter={this.onDragEnter}
                                                            onDragLeave={this.onDragLeave}
                                                            onDragStart={this.onDragStart}
                                                            onDragEnd={this.onDragEnd}
                                                            onDragOver={e => e.preventDefault()}
                                                            onDrop={this.onSelectAction}
                                                            title={name}
                                                        >
                                                            {name}
                                                        </List.Content>
                                                    ) : (
                                                        <Input
                                                            className="buildSection__navigation__shape__input"
                                                            value={name}
                                                            onChange={this.onChange}
                                                            onBlur={() => this.submitChange()}
                                                            onKeyDown={e => { this.handleKeyDown(e); }}
                                                            autoFocus
                                                        />
                                                    )}

                                                    <List.Content>
                                                        <Toggles
                                                            id={id}
                                                            isHidden={isHidden}
                                                            isHovering={isHovering}
                                                            isLocked={isLocked}
                                                            isContainerHidden={isContainerHidden}
                                                            isContainerLocked={isContainerLocked}
                                                            onShapeLockChange={onShapeLockChange}
                                                            onShapeVisibilityChange={onShapeVisibilityChange}
                                                        />
                                                    </List.Content>
                                                </Accordion.Title>

                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }

                        <Accordion.Content
                            className={
                                `buildSection__navigation__group buildSection__navigation__shape 
                                ${isHidden ? 'hidden' : ''}`
                                    .trim()
                            }
                            active={!isCollapsed}
                            as={List.List}
                        >
                            {shapes.map(data => (
                                <DraggableShape
                                    {...{
                                        ...data,
                                        key: data.id,
                                        ...this.getPropertiesToPassToGroupChild(),
                                        isSelected: selectedCanvasItems.includes(data.id)
                                    }}
                                />
                            ))}
                        </Accordion.Content>
                        {(isOver && dragRelativePosition === 'below') ? (
                            <Divider
                                onDragEnter={e => { e.preventDefault(); }}
                                onDragOver={e => { e.preventDefault(); }}
                                onDrop={this.onSelectAction}
                                className="buildSection__navigation__shape__border divider"
                            />
                        ) : null}
                    </Accordion>

                </React.Fragment>
            ) :
            connectDropTarget(
                <div
                    className="dropTarget"
                    onMouseOver={() => this.setHovering(true)}
                    onMouseLeave={() => this.setHovering(false)}
                    onFocus={() => {}}
                    ref={this.elementRef}
                >
                    {connectDragSource(
                        <div
                            className="dragSource"
                            onFocus={() => {}}
                            onDragStart={this.onDragStart}
                            onDragEnd={this.onDragEnd}
                        >
                            {(isOver && dragRelativePosition === 'above') ? (
                                <Divider
                                    onDragEnter={e => { e.preventDefault(); }}
                                    onDragOver={e => { e.preventDefault(); }}
                                    onDrop={this.onSelectAction}
                                    className="buildSection__navigation__shape__border divider"
                                />
                            ) :
                                null}
                            <List.Item
                                className={
                                    `buildSection__navigation__shape 
                                    ${isSelected ? 'buildSection__navigation__shape--selected' : ''}
                                    ${isHidden ? 'buildSection__navigation__shape--hidden' : ''} 
                                    ${isDragging ? 'buildSection__navigation__shape--dragging' : ''}`
                                        .trim()
                                }
                                onDragEnter={e => { e.preventDefault(); }}
                                onDragOver={e => { e.preventDefault(); }}
                                onDrop={this.onSelectAction}
                            >
                                <ShapeIcon
                                    {...this.props}
                                    fill={renderedFill}
                                    onClick={this.onClick}
                                />
                                {!isEditing ? (
                                    <List.Content
                                        onClick={this.handleClicks}
                                        onDragEnter={e => { e.preventDefault(); }}
                                        onDragOver={e => { e.preventDefault(); }}
                                        onDrop={this.onSelectAction}
                                        className="buildSection__navigation__shape__name"
                                        title={name}
                                    >
                                        {name}
                                    </List.Content>
                                ) : (
                                    <Input
                                        className="buildSection__navigation__shape__input"
                                        value={name}
                                        onChange={this.onChange}
                                        onBlur={() => this.submitChange()}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        autoFocus
                                    />
                                )}
                                <Toggles
                                    id={id}
                                    isHidden={isHidden}
                                    isLocked={isLocked}
                                    isContainerHidden={isContainerHidden}
                                    isContainerLocked={isContainerLocked}
                                    onShapeLockChange={onShapeLockChange}
                                    onShapeVisibilityChange={onShapeVisibilityChange}
                                    isHovering={isHovering}
                                    {...this.props}
                                />
                            </List.Item>
                            {(isOver && dragRelativePosition === 'below') ? (
                                <Divider
                                    onDragEnter={e => { e.preventDefault(); }}
                                    onDragOver={e => { e.preventDefault(); }}
                                    onDrop={this.onSelectAction}
                                    className="buildSection__navigation__shape__border divider"
                                />
                            ) :
                                null}
                        </div>
                    )}
                </div>
            );
    }
}

const DraggableShape = DropTarget(Types.SHAPE, shapeTarget, collectTarget)(
    DragSource(Types.SHAPE, shapeSource, collectSource)(Shape)
);

export default DraggableShape;
