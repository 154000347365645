import React from 'react';
import {
    Table,
    Button,
    Popup,
    Dimmer,
    Loader
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const InvitesTable = ({
    invites,
    onResendInvite,
    onCancelInvite,
    isLoading
}) => {
    const cells = invites.map(({
        _id, date, email, status, isResendable
    }) => (
        <Table.Row key={date + email}>
            <Table.Cell width={3}>{date}</Table.Cell>
            <Table.Cell width={7}>{email}</Table.Cell>
            <Table.Cell width={3}>{status}</Table.Cell>
            <Table.Cell width={3}>
                <Popup
                    trigger={
                        (
                            <div className="resend">
                                <Button type="button" onClick={() => onResendInvite(_id)} disabled={!isResendable}>Resend</Button>
                            </div>
                        )
                    }
                    disabled={isResendable}
                    content="Maximum number of resend reached. Please contact support !"
                    inverted
                />
                <Button type="button" onClick={() => onCancelInvite(_id)} className="cancel">Cancel</Button>
            </Table.Cell>
        </Table.Row>
    ));

    return (
        <React.Fragment>
            <Table attached="top" basic="very" className="pendingTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>Date</Table.HeaderCell>
                        <Table.HeaderCell width={7}>Email</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            </Table>
            <Dimmer.Dimmable as={Table} basic="very">
                <Table.Body>
                    {cells}
                    <Table.Row>
                        <Table.Cell>
                            <Dimmer active={isLoading}>
                                <Loader active={isLoading} content="Loading Invites" size="tiny" />
                            </Dimmer>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Dimmer.Dimmable>
        </React.Fragment>
    );
};

InvitesTable.propTypes = {
    invites: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string,
            date: PropTypes.string,
            email: PropTypes.string,
            status: PropTypes.string
        })
    ),
    onResendInvite: PropTypes.func.isRequired,
    onCancelInvite: PropTypes.func.isRequired,
    isLoading: PropTypes.func.isRequired
};

InvitesTable.defaultProps = {
    invites: []
};

export default InvitesTable;
