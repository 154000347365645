import React from 'react';
import PropTypes from 'prop-types';

const escapeHandler = WrappedComponent => {
    const wrapped = props => {
        const {
            onKeyDown,
            onCancel,
            onBlur,
            ...cleanedProps
        } = props;

        const handleEsc = event => {
            if (event.key === 'Escape' || event.key === 'Esc') {
                event.stopPropagation();
                event.preventDefault();
                onCancel(event);
                onBlur(event);
                return false;
            }
            onKeyDown(event);
            return true;
        };
        return <WrappedComponent {...cleanedProps} onKeyDown={handleEsc} onBlur={onBlur} onCancel={onCancel} />;
    };
    wrapped.propTypes = {
        onKeyDown: PropTypes.func,
        onCancel: PropTypes.func,
        onBlur: PropTypes.func
    };
    wrapped.defaultProps = {
        onKeyDown: Function.prototype,
        onCancel: Function.prototype,
        onBlur: Function.prototype
    };
    return wrapped;
};

export default WrappedComponent => escapeHandler(WrappedComponent);
