import React from 'react';
import PropTypes from 'prop-types';
import {
    Dropdown,
    Image,
    List,
    Icon,
    Divider
} from 'semantic-ui-react';
import './LogoDropdown.scss';
import { UserLabel } from './UserLabel/UserLabel';
import logo from '../../../assets/images/decksign-logo.svg';

const Trigger = ({ dropdownIsOpen }) => (
    <div className="LogoDropdown__Trigger">
        <Icon
            className={`LogoDropdown__Trigger__Icon--${dropdownIsOpen ? 'open' : 'close'}`}
            name={dropdownIsOpen ? 'close' : 'bars'}
        />
        <Image
            className="LogoDropdown__Trigger__Image"
            src={logo}
        />
    </div>
);

class LogoDropdown extends React.PureComponent {
    state = { isOpen: false };

    handleOnOpen = () => {
        this.setState({ isOpen: true });
    };

    handleOnClose = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const {
            username,
            onLogout,
            openProfileModal,
            authService
        } = this.props;
        const { isOpen } = this.state;

        return (
            <Dropdown
                className="LogoDropdown__Dropdown"
                trigger={(
                    <Trigger dropdownIsOpen={isOpen} />
                )}
                onOpen={this.handleOnOpen}
                onClose={this.handleOnClose}
                icon={null}
            >
                <Dropdown.Menu className="LogoDropdown__Dropdown__Menu">
                    <List className="LogoDropdown__Dropdown__Menu__list" relaxed="very" style={{ padding: '10px' }}>
                        <List.Item className="LogoDropdown__Dropdown__ListItem">
                            <UserLabel user={username} />
                        </List.Item>
                        <Divider fitted />
                        <List.Item
                            id="profile"
                            className="LogoDropdown__Dropdown__ListItem--clickable"
                            onClick={() => openProfileModal()}
                            icon={<Icon name="user circle" size="large" />}
                            content="Manage your profile"
                        />
                        <List.Item
                            id="logout"
                            className="LogoDropdown__Dropdown__ListItem--clickable"
                            onClick={() => onLogout(authService)}
                            icon={<Icon name="sign-out" size="large" />}
                            content="Sign Out"
                        />
                    </List>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

Trigger.defaultProps = {
    dropdownIsOpen: false
};

Trigger.propTypes = {
    dropdownIsOpen: PropTypes.bool
};

LogoDropdown.defaultProps = {
    username: null,
    onLogout: (() => { }),
    openProfileModal: (() => { })
};

LogoDropdown.propTypes = {
    username: PropTypes.string,
    authService: PropTypes.object.isRequired,
    onLogout: PropTypes.func,
    openProfileModal: PropTypes.func
};

export default LogoDropdown;
