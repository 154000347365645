import React from 'react';
import PropTypes from 'prop-types';

const withErrorRegistering = WrappedComponent => {
    const ErrorRegistering = class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                hasError: false
            };
            this.onError = this.onError.bind(this);
            this.clearErrorOnChange = this.clearErrorOnChange.bind(this);
            this.clearErrorOnBlur = this.clearErrorOnBlur.bind(this);
        }

        onError(error, fallback, value) {
            const { onError, updateDisplay } = this.props;
            this.setState({
                hasError: true
            });
            if (onError) {
                onError(error);
            }
            if (updateDisplay) {
                updateDisplay(value);
            }
        }

        clearErrorOnChange(event, data) {
            const { onChange } = this.props;
            this.setState({ hasError: false });
            if (onChange) {
                onChange(event, data);
            }
        }

        clearErrorOnBlur(event) {
            const { onBlur } = this.props;
            this.setState({ hasError: false });
            if (onBlur) {
                onBlur(event);
            }
        }

        render() {
            const { updateDisplay, ...cleanedProps } = this.props; // eslint-disable-line no-unused-vars
            const { hasError } = this.state;
            return (
                <WrappedComponent
                    {...cleanedProps}
                    onError={this.onError}
                    onChange={this.clearErrorOnChange}
                    onBlur={this.clearErrorOnBlur}
                    error={hasError === true}
                />
            );
        }
    };
    ErrorRegistering.propTypes = {
        onChange: PropTypes.func,
        onError: PropTypes.func,
        updateDisplay: PropTypes.func,
        onBlur: PropTypes.func
    };
    ErrorRegistering.defaultProps = {
        onChange: () => {},
        onError: () => {},
        updateDisplay: () => {},
        onBlur: () => {}
    };
    return ErrorRegistering;
};

export default withErrorRegistering;
