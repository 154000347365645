import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import Pages from './Pages/Pages';
import Topics from './Topics/Topics';
import './Navigation.scss';

const Navigation = ({
    activePageId,
    onActivePageChange,
    pages
}) => (
    <div className="viewer__navigation__container">

        <Tab
            menu={{ color: 'blue', secondary: true, pointing: true }}
            panes={[
                {
                    menuItem: 'Pages',
                    pane: {
                        key: 'pages',
                        content: (
                            <Pages activePageId={activePageId} onActivePageChange={onActivePageChange} pages={pages} />
                        )
                    }
                },
                {
                    menuItem: 'Topics',
                    pane: {
                        key: 'topics',
                        content: (
                            <Topics
                                activePageId={activePageId}
                                onActivePageChange={onActivePageChange}
                                topics={pages}
                            />
                        )
                    }
                }
            ]}
            renderActiveOnly={false}
        />
    </div>
);

Navigation.propTypes = {
    activePageId: PropTypes.string,
    onActivePageChange: PropTypes.func.isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        pageNumber: PropTypes.number,
        topic: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string
        }),
        thumbnailSrc: PropTypes.string
    }))
};

Navigation.defaultProps = {
    activePageId: '',
    pages: []
};

export default Navigation;
