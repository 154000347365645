import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Header } from 'semantic-ui-react';
import Feedbacks from './Feedbacks/Feedbacks';
import Comments from './Comments/Comments';
import Play from '../../../Play/Play';
import Fullscreen from '../../../Fullscreen/Fullscreen';
import Settings from './Settings/Settings';
import PageCounter from '../../../PageCounter/PageCounter';
import Download from '../../../Download/Download';
import './Form.scss';

const RetroactionForm = ({
    comments,
    feedbacks,
    onComment,
    onDeleteComment,
    onEditComment,
    sharePermissions,
    onNegativeFeedback,
    onPositiveFeedback,
    onRemoveFeedback,
    settings,
    onPlay,
    onToggle,
    isSettingsVisible,
    props
}) => {
    const date = new Date(props?.information?.date);
    const presentationName = props?.information?.title;
    const updatedAtDate = date.toDateString().slice(4, 15);
    const {
        onSpaceBarAutoplayControlSettingChange,
        onBlackPageEndSettingChange,
        onAutoplayDelayChange,
        onAutoplayToggle,
        onFullscreenToggle,
        onMouseButtonsControlSetting,
        onArrowKeysControlSetting,
        onSpaceBarBackspaceControlSetting,
        onPageUpPageDownControlSetting,
        information: {
            activePageNumber,
            pagesCount
        },
        onExportAsPDF
    } = props;
    const {
        autoplay,
        autoplayDelay,
        spaceBarAutoplayControlSetting,
        blackPageEnd,
        mouseButtonsControlSetting,
        arrowKeysControlSetting,
        spaceBarBackspaceControlSetting,
        pageUpPageDownControlSetting,
        endPresentationPage
    } = settings;
    const {
        comment,
        feedback,
        download
    } = sharePermissions;
    const pageOptions = [
        {
            key: 'Black page',
            text: 'Black page',
            value: 'Black page'
        },

        {
            key: 'First page',
            text: 'First page',
            value: 'First page'
        },
        {
            key: 'Last page',
            text: 'Last page',
            value: 'Last page'
        },

        {
            key: 'White page',
            text: 'White page',
            value: 'White page'
        }
    ];
    return (

        <Form>
            <Grid className="form__grid">
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={4} className="feedbacks__grid__container">
                        <Feedbacks
                            feedbacks={feedbacks}
                            onNegativeFeedback={onNegativeFeedback}
                            onPositiveFeedback={onPositiveFeedback}
                            onRemoveFeedback={onRemoveFeedback}
                            permission={feedback}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={10} className="comment__grid__container">
                        <Comments
                            onComment={onComment}
                            comments={comments}
                            onDeleteComment={onDeleteComment}
                            onEditComment={onEditComment}
                            permission={comment}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={2} className="controls__grid__container">

                        <div className="controls__container">
                            <Header>
                                <Header.Content>
                                    Controls
                                </Header.Content>
                            </Header>
                            <Play autoplay={autoplay} onPlay={onPlay} />
                            <Settings
                                pageOptions={pageOptions}
                                onSpaceBarAutoplayControlSettingChange={onSpaceBarAutoplayControlSettingChange}
                                onBlackPageEndSettingChange={onBlackPageEndSettingChange}
                                onAutoplayDelayChange={onAutoplayDelayChange}
                                onAutoplayToggle={onAutoplayToggle}
                                autoplay={autoplay}
                                onPlay={onPlay}
                                autoplayDelay={autoplayDelay}
                                blackPageEnd={blackPageEnd}
                                endPresentationPage={endPresentationPage}
                                spaceBarAutoplayControlSetting={spaceBarAutoplayControlSetting}
                                onMouseButtonsControlSetting={onMouseButtonsControlSetting}
                                onArrowKeysControlSetting={onArrowKeysControlSetting}
                                onSpaceBarBackspaceControlSetting={onSpaceBarBackspaceControlSetting}
                                onPageUpPageDownControlSetting={onPageUpPageDownControlSetting}
                                mouseButtonsControlSetting={mouseButtonsControlSetting}
                                arrowKeysControlSetting={arrowKeysControlSetting}
                                spaceBarBackspaceControlSetting={spaceBarBackspaceControlSetting}
                                pageUpPageDownControlSetting={pageUpPageDownControlSetting}
                                onToggle={onToggle}
                                isDropdownMenuVisible={isSettingsVisible}
                            />
                            <Fullscreen onFullscreenToggle={onFullscreenToggle} />

                        </div>

                    </Grid.Column>

                </Grid.Row>
                <Grid.Row className="presentation__information">
                    <Grid.Column className="pageCounter__grid__container">
                        <PageCounter
                            activePageNumber={activePageNumber}
                            pagesCount={pagesCount}
                        />
                    </Grid.Column>
                    <Grid.Column className="deck__information__grid__container">
                        <div className="presentation__label">{presentationName}</div>
                        <div className="presentation__date">
                            {updatedAtDate}
                        </div>
                    </Grid.Column>
                    <Grid.Column className="download__grid__container">
                        <Download disabled={!download} onExportAsPDF={onExportAsPDF} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

RetroactionForm.propTypes = {
    agreement: PropTypes.number,
    representation: PropTypes.number,
    comments: PropTypes.array,
    onEditComment: PropTypes.any,
    feedbacks: PropTypes.shape({
        agreement: PropTypes.number,
        representation: PropTypes.number,
        relevance: PropTypes.number
    }),
    isSettingsVisible: PropTypes.any,
    onComment: PropTypes.any,
    onDeleteComment: PropTypes.any,
    onNegativeFeedback: PropTypes.any,
    onPlay: PropTypes.any,
    onPositiveFeedback: PropTypes.any,
    onRemoveFeedback: PropTypes.any,
    onToggle: PropTypes.any,
    props: PropTypes.shape({
        information: PropTypes.shape({
            activePageNumber: PropTypes.any,
            date: PropTypes.any,
            pagesCount: PropTypes.any
        }),
        onArrowKeysControlSetting: PropTypes.any,
        onAutoplayDelayChange: PropTypes.any,
        onAutoplayToggle: PropTypes.any,
        onBlackPageEndSettingChange: PropTypes.any,
        onExportAsPDF: PropTypes.any,
        onFullscreenToggle: PropTypes.any,
        onMouseButtonsControlSetting: PropTypes.any,
        onPageUpPageDownControlSetting: PropTypes.any,
        onSpaceBarAutoplayControlSettingChange: PropTypes.any,
        onSpaceBarBackspaceControlSetting: PropTypes.any,
        sharePermissions: PropTypes.shape({
            download: PropTypes.any
        })
    }),
    relevance: PropTypes.number,
    settings: PropTypes.shape({
        arrowKeysControlSetting: PropTypes.any,
        autoplay: PropTypes.any,
        autoplayDelay: PropTypes.any,
        blackPageEnd: PropTypes.any,
        endPresentationPage: PropTypes.string,
        mouseButtonsControlSetting: PropTypes.any,
        pageUpPageDownControlSetting: PropTypes.any,
        spaceBarAutoplayControlSetting: PropTypes.any,
        spaceBarBackspaceControlSetting: PropTypes.any
    }),
    sharePermissions: PropTypes.shape({
        comment: PropTypes.any,
        feedback: PropTypes.any
    })
}.isRequired;

RetroactionForm.defaultProps = {
    comments: [],
    feedbacks: {
        relevance: 0,
        representation: 0,
        agreement: 0
    }
};

export default RetroactionForm;
