import React from 'react';
import { Modal, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class TabbedModal extends React.Component {
    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this);
    }

    onClose(event) {
        const {
            onClose
        } = this.props;
        if (!event.target.nodeName || event.target.nodeName !== 'INPUT') {
            onClose();
        }
    }

    render() {
        const {
            parentBlockClassPrefix,
            header,
            trigger,
            panes,
            open,
            defaultActiveIndex
        } = this.props;
        return (
            <Modal
                open={open}
                onClose={this.onClose}
                className={`${parentBlockClassPrefix}__Modal`}
                trigger={trigger}
                closeIcon
                closeOnDimmerClick={false}
            >
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content>
                    <Tab
                        menu={{ fluid: true, vertical: true }}
                        menuPosition="left"
                        panes={panes}
                        defaultActiveIndex={defaultActiveIndex}
                    />
                </Modal.Content>
            </Modal>
        );
    }
}

TabbedModal.propTypes = {
    parentBlockClassPrefix: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    trigger: PropTypes.node,
    panes: PropTypes.arrayOf(PropTypes.shape({
        menuItem: PropTypes.string,
        render: PropTypes.function
    })).isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    defaultActiveIndex: PropTypes.number
};

TabbedModal.defaultProps = {
    trigger: null,
    open: undefined,
    onClose: Function.prototype,
    defaultActiveIndex: 0
};

export default TabbedModal;
