import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const GroupIcon = ({ iconProps, isCollapsed }) => (
    <Icon {...iconProps}>
        <i className="material-icons-outlined icon-white group-icon">
            {isCollapsed ? 'folder' : 'folder_open'}
        </i>
    </Icon>
);

GroupIcon.propTypes = {
    iconProps: PropTypes.object,
    isCollapsed: PropTypes.bool
};

GroupIcon.defaultProps = {
    iconProps: {},
    isCollapsed: true
};

export default GroupIcon;
