import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Divider, Form
} from 'semantic-ui-react';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

import './TableStructureFieldSets.scss';

class TableStructureFieldSets extends Component {
    static pickStateFromProps(props = {}) {
        const {
            pageHeight,
            pageWidth
        } = props;

        return {
            ...pick(props, [
                'hasBandedColumns',
                'hasBandedRows',
                'hasHeaderColumn',
                'hasHeaderRow',
                'hasTotalColumn',
                'hasTotalRow',
                'mainAxis'
            ]),
            maxTableWidth: pageWidth - 200,
            maxTableHeight: pageHeight - 200
        };
    }

    /* eslint-disable react/no-unused-prop-types */
    static propTypes = {
        hasBandedColumns: PropTypes.bool,
        hasBandedRows: PropTypes.bool,
        hasHeaderColumn: PropTypes.bool,
        hasHeaderRow: PropTypes.bool,
        hasTotalColumn: PropTypes.bool,
        hasTotalRow: PropTypes.bool,
        mainAxis: PropTypes.string,
        onStructureChange: PropTypes.func,
        pageHeight: PropTypes.number,
        pageWidth: PropTypes.number,
        showOrientation: PropTypes.bool
    }
    /* eslint-enable react/no-unused-prop-types */

    static defaultProps = {
        hasBandedColumns: false,
        hasBandedRows: false,
        hasHeaderColumn: false,
        hasHeaderRow: false,
        hasTotalColumn: false,
        hasTotalRow: false,
        mainAxis: 'vertical',
        onStructureChange: () => { },
        pageHeight: 800,
        pageWidth: 1200,
        showOrientation: true
    };

    constructor(props) {
        super(props);

        this.handleMainAxisChange = this.handleMainAxisChange.bind(this);
        this.handleStructureChange = this.handleStructureChange.bind(this);

        this.state = this.constructor.pickStateFromProps(props);
    }

    componentDidUpdate(prevProps) {
        const initialState = this.constructor.pickStateFromProps(prevProps);
        const nextState = this.constructor.pickStateFromProps(this.props);
        if (!isEqual(initialState, nextState) && !isEqual(this.state, nextState)) {
            this.setState(nextState); // eslint-disable-line react/no-did-update-set-state
        }
    }

    handleMainAxisChange(event, { value }) {
        const {
            onStructureChange
        } = this.props;

        this.setState(
            {
                mainAxis: value
            },
            () => {
                onStructureChange(this.state);
            }
        );
    }

    handleStructureChange(part) {
        const {
            [part]: oldValue
        } = this.state;

        const {
            onStructureChange
        } = this.props;

        this.setState(
            {
                [part]: !oldValue
            },
            () => {
                onStructureChange(this.state);
            }
        );
    }

    render() {
        const {
            hasBandedColumns,
            hasBandedRows,
            hasHeaderColumn,
            hasHeaderRow,
            hasTotalColumn,
            hasTotalRow,
            mainAxis
        } = this.state;

        const { showOrientation } = this.props;

        return (
            <Fragment>
                {showOrientation && (
                    <>
                        <Divider horizontal content="Orientation" />
                        <Form.Group className="TableForm__checkGroup">
                            <Form.Field>
                                <Form.Radio
                                    label="Vertical"
                                    value="vertical"
                                    checked={mainAxis === 'vertical'}
                                    onChange={this.handleMainAxisChange}
                                    name="mainAxis"
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Radio
                                    label="Horizontal"
                                    value="horizontal"
                                    checked={mainAxis === 'horizontal'}
                                    onChange={this.handleMainAxisChange}
                                    name="mainAxis"
                                />
                            </Form.Field>
                        </Form.Group>
                    </>
                )
                }
                <Divider horizontal content="Structure" />
                <Form.Group className="TableForm__checkGroup">
                    <Form.Field>
                        <Form.Checkbox
                            label="Header Row"
                            checked={hasHeaderRow}
                            key="hasHeaderRow"
                            onChange={() => {
                                this.handleStructureChange('hasHeaderRow');
                            }}
                        />
                        <Form.Checkbox
                            label="Banded Rows"
                            checked={hasBandedRows}
                            key="hasBandedRows"
                            onChange={() => {
                                this.handleStructureChange('hasBandedRows');
                            }}
                        />
                        <Form.Checkbox
                            label="Total Row"
                            checked={hasTotalRow}
                            key="hasTotalRow"
                            onChange={() => {
                                this.handleStructureChange('hasTotalRow');
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Checkbox
                            label="Header Column"
                            checked={hasHeaderColumn}
                            key="hasHeaderColumn"
                            onChange={() => {
                                this.handleStructureChange('hasHeaderColumn');
                            }}
                        />
                        <Form.Checkbox
                            label="Banded Columns"
                            checked={hasBandedColumns}
                            key="hasBandedColumns"
                            onChange={() => {
                                this.handleStructureChange('hasBandedColumns');
                            }}
                        />
                        <Form.Checkbox
                            label="Total Column"
                            checked={hasTotalColumn}
                            key="hasTotalColumn"
                            onChange={() => {
                                this.handleStructureChange('hasTotalColumn');
                            }}
                        />
                    </Form.Field>
                </Form.Group>
            </Fragment>
        );
    }
}

export default TableStructureFieldSets;
