import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const DraggableContextProvider = ({ children }) => (
    <div>
        {children}
    </div>
);

DraggableContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default DragDropContext(HTML5Backend)(DraggableContextProvider);
