import React from 'react';
import {
    Table, Button, Dimmer, Loader
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const InvoicesTable = ({ invoices, isLoading }) => {
    const cells = invoices.map(({
        date, id, amount, taxPercentages, status, onIdClick, onPdfClick
    }) => (
        <Table.Row key={id}>
            <Table.Cell width={3}>{date}</Table.Cell>
            <Table.Cell width={4}><Button type="button" onClick={onIdClick}>{id}</Button></Table.Cell>
            <Table.Cell width={2}>{amount}</Table.Cell>
            <Table.Cell width={2}>{taxPercentages}</Table.Cell>
            <Table.Cell width={2}>{status}</Table.Cell>
            <Table.Cell width={2}><Button type="button" onClick={onPdfClick}>PDF</Button></Table.Cell>
        </Table.Row>
    ));
    return (
        <React.Fragment>
            <Table attached="top" basic="very" className="invoicesTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>Date</Table.HeaderCell>
                        <Table.HeaderCell width={4}>Invoice ID</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Amount</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Tax (%)</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Download</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            </Table>
            <Dimmer.Dimmable as={Table} basic="very">
                <Table.Body>
                    {cells}
                    <Table.Row>
                        <Table.Cell>
                            <Dimmer active={isLoading}>
                                <Loader active={isLoading} content="Loading Invoices" size="tiny" />
                            </Dimmer>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Dimmer.Dimmable>
        </React.Fragment>
    );
};

InvoicesTable.propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        id: PropTypes.string,
        amount: PropTypes.string,
        status: PropTypes.string,
        onIdClick: PropTypes.func,
        onPdfClick: PropTypes.func
    })),
    isLoading: PropTypes.bool
};

InvoicesTable.defaultProps = {
    invoices: [],
    isLoading: false
};

export default InvoicesTable;
