import React from 'react';
import {
    Grid,
    Button,
    Form,
    Dimmer,
    Loader
} from 'semantic-ui-react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PropTypes from 'prop-types';
import InteractiveInput from '../../../BuildSection/Toolbar/Tools/InteractiveInput/InteractiveInput';
import '../BillingPane.scss';

const Address = ({
    isLoading, addressFormVisibility, address, onChangeAddress, showAddress
}) => (
    <Grid columns={2} className="address">
        <Grid.Row>
            <Grid.Column width={3}>
                <span>Billing Address</span>
            </Grid.Column>
            <Grid.Column width={7}>
                {isLoading && (
                    <Dimmer active inverted>
                        <Loader inverted />
                    </Dimmer>
                )}
                {!addressFormVisibility ? (
                    <Button type="button" onClick={() => showAddress()}>
                        { address.line1 && (<p>{address.line1}</p>) }
                        { address.line2 && (<p>{address.line2}</p>) }
                        <p>
                            {(address.city && address.state && address.postal_code) ?
                                `${address.city}, ${address.state}, ${address.postal_code}` :
                                'No Address'}
                        </p>
                        { address.country && (<p>{address.country}</p>) }
                    </Button>
                ) :
                    (
                        <Form className="address-form">
                            <Grid>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Input
                                            label="Address"
                                            value={address.line1}
                                            required
                                            inputType="string"
                                            control={InteractiveInput}
                                            onChange={(e, data) => onChangeAddress(data.value, 'line1')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Input
                                            label="Address Line 2 (optional)"
                                            value={address.line2}
                                            inputType="string"
                                            control={InteractiveInput}
                                            onChange={(e, data) => {
                                                onChangeAddress(data.value, 'line2');
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                    <Grid.Column>
                                        <Form.Input
                                            label="City"
                                            value={address.city}
                                            required
                                            inputType="string"
                                            control={InteractiveInput}
                                            onChange={(e, data) => onChangeAddress(data.value, 'city')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2}>
                                    <Grid.Column width={10}>
                                        <Form.Field required>
                                            <label htmlFor="country">Country</label>
                                            <CountryDropdown
                                                id="country"
                                                valueType="short"
                                                className="inline-input"
                                                whitelist={['CA', 'US']}
                                                value={address.country}
                                                onChange={country => onChangeAddress(country, 'country')}
                                            />
                                        </Form.Field>
                                        <Form.Field required>
                                            <label htmlFor="region">Province / State / Region</label>
                                            <RegionDropdown
                                                id="region"
                                                valueType="short"
                                                countryValueType="short"
                                                className="inline-input"
                                                country={address.country}
                                                value={address.state}
                                                onChange={state => onChangeAddress(state, 'state')}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Form.Input
                                            label="Postal / ZIP Code"
                                            value={address.postal_code}
                                            className="inline-input"
                                            required
                                            inputType="string"
                                            control={InteractiveInput}
                                            onChange={(e, data) => onChangeAddress(data.value, 'postal_code')}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    )}
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

Address.propTypes = {
    isLoading: PropTypes.bool,
    addressFormVisibility: PropTypes.bool,
    address: PropTypes.shape({
        cardholder: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        country: PropTypes.string,
        state: PropTypes.string,
        city: PropTypes.string,
        postal_code: PropTypes.string
    }).isRequired,
    onChangeAddress: PropTypes.func.isRequired,
    showAddress: PropTypes.func
};

Address.defaultProps = {
    isLoading: false,
    addressFormVisibility: false,
    showAddress: Function.prototype
};

export default Address;
