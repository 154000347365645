const locale = navigator.language;

const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
});

export const dateFormat = str => formatter.format(str);

export const adjustTimeAndDateFromUTC = dateObj => {
    const date = new Date(`${dateObj.date}T${dateObj.time}Z`);
    if (!date.getTime()) { return {}; }
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.getTime() - timezoneOffset).toISOString();
    const splitDate = adjustedDate.split(/[(T|Z|.)]/);
    return {
        date: splitDate[0],
        time: splitDate[1]
    };
};
