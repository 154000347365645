import React from 'react';
import PropTypes from 'prop-types';
import { Label, Segment, Input } from 'semantic-ui-react';
import { withCustomValidators } from '../Validators/Validators';
import { withFixedPrecision, withCustomTransformers } from '../Transformers/Transformers';
import withErrorRegistering from '../Validators/withErrorRegistering';
import withValidInputMemory from '../Validators/withValidInputMemory';
import withControlledInput from './withControlledInput';
import withActivatable from './withActivatable';

const renderLabel = (labelValue, labelProps) => {
    if (labelProps === undefined) {
        return '';
    }
    const { content: labelTag } = labelProps;
    const label = `${(labelTag || '')} ${labelValue}`;
    return (<Label {...labelProps} content={label} />);
};

class SliderInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            labelValue: props.value
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(event, data) {
        // react-semantic-ui-range only passes a value, this is bad!
        // But doing css is worse...
        const { onChange } = this.props;
        const { value } = data;
        this.setState({
            labelValue: value
        });
        if (onChange) {
            onChange(event, data);
        }
    }

    render() {
        const { label, ...otherProps } = this.props;
        const { labelValue } = this.state;
        return (
            <Segment padded={false} compact>
                <Input {...otherProps} onChange={this.onChange} />
                {renderLabel(labelValue, label)}
            </Segment>
        );
    }
}

SliderInput.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ]),
    onChange: PropTypes.func,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape,
        PropTypes.bool
    ])
};

SliderInput.defaultProps = {
    value: false,
    onChange: () => { },
    label: false
};

const withValidation = withCustomValidators(SliderInput);
const withTransformers = withCustomTransformers(withFixedPrecision(withValidation));
const withMemory = withValidInputMemory(withTransformers);
const withErrorHandling = withErrorRegistering(withMemory);

export default withActivatable(withControlledInput(withErrorHandling));
