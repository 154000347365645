import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';

const QuickInsert = ({ objectToInsert, icon, onItemClick }) => (
    <Button onClick={() => onItemClick(objectToInsert)} icon>
        {icon}
    </Button>
);

QuickInsert.propTypes = {
    icon: PropTypes.node.isRequired,
    onItemClick: PropTypes.func.isRequired,
    objectToInsert: PropTypes.object.isRequired
};

export default QuickInsert;
