import React from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Button } from 'semantic-ui-react';
import AutoplaySettings from './AutoplaySettings/AutoplaySettings';
import PresentationControlsSettings from './PresentationControlsSettings/PresentationControlsSettings';
import './Menu.scss';

const Menu = ({
    onSpaceBarAutoplayControlSettingChange,
    onBlackPageEndSettingChange,
    onAutoplayDelayChange,
    onAutoplayToggle,
    autoplayDelay,
    spaceBarAutoplayControlSetting,
    onMouseButtonsControlSetting,
    onArrowKeysControlSetting,
    onSpaceBarBackspaceControlSetting,
    onPageUpPageDownControlSetting,
    mouseButtonsControlSetting,
    arrowKeysControlSetting,
    spaceBarBackspaceControlSetting,
    pageUpPageDownControlSetting,
    onToggle,
    pageOptions,
    endPresentationPage
}) => (
    <Form>
        <Card className="settings__menu">
            <div className="settings__menu__close">

                <Button
                    type="button"
                    onClick={() => onToggle('settings')}
                >
                    <i className="material-icons">close </i>
                </Button>
            </div>
            <Card.Content>

                <div className="settings__menu__label">Autoplay</div>

                <AutoplaySettings
                    pageOptions={pageOptions}
                    onSpaceBarAutoplayControlSettingChange={onSpaceBarAutoplayControlSettingChange}
                    onBlackPageEndSettingChange={onBlackPageEndSettingChange}
                    onAutoplayDelayChange={onAutoplayDelayChange}
                    onAutoplayToggle={onAutoplayToggle}
                    autoplayDelay={autoplayDelay}
                    endPresentationPage={endPresentationPage}
                    spaceBarAutoplayControlSetting={spaceBarAutoplayControlSetting}
                    onSpaceBarBackspaceControlSetting={onSpaceBarBackspaceControlSetting}
                />

            </Card.Content>
            <Card.Content>
                <div className="settings__menu__label"> Next/Previous page</div>

                <PresentationControlsSettings
                    onMouseButtonsControlSetting={onMouseButtonsControlSetting}
                    onArrowKeysControlSetting={onArrowKeysControlSetting}
                    onSpaceBarBackspaceControlSetting={onSpaceBarBackspaceControlSetting}
                    onPageUpPageDownControlSetting={onPageUpPageDownControlSetting}
                    mouseButtonsControlSetting={mouseButtonsControlSetting}
                    arrowKeysControlSetting={arrowKeysControlSetting}
                    spaceBarBackspaceControlSetting={spaceBarBackspaceControlSetting}
                    spaceBarAutoplayControlSetting={spaceBarAutoplayControlSetting}
                    pageUpPageDownControlSetting={pageUpPageDownControlSetting}
                    onSpaceBarAutoplayControlSettingChange={onSpaceBarAutoplayControlSettingChange}
                />

            </Card.Content>
        </Card>
    </Form>
);

Menu.propTypes = {
    arrowKeysControlSetting: PropTypes.bool,
    autoplayDelay: PropTypes.number,
    mouseButtonsControlSetting: PropTypes.bool,
    onArrowKeysControlSetting: PropTypes.any,
    onAutoplayDelayChange: PropTypes.any,
    onAutoplayToggle: PropTypes.any,
    onBlackPageEndSettingChange: PropTypes.any,
    onMouseButtonsControlSetting: PropTypes.any,
    onPageUpPageDownControlSetting: PropTypes.any,
    onSpaceBarAutoplayControlSettingChange: PropTypes.any,
    onSpaceBarBackspaceControlSetting: PropTypes.any,
    onToggle: PropTypes.func,
    pageUpPageDownControlSetting: PropTypes.bool,
    spaceBarAutoplayControlSetting: PropTypes.bool,
    spaceBarBackspaceControlSetting: PropTypes.bool
}.isRequired;

Menu.defaultProps = {
    autoplayDelay: 0,
    spaceBarAutoplayControlSetting: false,
    mouseButtonsControlSetting: false,
    arrowKeysControlSetting: false,
    spaceBarBackspaceControlSetting: false,
    pageUpPageDownControlSetting: false
};

export default Menu;
