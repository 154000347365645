import React from 'react';
import PropTypes from 'prop-types';
import {
    Header,
    Sidebar
} from 'semantic-ui-react';
import HandledImage from '../HandledImage/HandledImage';
import './DetailsPanel.scss';

const DetailsPanel = ({
    title, imgUrl, content, visible
}) => {
    const width = window.innerWidth < 1400 ? 'wide' : 'very wide';
    return (
        <Sidebar animation="push" direction="right" visible={visible} vertical="true" width={width}>
            <div className="DetailsPanel">
                {imgUrl && <HandledImage src={imgUrl} alt={title} retry />}
                <div className="DetailsPanel__Content">
                    <Header className="DetailsPanel__Content__Header">{title}</Header>
                    <p className="DetailsPanel__Content__Description">{content}</p>
                </div>
            </div>
        </Sidebar>
    );
};

DetailsPanel.propTypes = {
    title: PropTypes.string,
    imgUrl: PropTypes.string,
    content: PropTypes.string,
    visible: PropTypes.bool
};

DetailsPanel.defaultProps = {
    title: '',
    imgUrl: '',
    content: '',
    visible: true
};

export default DetailsPanel;
