import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Form
} from 'semantic-ui-react';
import AuthHeader from '../Common/AuthHeader/AuthHeader';

const ForgotPassword = props => {
    const {
        loading,
        globalMessage,
        inputChanged,
        submitForm,
        authNavConfig,
        onRedirect
    } = props;
    return (
        <div className="auth-wrapper">
            <Form error onSubmit={submitForm}>
                <Card fluid className="forgot_form-card">
                    <Card.Content className="card-header_content">

                        <AuthHeader
                            onRedirect={onRedirect}
                            pathName="Sign In"
                            path={authNavConfig.login.path}
                        />
                        <div className="card-header_content">
                            Forgot your password?
                            <Card.Content className="subheader">
                                Fear not. We&apos;ll
                                send you a reset code to your email address
                                <br />
                                with which you will be able to
                                select a new password.
                            </Card.Content>

                        </div>
                    </Card.Content>
                    { globalMessage ? (
                        <>
                            <Card.Content className="card-body_content">
                                { globalMessage }
                            </Card.Content>

                        </>
                    ) :
                        (
                            <>
                                <Card.Content className="card-body_content forgot_input_field">
                                    <Form.Input
                                        id="forgotPasswordFormEmailInput"
                                        size="huge"
                                        type="email"
                                        onChange={inputChanged('email')}
                                        label="Email"
                                        required
                                    />

                                    <Button
                                        type="submit"
                                        loading={loading}
                                        fluid
                                        size="large"
                                        color="blue"
                                        id="forgotPasswordFormSubmit"
                                    >
                                        Send password reset code
                                    </Button>

                                </Card.Content>
                            </>
                        )}
                </Card>
            </Form>
        </div>
    );
};

ForgotPassword.propTypes = {
    loading: PropTypes.bool.isRequired,
    globalMessage: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
    authNavConfig: PropTypes.shape({
        login: PropTypes.shape({
            path: PropTypes.string
        })
    }).isRequired,
    onRedirect: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    inputChanged: PropTypes.func.isRequired
};

export default ForgotPassword;
