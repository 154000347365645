import React from 'react';
import PropTypes from 'prop-types';
import {
    Segment, Sidebar, Grid
} from 'semantic-ui-react';
import './BottomToolbar.scss';
import RetroactionForm from './Retroactions/Menu/Form/Form';

class BottomToolbar extends React.Component {
    static propTypes = {

        onPositiveFeedback: PropTypes.func.isRequired,
        onNegativeFeedback: PropTypes.func.isRequired,
        onRemoveFeedback: PropTypes.func.isRequired,
        onComment: PropTypes.func.isRequired,
        onDeleteComment: PropTypes.func.isRequired,
        onEditComment: PropTypes.func.isRequired,
        onPlay: PropTypes.func.isRequired,
        resumeAutoplay: PropTypes.func,
        pauseAutoplay: PropTypes.func,
        retroactions: PropTypes.shape({
            feedbacks: PropTypes.shape({
                relevance: PropTypes.number,
                representation: PropTypes.number,
                agreement: PropTypes.number
            }),
            comments: PropTypes.arrayOf(PropTypes.string)
        }),
        sharePermissions: PropTypes.shape({
            comment: PropTypes.bool,
            feedback: PropTypes.bool,
            download: PropTypes.bool
        }).isRequired,
        information: PropTypes.shape({
            title: PropTypes.string,
            author: PropTypes.string,
            date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            pagesCount: PropTypes.number,
            activePageNumber: PropTypes.number
        }),
        settings: PropTypes.shape({
            autoplay: PropTypes.bool,
            autoplayDelay: PropTypes.number,
            blackPageEnd: PropTypes.bool,
            spaceBarAutoplayControlSetting: PropTypes.bool,
            mouseButtonsControlSetting: PropTypes.bool,
            arrowKeysControlSetting: PropTypes.bool,
            spaceBarBackspaceControlSetting: PropTypes.bool,
            pageUpPageDownControlSetting: PropTypes.bool,
            endPresentationPage: PropTypes.string
        })
    };

    static defaultProps = {
        retroactions: {
            feedbacks: {
                relevance: 0,
                representation: 0,
                agreement: 0
            },
            comments: []
        },
        information: {
            title: '',
            author: '',
            date: Date.now(),
            pagesCount: 0,
            activePageNumber: 0
        },
        settings: {
            autoPlayDelay: 30,
            autoPlay: false,
            blackPageEnd: false,
            endPresentationPage: '',
            spaceBarAutoplayControlSetting: false,
            mouseButtonsControlSetting: false,
            arrowKeysControlSetting: false,
            spaceBarBackspaceContrtolSetting: false
        },
        resumeAutoplay: () => { },
        pauseAutoplay: () => { }
    };

    constructor(props) {
        super(props);
        this.state = {
            isRetroactionsVisible: false,
            isInformationVisible: false,
            isSettingsVisible: false
        };
        this.onToggle = this.onToggle.bind(this);
    }

    onToggle(type) {
        const { isInformationVisible, isSettingsVisible } = this.state;
        if (type === 'information') {
            this.setState({
                isInformationVisible: !isInformationVisible,
                isSettingsVisible: false,
                isRetroactionsVisible: false
            });
        } else if (type === 'settings') {
            this.setState({
                isSettingsVisible: !isSettingsVisible,
                isInformationVisible: false,
                isRetroactionsVisible: false
            });
        } else if (type === 'retroactions') {
            this.setState({
                isSettingsVisible: false,
                isInformationVisible: false,
                isRetroactionsVisible: true
            });
        }
    }

    render() {
        const sidebarVisible = true;
        const {
            onPositiveFeedback,
            onNegativeFeedback,
            onRemoveFeedback,
            onComment,
            onDeleteComment,
            onEditComment,
            onPlay,
            resumeAutoplay,
            pauseAutoplay,
            retroactions,
            sharePermissions,
            settings
        } = this.props;
        const { isRetroactionsVisible, isInformationVisible, isSettingsVisible } = this.state;
        const isOnePanelVisible = isRetroactionsVisible || isInformationVisible || isSettingsVisible;
        const containerClassBase = 'bottomtoolbar__container';
        return (
            <div
                className={`${containerClassBase} ${isOnePanelVisible ? `${containerClassBase}--locked` : ''}`.trim()}
                onMouseOver={pauseAutoplay}
                onMouseOut={resumeAutoplay}
                onFocus={() => {}}
                onBlur={e => e.preventDefault()}
            >
                <Sidebar className="form__container" as={Segment} direction="bottom" visible={sidebarVisible}>
                    <Grid className="form__container__section">
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                <RetroactionForm
                                    comments={retroactions.comments}
                                    feedbacks={retroactions.feedbacks}
                                    onComment={onComment}
                                    onDeleteComment={onDeleteComment}
                                    onEditComment={onEditComment}
                                    sharePermissions={sharePermissions}
                                    onNegativeFeedback={onNegativeFeedback}
                                    onPositiveFeedback={onPositiveFeedback}
                                    onRemoveFeedback={onRemoveFeedback}
                                    settings={settings}
                                    onPlay={onPlay}
                                    props={this.props}
                                    isSettingsVisible={isSettingsVisible}
                                    onToggle={this.onToggle}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar>
            </div>
        );
    }
}

export default BottomToolbar;
