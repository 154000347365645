import React from 'react';
import omit from 'lodash/omit';
import { TextArea } from 'semantic-ui-react';
import { withCustomTransformers } from '../Transformers/Transformers';
import { withBoundedString, withCustomValidators } from '../Validators/Validators';
import withErrorRegistering from '../Validators/withErrorRegistering';
import withValidInputMemory from '../Validators/withValidInputMemory';
import withControlledInput from './withControlledInput';
import withExitOnReturn from '../Modifiers/withExitOnReturn';
import withCancelOnEsc from '../Modifiers/withCancelOnEsc';
import withEmptyStateFlag from '../Modifiers/withEmptyStateFlag';
import withActivatable from './withActivatable';

const ErrorFreeTextArea = props => (<TextArea {...omit(props, ['error'])} />);

const withValidation = withBoundedString(withCustomValidators(ErrorFreeTextArea));
const withTransformers = withCustomTransformers(withCancelOnEsc(withExitOnReturn(withValidation)));
const withMemory = withValidInputMemory(withTransformers);
const withErrorHandling = withEmptyStateFlag(withErrorRegistering(withMemory));

export default withActivatable(withControlledInput(withErrorHandling));
