import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import FeedbackDisplay from './FeedbackDisplay/FeedbackDisplay';
import './Table.scss';

const generateAudienceString = (audience, recipientCount = 0) => {
    if (audience === 'public') {
        return '(public)';
    }
    if (recipientCount === 1) {
        return '1 recipient';
    }
    return `${recipientCount} recipients`;
};
const formatter = new Intl.DateTimeFormat();

const generateDateString = date => formatter.format(new Date(date));

const SharesTable = ({
    sortedData,
    onRowClick,
    onViewDetail,
    rowIsSelected
}) => (
    <div className="Share__shares__content__inner__table">
        <h4 className="ui header">Share History</h4>
        <Table basic="very" striped selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Play</Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">Date</Table.HeaderCell>
                    <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Audience</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Opened</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Read</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Average Time</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Comments</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Feedback</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Settings</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Delete</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {sortedData.map(share => (
                    <Table.Row
                        key={share.id}
                        onClick={() => onRowClick(share)}
                        active={rowIsSelected(share)}
                    >
                        <Table.Cell
                            textAlign="center"
                            onClick={event => {
                                event.stopPropagation();
                                onViewDetail(share);
                            }}
                        >
                            {share.number}
                        </Table.Cell>
                        <Table.Cell textAlign="center"><i className="material-icons">play_arrow</i></Table.Cell>
                        <Table.Cell textAlign="left">{generateDateString(share.createdAt)}</Table.Cell>
                        <Table.Cell textAlign="left">{share.name}</Table.Cell>
                        <Table.Cell textAlign="right">{generateAudienceString(share.audience, share.recipients.length)}</Table.Cell>
                        <Table.Cell textAlign="right">{share.opened || '-'}</Table.Cell>
                        <Table.Cell textAlign="right">{share.read || '-'}</Table.Cell>
                        <Table.Cell textAlign="right">{share.duration ? `${share.duration}` : '-'}</Table.Cell>
                        <Table.Cell textAlign="right">{share.comments === 0 ? '-' : share.comments}</Table.Cell>
                        <Table.Cell textAlign="center"><FeedbackDisplay data={share.feedbacks} /></Table.Cell>
                        <Table.Cell textAlign="center"><i className="material-icons">settings</i></Table.Cell>
                        <Table.Cell textAlign="center"><i className="material-icons">delete_forever</i></Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    </div>
);

SharesTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
    onViewDetail: PropTypes.func,
    rowIsSelected: PropTypes.func.isRequired,
    sortedData: PropTypes.array.isRequired
};

SharesTable.defaultProps = {
    onViewDetail: () => {}
};

SharesTable.FeedbackDisplay = FeedbackDisplay;

export default SharesTable;
