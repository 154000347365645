import React from 'react';
import PropTypes from 'prop-types';
import { Image, Loader, Icon } from 'semantic-ui-react';
import withSignedSrc from '../withSignedSrc/withSignedSrc';

import './HandledImage.scss';

class HandledImage extends React.Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        onLoad: PropTypes.func,
        className: PropTypes.string,
        alt: PropTypes.string,
        onClick: PropTypes.func,
        centered: PropTypes.bool,
        bordered: PropTypes.bool,
        retry: PropTypes.bool,
        delayToRetry: PropTypes.number,
        maxRetriesCount: PropTypes.number,
        waitForUpdate: PropTypes.bool,
        crossOrigin: PropTypes.string,
        isDisabled: PropTypes.bool,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        className: null,
        alt: '',
        onClick: () => {},
        onLoad: () => {},
        centered: true,
        bordered: false,
        retry: false,
        delayToRetry: 3000,
        maxRetriesCount: 6,
        waitForUpdate: false,
        crossOrigin: null,
        isDisabled: false,
        isLoading: false
    }

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            isLoading: props.retry
        };
    }

    componentDidMount() {
        const {
            waitForUpdate,
            maxRetriesCount,
            retry
        } = this.props;

        if (!waitForUpdate) {
            return retry && this.fetchImage(maxRetriesCount)
                .then(() => this.setState({ isLoading: false }))
                .catch(error => this.setState({ error }));
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        const {
            src,
            waitForUpdate,
            maxRetriesCount,
            retry
        } = this.props;

        const {
            src: prevSrc
        } = prevProps;

        if (!waitForUpdate && src !== prevSrc) {
            return retry && this.fetchImage(maxRetriesCount)
                .then(() => this.setState({ isLoading: false }))
                .then(error => this.setState({ error }));
        }

        return null;
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    fetchImage(retries) {
        const {
            delayToRetry,
            src
        } = this.props;

        if (!src || src.startsWith('data:image')) {
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            const prefetchImage = document.createElement('img');
            prefetchImage.onload = () => {
                prefetchImage.remove();
                this.setState({ isLoading: false }, resolve());
            };
            prefetchImage.onerror = () => {
                prefetchImage.remove();
                this.setState({ isLoading: true }, reject());
            };
            prefetchImage.style.display = 'none';
            prefetchImage.src = src;
        })
            .catch(() => {
                if (retries > 0) {
                    return new Promise(resolve => {
                        this.timeout = setTimeout(resolve, delayToRetry);
                    })
                        .then(() => this.fetchImage(retries - 1));
                }
                console.error('The ressource is not available');
                return false;
            });
    }

    render() {
        const {
            alt,
            bordered,
            centered,
            className,
            onClick,
            onLoad,
            crossOrigin,
            src,
            isDisabled,
            isLoading: isPropsLoading
        } = this.props;

        const {
            error,
            isLoading
        } = this.state;

        if (isPropsLoading || isLoading || error || !src || src === '') {
            return (
                <div className={className || 'HandledImage'}>
                    <Loader active={isLoading && !error} />
                    <div className={`HandledImage__placeholder${isDisabled ? '__isDisabled' : ''}`}>
                        <Icon className="HandledImage__placeholder__icon" name="image" />
                    </div>
                </div>
            );
        }
        return (
            <div className={className || 'HandledImage'}>
                <Image
                    className="HandledImage__img"
                    src={src}
                    alt={alt}
                    centered={centered}
                    bordered={bordered}
                    onClick={onClick}
                    onLoad={onLoad}
                    crossOrigin={crossOrigin}
                />
            </div>
        );
    }
}

export default withSignedSrc(HandledImage);
