import React from 'react';
import PropTypes from 'prop-types';
import InteractiveInput from '../../../BuildSection/Toolbar/Tools/InteractiveInput/InteractiveInput';

const Title = ({
    disabled,
    title,
    onBlur,
    onFocus,
    onChange
}) => (
    <div className="EditableTitle--withDefaultStyle" role="button" tabIndex="0">
        <span
            className="EditableTitle__ruler"
        >
            {title}
        </span>
        <InteractiveInput
            className="EditableTitle__field"
            type="string"
            disabled={disabled}
            value={title}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            delay={0}
        />
    </div>
);

Title.defaultProps = {
    title: '',
    disabled: false,
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {}
};

Title.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func
};

export default Title;
