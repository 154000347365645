import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import Topic from './Topic/Topic';

const Topics = ({
    activePageId,
    onActivePageChange,
    topics
}) => (
    <List>
        {topics.map(data => (
            <Topic
                key={data.id}
                id={data.id}
                pageNumber={data.pageNumber}
                topic={data.topic}
                isSelected={data.id === activePageId}
                onActivePageChange={onActivePageChange}
            />
        ))}
    </List>
);

Topics.propTypes = {
    activePageId: PropTypes.string,
    onActivePageChange: PropTypes.func.isRequired,
    topics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        pageNumber: PropTypes.number,
        topic: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string
        })
    }))
};

Topics.defaultProps = {
    activePageId: '',
    topics: []
};

export default Topics;
