import React from 'react';
import { Button } from 'semantic-ui-react';

const requestFullscreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    }
};

const cancelFullscreen = () => {
    if (document.cancelFullScreen) {
        document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
    }
};

class Fullscreen extends React.Component {
    state = { isFullscreen: false };

    onFullscreenToggle() {
        const { isFullscreen } = this.state;

        if (isFullscreen) {
            cancelFullscreen();
        } else {
            requestFullscreen();
        }
        this.setState({
            isFullscreen: !isFullscreen
        });
    }

    render() {
        const { isFullscreen } = this.state;

        return (
            <div className="fullscreen__container">
                <Button
                    size="tiny"
                    icon
                    onClick={e => {
                        e.currentTarget.blur();
                        return this.onFullscreenToggle();
                    }}
                >
                    <i className="material-icons">
                        {!isFullscreen ? 'fullscreen' : 'fullscreen_exit'}
                    </i>
                </Button>
            </div>
        );
    }
}

export default Fullscreen;
