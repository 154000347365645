import React from 'react';
import PropTypes from 'prop-types';

const PageCounter = ({
    activePageNumber,
    pagesCount
}) => (
    <div className="pagecounter__container">
        <span>{activePageNumber}</span>
        <span>/</span>
        <span>{pagesCount}</span>
    </div>
);

PageCounter.propTypes = {
    activePageNumber: PropTypes.number,
    pagesCount: PropTypes.number
};

PageCounter.defaultProps = {
    activePageNumber: 0,
    pagesCount: 0
};

export default PageCounter;
