import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

const ListAsideDetails = ({ list }) => (
    <List>
        {list.map(item => (
            <List.Item key={item.label}>
                <span className="AsideDetails__info__item__label">
                    <span>{item.label}</span>
                </span>
                <strong className="AsideDetails__info__item__data">{item.value}</strong>
            </List.Item>
        ))}
    </List>
);

ListAsideDetails.propTypes = {
    list: PropTypes.array.isRequired
};

export default ListAsideDetails;
