import React from 'react';
import PropTypes from 'prop-types';

const withActivatable = WrappedComponent => {
    const ActivatableInput = class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                active: false
            };
            this.onFocus = this.onFocus.bind(this);
            this.onBlur = this.onBlur.bind(this);
        }

        onFocus(event) {
            const { onFocus } = this.props;
            this.setState({
                active: true
            });
            onFocus(event);
        }

        onBlur(event) {
            const { onBlur } = this.props;
            this.setState({
                active: false
            });
            onBlur(event);
        }

        render() {
            const { active } = this.state;
            const { className } = this.props;
            return (
                <WrappedComponent
                    {...this.props}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    className={active ? `${className} active` : className}
                />
            );
        }
    };

    ActivatableInput.propTypes = {
        className: PropTypes.string,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func
    };

    ActivatableInput.defaultProps = {
        className: '',
        onFocus: () => { },
        onBlur: () => { }
    };

    return ActivatableInput;
};

export default withActivatable;
