import React from 'react';
import { Button, Icon, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import './Button.scss';

const DecksignButton = ({
    color,
    icon,
    materialIcon,
    size,
    className,
    disabled,
    image,
    onBlur,
    onClick,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    text
}) => {
    let ButtonIcon = '';
    if (icon) {
        ButtonIcon = <Icon {...pickBy({ size, color }, Boolean)} name={icon} disabled={disabled} />;
    }
    if (materialIcon) {
        ButtonIcon = (
            <Icon {...pickBy({ size, color }, Boolean)} disabled={disabled}>
                <i className={`material-icons${disabled ? ' DecksignButton--isDisabled' : ''}`}>{materialIcon}</i>
            </Icon>
        );
    }
    if (image) {
        ButtonIcon = (
            <Icon {...pickBy({ size, color }, Boolean)} disabled={disabled}>
                <Image src={image.src} alt={image.alt} />
            </Icon>
        );
    }
    return (
        <Button
            className={className}
            disabled={disabled}
            icon={Boolean(ButtonIcon)}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...(text ? { labelPosition: 'left' } : {})}
        >
            {ButtonIcon}
            {text}
        </Button>
    );
};

DecksignButton.defaultProps = {
    color: 'black',
    icon: undefined,
    size: undefined,
    className: 'DecksignButton',
    disabled: false,
    image: undefined,
    materialIcon: undefined,
    text: '',
    onClick: () => {},
    onBlur: () => {},
    onFocus: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {}
};

DecksignButton.propTypes = {
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    materialIcon: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    image: PropTypes.object,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
};

export default DecksignButton;
