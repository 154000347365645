import React from 'react';
import PropTypes from 'prop-types';
import isFinite from 'lodash/isFinite';
import toNumber from 'lodash/toNumber';
import round from 'lodash/round';

const updateValue = (value, updateStep, min, max) => {
    if (value === '') return value;
    const numericValue = toNumber(value);
    let updatedValue = numericValue + updateStep;
    if (isFinite(min)) {
        updatedValue = Math.max(updatedValue, min);
    }
    if (isFinite(max)) {
        updatedValue = Math.min(updatedValue, max);
    }
    return {
        value: round(updatedValue, 10)
    };
};

const withUnboundedFixedIncrement = WrappedComponent => {
    const wrapped = props => {
        const { step, onChange } = props;
        const increment = value => {
            if (onChange && isFinite(step)) {
                onChange({}, updateValue(value, step));
            }
        };
        const decrement = value => {
            if (onChange && isFinite(step)) {
                onChange({}, updateValue(value, -1 * step));
            }
        };
        return (<WrappedComponent {...props} handleIncrement={increment} handleDecrement={decrement} />);
    };
    wrapped.propTypes = {
        step: PropTypes.number,
        onChange: PropTypes.func,
        min: PropTypes.number,
        max: PropTypes.number
    };
    wrapped.defaultProps = {
        step: 0,
        onChange: () => { },
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
    };
    return wrapped;
};

const withFixedIncrement = WrappedComponent => {
    const wrapped = props => {
        const {
            step, onChange, min, max
        } = props;
        const increment = value => {
            if (isFinite(step)) {
                onChange({}, updateValue(value, step, min, max));
            }
        };
        const decrement = value => {
            if (isFinite(step)) {
                onChange({}, updateValue(value, -1 * step, min, max));
            }
        };
        return (<WrappedComponent {...props} handleIncrement={increment} handleDecrement={decrement} />);
    };
    wrapped.propTypes = {
        step: PropTypes.number,
        onChange: PropTypes.func,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired
    };
    wrapped.defaultProps = {
        step: 1,
        onChange: () => { }
    };
    return wrapped;
};

export {
    withUnboundedFixedIncrement,
    withFixedIncrement
};
