import React from 'react';
import PropTypes from 'prop-types';

import './BackToLogin.scss';

const BackToLogin = ({
    authNavConfig,
    onRedirect
}) => (
    <a
        role="button"
        tabIndex="0"
        onClick={() => onRedirect(authNavConfig.login.path)}
        className="Login__form__backToLoginLink"
    >
        <i className="material-icons Login__form__backToLoginLink__icon">
            keyboard_backspace
        </i>
        Back to login
    </a>
);
BackToLogin.propTypes = {
    authNavConfig: PropTypes.shape({
        login: PropTypes.shape({
            path: PropTypes.string
        })
    }),
    onRedirect: PropTypes.func
}.isRequired;

export default BackToLogin;
