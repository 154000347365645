import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isBoolean from 'lodash/isBoolean';
import {
    Button,
    Form,
    Icon,
    Message
} from 'semantic-ui-react';

import ButtonWithPopup from '../ButtonWithPopup/ButtonWithPopup';
import './ButtonWithForm.scss';

class ButtonWithForm extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        closeOnFail: PropTypes.bool,
        error: PropTypes.string,
        isOpen: PropTypes.bool,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        onOpen: PropTypes.func,
        onSubmit: PropTypes.func
    }

    static defaultProps = {
        children: null,
        className: '',
        closeOnFail: false,
        error: '',
        isOpen: false,
        onCancel: () => {},
        onClose: () => {},
        onOpen: () => {},
        onSubmit: () => true
    }

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            isOpen: props.isOpen
        };
    }

    componentDidUpdate(prevProps) {
        const {
            isOpen: isOpenState
        } = this.state;

        const {
            isOpen
        } = this.props;

        if (prevProps.isOpen !== isOpen && isOpen !== isOpenState) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isOpen
            });
        }
    }

    handleOpen() {
        const {
            onOpen
        } = this.props;

        this.setState({ isOpen: true }, onOpen);
    }

    handleClose() {
        const {
            onClose
        } = this.props;

        this.setState({ isOpen: false }, onClose);
    }

    onCancel(...args) {
        const {
            onCancel
        } = this.props;

        onCancel(...args);

        this.handleClose();
    }

    onSubmit(...args) {
        const {
            onSubmit,
            closeOnFail
        } = this.props;

        const submitFeedback = onSubmit(...args);

        if (isBoolean(submitFeedback)) {
            if (submitFeedback || closeOnFail) {
                this.handleClose();
            }
        } else if (submitFeedback instanceof Promise) {
            submitFeedback
                .then(() => {
                    this.handleClose();
                })
                .catch(() => {
                    if (closeOnFail) {
                        this.handleClose();
                    }
                });
        } else {
            throw new Error('Button with form component onSubmit props should return a boolean or a promise');
        }
    }

    render() {
        const {
            children,
            error,
            className,
            ...buttonWithPropupProps
        } = this.props;

        const {
            isOpen
        } = this.state;

        return (
            <ButtonWithPopup
                {...buttonWithPropupProps}
                className={`buttonWithForm ${className}`.trim()}
                isOpen={isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
            >
                {error && <Message error>{error}</Message>}
                <Form
                    onSubmit={this.onSubmit}
                    className="buttonWithForm__form"
                >
                    {children}
                    <Form.Group widths="2">
                        <Button
                            type="submit"
                            primary
                        >
                            <Icon
                                name="check"
                            />
                            confirm
                        </Button>
                        <Button
                            type="reset"
                            onClick={this.onCancel}
                        >
                            <Icon
                                name="cancel"
                            />
                            cancel
                        </Button>
                    </Form.Group>
                </Form>
            </ButtonWithPopup>
        );
    }
}

export default ButtonWithForm;
