import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import Page from './Page/Page';

const Pages = ({
    activePageId,
    onActivePageChange,
    pages
}) => (
    <List>
        {pages.map(data => (
            <Page
                key={data.id}
                id={data.id}
                pageNumber={data.pageNumber}
                thumbnailSrc={data.thumbnailSrc}
                isSelected={data.id === activePageId}
                onActivePageChange={onActivePageChange}
            />
        ))}
    </List>
);

Pages.propTypes = {
    activePageId: PropTypes.string,
    onActivePageChange: PropTypes.func.isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        pageNumber: PropTypes.number,
        thumbnailSrc: PropTypes.string
    }))
};

Pages.defaultProps = {
    activePageId: '',
    pages: []
};

export default Pages;
