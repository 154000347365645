import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { labelSizeInPx } from '../feedbackDisplay.config';
import './PositiveFeedbackBar.scss';

const PositiveFeedbackBar = ({
    data, barWidth, offset, needsPopup, totalOfFeedbacks
}) => (
    <div
        style={{
            right: offset
        }}
        className="share-table__feedbacks-graph__positive"
    >
        <div
            style={{
                width: labelSizeInPx
            }}
            className="share-table__feedbacks-graph__positive__indicator"
        >
            {needsPopup ?
                (
                    <Popup
                        position="top center"
                        trigger={(
                            <p className="share-table__feedbacks-graph__positive__popup-triger">
                                ...
                            </p>
                        )}
                        content={totalOfFeedbacks <= 0 ? '' : data}
                    />
                ) :
                (
                    <p className="share-table__feedbacks-graph__positive__indicator__count">
                        {totalOfFeedbacks <= 0 ? '' : data}
                    </p>
                )}
        </div>
        <div
            className="share-table__feedbacks-graph__positive__bar"
            style={{ width: barWidth || 0 }}
        />
    </div>
);

PositiveFeedbackBar.propTypes = {
    data: PropTypes.number,
    barWidth: PropTypes.number,
    needsPopup: PropTypes.bool,
    totalOfFeedbacks: PropTypes.number,
    offset: PropTypes.number
};

PositiveFeedbackBar.defaultProps = {
    data: 0,
    barWidth: 0,
    needsPopup: false,
    totalOfFeedbacks: 0,
    offset: 0
};

export default PositiveFeedbackBar;
