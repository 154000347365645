import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-unknown-property */
const Circle = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" className="Common__SideNav__Container__object__img">
        <circle cx="151.5" cy="224.5" r="27.5" fill={fill || 'url(#circles-a)'} fillRule="evenodd" transform="translate(-124 -197)" />
    </svg>
);
/* eslint-enable react/no-unknown-property */

Circle.propTypes = {
    fill: PropTypes.string
};

Circle.defaultProps = {
    fill: ''
};

export default Circle;
