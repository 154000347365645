import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ImageIcon = ({ iconProps }) => (
    <Icon {...iconProps}>
        <i className="material-icons-outlined photo-icon">
            photo
        </i>
    </Icon>
);

ImageIcon.propTypes = {
    iconProps: PropTypes.object
};

ImageIcon.defaultProps = {
    iconProps: PropTypes.object
};

export default ImageIcon;
