import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Divider, Ref } from 'semantic-ui-react';

const itemSource = {
    beginDrag: props => (
        {
            page: props.children.props.item,
            fullWidth: props.children.props.fullWidth,
            index: props.index
        }
    ),
    canDrag: props => (
        !props.isReordering && !props.disabled
    ),
    endDrag: (props, monitor) => {
        const didDrop = monitor.didDrop();
        const dropTarget = monitor.getDropResult();

        if (didDrop) {
            props.moveItem(props.index, dropTarget.index);
        }
    }
};

const itemTarget = {
    hover: (props, monitor) => {
        const item = monitor.getItem();
        item.overIndex = props.index;
    },
    canDrop: (props, monitor) => {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        return dragIndex !== hoverIndex && !props.disabled;
    },
    drop: props => ({ index: props.index })
};

const collectDrag = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    draggingIndex: monitor.getItem()?.index
});

const collectDrop = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    overIndex: monitor.getItem()?.overIndex
});

const addStyle = (children, isOver, isDragging, draggingIndex, overIndex) => {
    let classes = children.props.className;

    if (isOver) {
        classes += ' Plan__storyboard__pages__list__item--isTarget';
        if (overIndex > draggingIndex) {
            classes += ' Plan__storyboard__pages__list__item--isTargetGreater';
        } else if (overIndex < draggingIndex) {
            classes += ' Plan__storyboard__pages__list__item--isTargetLess';
        }
    }

    if (isDragging) {
        classes += ' Plan__storyboard__pages__list__item--isDragging';
    }

    return React.cloneElement(children, { className: classes });
};

const AbstractDraggable = memo(({
    children,
    isOver,
    isDragging,
    draggingIndex,
    overIndex,
    connectDragSource,
    connectDropTarget,
    connectDragPreview
}) => {
    useEffect(() => {
        if (connectDragPreview) {
            connectDragPreview(getEmptyImage(), { captureDraggingState: true });
        }
    }, []);

    return (
        <>
            {isOver && draggingIndex > overIndex && (children.props.fullWidth ?
                <Divider /> :
                <div className="vertical-divider" />
            )}
            <Ref innerRef={instance => { connectDragSource(instance); connectDropTarget(instance); }}>
                {(addStyle(children, isOver, isDragging, draggingIndex, overIndex))}
            </Ref>
            {isOver && draggingIndex < overIndex && (children.props.fullWidth ?
                <Divider /> :
                <div className="vertical-divider" />
            )}
        </>
    );
});

AbstractDraggable.propTypes = {
    children: PropTypes.node.isRequired,
    isOver: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,
    draggingIndex: PropTypes.number,
    overIndex: PropTypes.number,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired
};

AbstractDraggable.defaultProps = {
    draggingIndex: 0,
    overIndex: 0
};

export default DropTarget('draggable', itemTarget, collectDrop)(DragSource('draggable', itemSource, collectDrag)(AbstractDraggable));
