import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './WorkspaceHeader.scss';

const WorkspaceHeader = ({
    openInsertMenu,
    openPreview,
    zoomComponent,
    shapeQuickInsert,
    textQuickInsert
}) => (
    <div className="WorkspaceHeader">
        {/* Section left */}
        <div className="WorkspaceHeader__side WorkspaceHeader__side--left">
            <Button onClick={openPreview} icon><Icon name="play" /></Button>
        </div>
        {/* Middle section */}
        <div className="WorkspaceHeader__side WorkspaceHeader__side--middle">
            {shapeQuickInsert}
            <Button onClick={openInsertMenu} secondary icon>
                <i className="material-icons">add</i>
            </Button>
            {textQuickInsert}
        </div>
        {/* End section */}
        <div className="WorkspaceHeader__side WorkspaceHeader__side--right">
            {zoomComponent}
        </div>
    </div>
);

WorkspaceHeader.propTypes = {
    openInsertMenu: PropTypes.func.isRequired,
    openPreview: PropTypes.func.isRequired,
    zoomComponent: PropTypes.node.isRequired,
    shapeQuickInsert: PropTypes.node.isRequired,
    textQuickInsert: PropTypes.node.isRequired
};

export default WorkspaceHeader;
