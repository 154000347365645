import React from 'react';
import PropTypes from 'prop-types';

const PasswordRequirements = ({
    passwordRequirements
}) => (
    <div className="signup_requirement_message">
        <div className="requirement_label"> Your password must have:</div>
        <div className="requirement_checkboxes">
            {passwordRequirements.map(validator => (
                <div className="validator_checkboxes" key={validator.label}>
                    {validator.valid ?
                        <i id="valid" className="material-icons">check</i> :
                        <i className="material-icons">clear</i>
                    }
                    <div>{validator.label}</div>
                </div>
            ))}
        </div>
    </div>

);
PasswordRequirements.propTypes = {
    passwordRequirements: PropTypes.array
}.isRequired;

export default PasswordRequirements;
