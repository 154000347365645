import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';
import { DragSource, DropTarget } from 'react-dnd';
import './Page.scss';
import PageCard from '../../../../Common/PageCard/PageCard';

const Types = {
    PAGE: 'page'
};

const pageTarget = {
    hover(props, monitor) {
        if (props.id === monitor.getItem().id) {
            props.hoverChange(null, null);
            return;
        }
        const source = monitor.getItem();
        props.hoverChange(props.id, (source.index > props.index ? 'above' : 'below'));
    },
    drop(props) {
        return {
            id: props.id,
            index: props.index
        };
    }
};

const pageSource = {
    beginDrag(props) {
        props.onDrag(props.id);
        return {
            id: props.id,
            index: props.index
        };
    },
    endDrag(props, monitor) {
        props.hoverChange(null, null);
        if (!monitor.didDrop()) {
            return;
        }
        const source = monitor.getItem();
        const target = monitor.getDropResult();
        if (source.id === target.id) {
            return;
        }
        props.onDrop(source.id, target.id, (source.index > target.index ? 'above' : 'below'));
    }
};

const collectTarget = (connect, monitor) => ({
    isOver: monitor.isOver(),
    connectDropTarget: connect.dropTarget()
});

const collectSource = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
});

class Page extends React.Component {
    componentDidMount() {
        const {
            thumbnailSrc,
            connectDragPreview
        } = this.props;

        const image = new Image(50, 50);
        image.src = thumbnailSrc;
        image.onload = () => connectDragPreview(image);
    }

    render() {
        const {
            connectDragSource,
            connectDropTarget,
            currentPageHoverId,
            hoverRelativePosition,
            id,
            imageOptions,
            isDisabled,
            isDragging,
            isSelected,
            leftSideMenuOptions,
            onActivePageChange,
            onMouseEnter,
            pageNumber,
            thumbnailSrc
        } = this.props;
        return (
            connectDropTarget(
                <div className="dropTarget">
                    {connectDragSource(
                        <div className="dragSource">
                            {(currentPageHoverId === id && hoverRelativePosition === 'above') ?
                                <Divider /> :
                                null}
                            <div className={
                                `item buildSection__navigation__page ${isSelected ? 'buildSection__navigation__page--selected' : ''
                                }${isDragging ? 'buildSection__navigation__page--dragging' : ''
                                }`.trim()
                            }
                            >
                                <div className="PageNumber">
                                    <span>{isDisabled ? '' : pageNumber}</span>
                                </div>
                                <PageCard
                                    item={{ _id: id, thumbnailUrl: thumbnailSrc, hidden: isDisabled }}
                                    isSelected={isSelected}
                                    isDisabled={isDisabled}
                                    onClick={() => onActivePageChange(id)}
                                    onMouseEnter={onMouseEnter}
                                    withContextualMenu
                                    leftSideMenuOptions={leftSideMenuOptions}
                                    imageOptions={imageOptions}
                                />
                            </div>
                            {(currentPageHoverId === id && hoverRelativePosition === 'below') ?
                                <Divider /> :
                                null}
                        </div>
                    )}
                </div>
            )
        );
    }
}

Page.propTypes = {
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    currentPageHoverId: PropTypes.string,
    hoverRelativePosition: PropTypes.string,
    id: PropTypes.string,
    imageOptions: PropTypes.object,
    isDisabled: PropTypes.bool,
    isDragging: PropTypes.bool,
    isSelected: PropTypes.bool,
    leftSideMenuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    onActivePageChange: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    pageNumber: PropTypes.number,
    thumbnailSrc: PropTypes.string
};

Page.defaultProps = {
    currentPageHoverId: null,
    hoverRelativePosition: null,
    id: '',
    imageOptions: {},
    isDisabled: false,
    isDragging: false,
    isSelected: false,
    leftSideMenuOptions: [],
    pageNumber: 0,
    thumbnailSrc: ''
};

export default DropTarget(Types.PAGE, pageTarget, collectTarget)(
    DragSource(Types.PAGE, pageSource, collectSource)(Page)
);
