import React from 'react';
import PropTypes from 'prop-types';
import { withRelativeIncrement } from './withRelativeIncrement';

const RESPONSIVNESS_BOUND = 4;

const withDragIncrementHandler = WrappedComponent => {
    const DragIncrementHandler = class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                mouseIsDown: false
            };
            this.handleMouseDown = this.handleMouseDown.bind(this);
            this.handleMouseUp = this.handleMouseUp.bind(this);
            this.handleMouseMove = this.handleMouseMove.bind(this);
        }

        componentWillUnmount() {
            document.removeEventListener('mouseup', this.handleMouseUp);
            document.removeEventListener('mousemove', this.handleMouseMove);
        }

        handleMouseDown = event => {
            const { screenY } = event;
            const { value } = this.props;
            this.setState({
                mouseIsDown: true,
                mouseDownYPosition: screenY,
                referenceValue: value
            });
            document.addEventListener('mouseup', this.handleMouseUp);
            document.addEventListener('mousemove', this.handleMouseMove);
        }

        handleMouseUp = () => {
            this.setState({
                mouseIsDown: false,
                mouseDownYPosition: undefined
            });
            document.removeEventListener('mouseup', this.handleMouseUp);
            document.removeEventListener('mousemove', this.handleMouseMove);
        }

        handleMouseMove = event => {
            const {
                onDrag,
                handleRelativeIncrement,
                scrollSensitivity
            } = this.props;
            const {
                mouseDownYPosition,
                mouseIsDown,
                referenceValue
            } = this.state;
            const { screenY } = event;
            if (mouseIsDown) {
                const deltaY = screenY - mouseDownYPosition;
                if (Math.abs(deltaY) >= RESPONSIVNESS_BOUND) {
                    handleRelativeIncrement(referenceValue, -1 * (deltaY / scrollSensitivity));
                }
                if (onDrag) {
                    onDrag(event);
                }
            }
        }

        render = () => {
            const {
                scrollSensitivity,
                handleRelativeIncrement,
                dragIncrement,
                ...cleanedProps
            } = this.props;

            return (
                <WrappedComponent
                    {...cleanedProps}
                    onMouseDown={dragIncrement ? this.handleMouseDown : undefined}
                />
            );
        }
    };
    DragIncrementHandler.propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        onDrag: PropTypes.func,
        handleRelativeIncrement: PropTypes.func.isRequired,
        scrollSensitivity: PropTypes.number,
        dragIncrement: PropTypes.bool
    };
    DragIncrementHandler.defaultProps = {
        onDrag: () => { },
        scrollSensitivity: 1,
        value: undefined,
        dragIncrement: false
    };
    return DragIncrementHandler;
};

export default WrappedComponent => withRelativeIncrement(withDragIncrementHandler(WrappedComponent));
