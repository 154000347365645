import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import './UnknownTypeIcon.scss';

const UnknownTypeIcon = ({ iconProps }) => (
    <Icon
        {...{
            className: `buildSection__navigation__shape__icon--unknownType ${iconProps.className}`.trim(),
            ...iconProps
        }}
    >
        <i className="material-icons">
            error_outline
        </i>
    </Icon>
);

UnknownTypeIcon.propTypes = {
    iconProps: PropTypes.object
};

UnknownTypeIcon.defaultProps = {
    iconProps: PropTypes.object
};

export default UnknownTypeIcon;
