import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    Grid,
    Form
} from 'semantic-ui-react';

class Feedback extends React.Component {
    static propTypes = {
        value: PropTypes.number,
        label: PropTypes.string,
        onNegativeFeedback: PropTypes.func.isRequired,
        onPositiveFeedback: PropTypes.func.isRequired,
        onRemoveFeedback: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        value: 0,
        label: '',
        disabled: true
    };

    checkValue = (value, required) => {
        let cls = '';
        if (value === required) {
            if (value === 1) {
                cls = 'feedbacks__section__listitem__icons--positive';
            } else if (value === -1) {
                cls = 'feedbacks__section__listitem__icons--negative';
            } else if (value === 0) {
                cls = '';
            }
        }
        return cls;
    };

    render() {
        const {
            value,
            label,
            onNegativeFeedback,
            onPositiveFeedback,
            onRemoveFeedback,
            disabled
        } = this.props;
        return (
            <List.Item className="feedbacks__section__listitem">
                <Grid>
                    <Grid.Row>
                        <Grid.Column className="labels__description" width={13}>
                            <List.Description>
                                {label}
                                {' '}
                            </List.Description>
                        </Grid.Column>
                        <Grid.Column className="labels__arrows" width={3}>
                            <Form.Group inline className="feedbacks__section__radiogroup">
                                <Form.Radio
                                    className={`feedbacks__section__radiogroup--arrowUpIcon ${this.checkValue(value, 1)}`}
                                    checked={this.checkValue(value, 1)}
                                    control="input"
                                    type="radio"
                                    name="htmlRadios"
                                    onChange={() => (value === 1 ?
                                        onRemoveFeedback(label) :
                                        onPositiveFeedback(label))}
                                    label={(
                                        <i className="material-icons">arrow_upward </i>
                                    )}
                                    hidden
                                    disabled={disabled}
                                />
                                <Form.Radio
                                    className={`feedbacks__section__radiogroup--arrowDownIcon ${this.checkValue(value, -1)}`}
                                    checked={this.checkValue(value, -1)}
                                    control="input"
                                    type="radio"
                                    name="htmlRadios"
                                    onChange={() => (value === -1 ?
                                        onRemoveFeedback(label) :
                                        onNegativeFeedback(label)
                                    )}
                                    label={(
                                        <i className="material-icons">arrow_downward</i>
                                    )}
                                    hidden
                                    disabled={disabled}
                                />
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </List.Item>
        );
    }
}

export default Feedback;
