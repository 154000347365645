import React from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    Grid,
    Label,
    Icon,
    Divider,
    Ref
} from 'semantic-ui-react';

import HandledImage from '../../HandledImage/HandledImage';

import './ImportImageFile.scss';

class ImportImageFile extends React.Component {
    constructor(props) {
        super(props);

        this.handleImagePreviewRef = this.handleImagePreviewRef.bind(this);
        this.onPreviewLoad = this.onPreviewLoad.bind(this);
    }

    state = {
        height: 0,
        width: 0
    }

    componentDidMount() {
        const {
            resetImageSource
        } = this.props;

        resetImageSource();
    }

    handleImagePreviewRef(ref) {
        this.image = ref && ref.querySelector('img');
    }

    onPreviewLoad(e) {
        const {
            enableSubmit
        } = this.props;

        const height = this.image.naturalHeight;
        const width = this.image.naturalWidth;

        this.setState({
            height,
            width
        });

        enableSubmit(e);
    }

    render() {
        const {
            disableSubmit,
            handleSourceChange,
            handleNewFile,
            handleSubmit,
            pageWidth,
            pageHeight,
            itemBase,
            imageSrc,
            submitIsEnabled
        } = this.props;

        const {
            height,
            width
        } = this.state;

        return (
            <Form
                className="import-image-form"
                onSubmit={event => {
                    event.preventDefault();
                    const assetId = imageSrc.split('/').pop();
                    handleSubmit(event, event.target, itemBase, assetId, { pageWidth, pageHeight });
                    return false;
                }}
            >
                <Grid
                    verticalAlign="middle"
                    columns={2}
                    divided
                >
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field>
                                <label
                                    htmlFor="localImage"
                                    className="import-image-form__local-file-button"
                                >
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="localImage"
                                        onChange={e => handleNewFile(e.target.files[0])}
                                        hidden
                                    />
                                    <Label
                                        color="blue"
                                    >
                                        choose file
                                    </Label>
                                </label>
                            </Form.Field>
                            <Divider horizontal>
                                Or
                            </Divider>
                            <Form.Field
                                control="input"
                                type="text"
                                id="url"
                                label="Image URL:"
                                onChange={e => handleSourceChange(e.target.value)}
                            />
                            <Form.Field
                                control="input"
                                type="number"
                                id="height"
                                value={height}
                                readOnly
                                hidden
                            />
                            <Form.Field
                                control="input"
                                type="number"
                                id="width"
                                value={width}
                                readOnly
                                hidden
                            />
                        </Grid.Column>
                        <Grid.Column>
                            {imageSrc && (
                                <Ref innerRef={this.handleImagePreviewRef}>
                                    <HandledImage
                                        className="import-image-form__preview-image"
                                        src={imageSrc}
                                        onLoad={this.onPreviewLoad}
                                        onError={disableSubmit}
                                        crossOrigin="Anonymous"
                                    />
                                </Ref>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row textAlign="center">
                        <Form.Button
                            type="submit"
                            className="import-image-form__submit"
                            primary
                            disabled={!submitIsEnabled}
                        >
                            <Icon name="add" />
                            Add Image
                        </Form.Button>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }
}

ImportImageFile.propTypes = {
    disableSubmit: PropTypes.func.isRequired,
    enableSubmit: PropTypes.func.isRequired,
    handleNewFile: PropTypes.func.isRequired,
    handleSourceChange: PropTypes.func.isRequired,
    resetImageSource: PropTypes.func.isRequired,
    imageSrc: PropTypes.string,
    submitIsEnabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    pageWidth: PropTypes.number,
    pageHeight: PropTypes.number,
    itemBase: PropTypes.object
};

ImportImageFile.defaultProps = {
    imageSrc: '',
    submitIsEnabled: false,
    pageWidth: 0,
    pageHeight: 0,
    itemBase: {}
};

export default ImportImageFile;
