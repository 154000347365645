import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import {
    Modal, Header, Form, Button, Input
} from 'semantic-ui-react';
import HandledImage from '../HandledImage/HandledImage';
import Carousel from '../AsideDetails/Carousel/Carousel';
import './ExportModal.scss';

const exportOptions = [
    {
        key: 'pdf',
        text: 'pdf',
        value: 'pdf'
    },
    {
        key: 'png',
        text: 'png',
        value: 'png'
    }
];

class ExportModal extends React.Component {
    state = {
        exportType: 'pdf',
        selectedPageFilter: 'all',
        inputPageRange: {
            from: Infinity,
            to: Infinity
        },
        previewedPageRange: {
            from: Infinity,
            to: Infinity
        }
    };

    inputRef = createRef();

    onRadioChange = (e, { value }) => {
        this.setState(
            { selectedPageFilter: value },
            () => {
                if (value === 'range') {
                    this.inputRef.current.focus();
                }
            }
        );
    };

    onDropdownChange = (e, { value }) => {
        this.setState({ exportType: value });
    };

    onInputFocus = () => {
        this.setState({ selectedPageFilter: 'range' });
    }

    onInputChange = (e, { value }) => {
        const { inputPageRange } = this.state;
        const updatedRange = { ...inputPageRange };

        if (value > 0) {
            updatedRange[e.target.id] = parseInt(value);
            this.setState({ inputPageRange: updatedRange });
        }
    }

    onInputBlur = e => {
        const { thumbnails } = this.props;
        const { inputPageRange, previewedPageRange } = this.state;
        const updatedInputRange = { ...inputPageRange };
        const updatedPreviewedRange = { ...previewedPageRange };

        if (e.target.id === 'from') {
            if (inputPageRange.from > inputPageRange.to) {
                updatedInputRange[e.target.id] = inputPageRange.to;
                updatedPreviewedRange[e.target.id] = inputPageRange.to;
            } else {
                updatedPreviewedRange[e.target.id] = inputPageRange.from;
            }
        } else if (e.target.id === 'to') {
            if (inputPageRange.to > thumbnails.length) {
                updatedInputRange[e.target.id] = thumbnails.length;
                updatedPreviewedRange[e.target.id] = thumbnails.length;
            } else if (inputPageRange.to < inputPageRange.from) {
                updatedInputRange[e.target.id] = inputPageRange.from;
                updatedPreviewedRange[e.target.id] = inputPageRange.from;
            } else {
                updatedPreviewedRange[e.target.id] = inputPageRange.to;
            }
        }
        this.setState({
            inputPageRange: updatedInputRange,
            previewedPageRange: updatedPreviewedRange
        });
    }

    onSubmit = () => {
        const { exportType, selectedPageFilter } = this.state;
        const { onExport, selectedPages } = this.props;
        let exportReturn;
        switch (selectedPageFilter) {
            case 'range':
                exportReturn = onExport(exportType, this.getFilteredPages());
                break;
            case 'selection':
                exportReturn = onExport(exportType, selectedPages);
                break;
            case 'all':
            default:
                exportReturn = onExport(exportType);
                break;
        }
        if (exportReturn instanceof Promise) {
            return exportReturn.then(() => {
                this.onClose();
            });
        }
        return this.onClose();
    }

    onClose = () => {
        const { onClose } = this.props;
        onClose();
        this.setState({
            selectedPageFilter: 'all',
            inputPageRange: {
                from: Infinity,
                to: Infinity
            },
            previewedPageRange: {
                from: Infinity,
                to: Infinity
            }
        });
    }

    getFilteredPages = () => {
        const { previewedPageRange } = this.state;
        const allPages = [];
        for (let i = parseInt(previewedPageRange.from); i <= parseInt(previewedPageRange.to); i++) {
            allPages.push(i);
        }
        return allPages;
    };

    getFilteredThumbnails = () => {
        const { selectedPageFilter } = this.state;
        const { thumbnails, selectedPages } = this.props;
        const pages = this.getFilteredPages();
        switch (selectedPageFilter) {
            case 'range':
                return pages.length === 0 ?
                    thumbnails :
                    thumbnails.filter((_, index) => pages.includes(index + 1));
            case 'selection':
                return thumbnails.filter((_, index) => selectedPages.includes(index + 1));
            case 'all':
            default:
                return thumbnails;
        }
    }

    render() {
        const {
            exportType,
            selectedPageFilter,
            inputPageRange
        } = this.state;
        const { isOpen, selectedPages } = this.props;
        return (
            <Modal
                className="ExportModal"
                open={isOpen}
                onClose={this.onClose}
                size="small"
                closeIcon
                ref={node => { this.portal = node; }} // allows testing with Enzyme
            >
                <Header icon="external alternate" content="Export options" />
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <Form.Dropdown
                                label="Export as..."
                                placeholder="export type"
                                value={exportType}
                                selection
                                options={exportOptions}
                                onChange={this.onDropdownChange}
                            />
                        </Form.Field>
                        <Form.Group grouped>
                            <Form.Field label="Pages" />
                            <Form.Field>
                                <Form.Radio
                                    label="All"
                                    name="radioGroup"
                                    value="all"
                                    checked={selectedPageFilter === 'all'}
                                    onChange={this.onRadioChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Radio
                                    label="Selection"
                                    name="radioGroup"
                                    value="selection"
                                    checked={selectedPageFilter === 'selection'}
                                    disabled={selectedPages.length === 0}
                                    onChange={this.onRadioChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Radio
                                    label="Range"
                                    name="radioGroup"
                                    value="range"
                                    checked={selectedPageFilter === 'range'}
                                    onChange={this.onRadioChange}
                                />
                                <div className="RangeSelector">
                                    <Input
                                        ref={this.inputRef}
                                        id="from"
                                        type="number"
                                        label="From: "
                                        size="mini"
                                        placeholder="1"
                                        value={inputPageRange.from}
                                        onChange={this.onInputChange}
                                        onFocus={this.onInputFocus}
                                        onBlur={this.onInputBlur}
                                        fluid
                                    />
                                    <Input
                                        id="to"
                                        type="number"
                                        label="To: "
                                        size="mini"
                                        placeholder="1"
                                        value={inputPageRange.to}
                                        onChange={this.onInputChange}
                                        onFocus={this.onInputFocus}
                                        onBlur={this.onInputBlur}
                                        fluid
                                    />
                                </div>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <Carousel
                        paginationSize="mini"
                        elements={this.getFilteredThumbnails().map(thumbnail => (
                            <HandledImage
                                className="PreviewThumbnails"
                                src={thumbnail}
                            />
                        ))}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button className="ModalCancelButton" onClick={this.onClose}>
                        Cancel
                    </Button>
                    <Button className="ModalExportButton" color="blue" onClick={this.onSubmit}>
                        Export
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool,
    thumbnails: PropTypes.arrayOf(PropTypes.string),
    selectedPages: PropTypes.arrayOf(PropTypes.number),
    onExport: PropTypes.func,
    onClose: PropTypes.func
};

ExportModal.defaultProps = {
    isOpen: false,
    thumbnails: [],
    selectedPages: [],
    onExport: () => { },
    onClose: () => { }
};

export default ExportModal;
