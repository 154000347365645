import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Form,
    Checkbox
} from 'semantic-ui-react';
import AuthHeader from '../Common/AuthHeader/AuthHeader';
import '../index.scss';

const Welcome = ({
    mainNavConfigByType,
    selected,
    handleChange,
    handleRedirect,
    openProfileModal,
    handleChangeWelcomeScreenOption,
    handleClose
}) => {
    const closeModal = () => {
        if (!selected) {
            handleChangeWelcomeScreenOption();
        }
        handleClose();
    };

    return (
        <div className="auth-wrapper" role="button" tabIndex="0" onClick={() => closeModal()}>
            <Form error>
                <Card fluid className="forgot_form-card" onClick={e => { e.stopPropagation(); }}>
                    <Card.Content className="card-header_content">
                        <AuthHeader />
                        <div className="card-header_content">
                            Welcome to Decksign!
                            <Card.Content className="subheader">
                                Here&apos;s
                                a few tips to get you on your way.
                                <br />
                                <br />
                                You can start to:
                                <br />
                                <ul>
                                    <li>
                                        <Button className="redirect_links" id="plan_button" onClick={() => { if (!selected) { handleChangeWelcomeScreenOption(); } handleRedirect(mainNavConfigByType.plan.path); }}> PLAN </Button>
                                        {' '}
                                        <span className="description"> a new deck using our storyboards</span>
                                    </li>
                                    <li>
                                        <Button className="redirect_links" id="build_button" onClick={() => { if (!selected) { handleChangeWelcomeScreenOption(); } handleRedirect(mainNavConfigByType.build.path); }}>BUILD</Button>
                                        {' '}
                                        <span className="description"> a new deck from scratch</span>
                                    </li>
                                    <li>
                                        <Button className="redirect_links" id="share_button" onClick={() => { if (!selected) { handleChangeWelcomeScreenOption(); } handleRedirect(mainNavConfigByType.share.path); }}> SHARE</Button>
                                        {' '}
                                        <span className="description">an existing</span>
                                        {' '}
                                    </li>

                                </ul>
                                or go to
                                {' '}
                                <Button className="redirect_links" id="manage_button" onClick={() => { if (!selected) { handleChangeWelcomeScreenOption(); } handleRedirect(mainNavConfigByType.manage.path); }}> MANAGE </Button>
                                {' '}
                                to import an existing .pptx presentation.
                                <br />
                                {' '}
                                <br />
                                Your first deck is on us (up to 100 pages) and allows you to use
                                {' '}
                                <br />
                                all of Decksign&apos;s
                                {' '}
                                functionalities without restrictions or time limit.
                                {' '}
                                <br />
                                {' '}
                                <br />

                                Beyond that, you can suscribe to Decksign by adding a credit
                                {' '}
                                <br />
                                {' '}
                                card and selecting a plan in your
                                {' '}
                                <Button className="redirect_links" id="profile_button" onClick={() => { if (!selected) { handleChangeWelcomeScreenOption(); } openProfileModal(); handleRedirect(mainNavConfigByType.manage.path); }}> profile</Button>
                                {' '}
                                .

                            </Card.Content>
                            <Card.Content extra className="card-body_content">
                                <div className="keep-showing-wrapper">
                                    <Checkbox label="Keep showing this screen when I sign in." onChange={(e, { checked }) => handleChange(checked)} defaultChecked />
                                </div>
                            </Card.Content>

                        </div>
                    </Card.Content>

                </Card>
            </Form>

        </div>
    );
};

Welcome.propTypes = {
    mainNavConfigByType: PropTypes.shape({
        build: PropTypes.shape({
            path: PropTypes.string
        }),
        plan: PropTypes.shape({
            path: PropTypes.string
        }),
        share: PropTypes.shape({
            path: PropTypes.string
        }),
        manage: PropTypes.shape({
            path: PropTypes.string
        })
    }),
    selected: PropTypes.bool,
    handleRedirect: PropTypes.func,
    handleChange: PropTypes.func,
    handleChangeWelcomeScreenOption: PropTypes.func
}.isRequired;

export default Welcome;
