import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'semantic-ui-react';
import HandledImage from '../../HandledImage/HandledImage';
import './PageCard.scss';

const ContextualMenus = ({
    open, item, options, side
}) => (
    <Dropdown
        options={options}
        open={open}
        id={`${item._id}-left`}
        className={`PageCard__Dropdown__Contexual__${side}`}
        icon={null}
    />
);

ContextualMenus.propTypes = {
    item: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ).isRequired,
    side: PropTypes.oneOf(['Left', 'Right']).isRequired
};

const getClassName = fullWidth => (fullWidth ? 'FullWidthPageCard' : 'PageCard');

class PageCard extends PureComponent {
    static propTypes = {
        fullWidth: PropTypes.bool,
        imageOptions: PropTypes.object,
        isDisabled: PropTypes.bool,
        isSelected: PropTypes.bool,
        item: PropTypes.object.isRequired,
        leftSideMenuOptions: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                key: PropTypes.string,
                type: PropTypes.string,
                onClick: PropTypes.func
            })
        ),
        onClick: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        rightSideMenuOptions: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                key: PropTypes.string,
                type: PropTypes.string,
                onClick: PropTypes.func
            })
        ),
        withContextualMenu: PropTypes.bool,
        withTextContent: PropTypes.bool,
        className: PropTypes.string
    }

    static defaultProps = {
        fullWidth: false,
        imageOptions: {},
        isDisabled: false,
        isSelected: false,
        leftSideMenuOptions: [],
        onClick: () => {},
        onMouseEnter: () => {},
        onMouseLeave: () => {},
        rightSideMenuOptions: [],
        withContextualMenu: false,
        withTextContent: false,
        className: ''
    }

    constructor(props) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.state = {
            open: false
        };
    }

    onMouseEnter() {
        const { onMouseEnter, item } = this.props;
        this.setState(
            {
                open: true
            },
            () => onMouseEnter(item)
        );
    }

    onMouseLeave() {
        const { onMouseLeave, item } = this.props;
        this.setState(
            {
                open: false
            },
            () => onMouseLeave(item)
        );
    }

    render() {
        const {
            item,
            onClick,
            isDisabled,
            isSelected,
            leftSideMenuOptions,
            rightSideMenuOptions,
            imageOptions,
            fullWidth = false,
            withTextContent = false,
            withContextualMenu = false,
            className
        } = this.props;

        const {
            open
        } = this.state;
        return (
            <Card
                className={`${className} ${getClassName(fullWidth)}${isSelected ? ` isSelected${fullWidth ? '--fullWidth' : ''}` : ''}`}
                key={item._id}
                onClick={() => onClick(item)}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <Card.Content className={`${getClassName(fullWidth)}__Content${isDisabled ? '--isDisabled' : ''}`}>
                    <HandledImage
                        className={`${getClassName(fullWidth)}__Content__Image`}
                        src={item.thumbnailUrl}
                        size="huge"
                        retry
                        isDisabled={isDisabled}
                        waitForUpdate={item.waitForUpdate}
                        {...imageOptions}
                    />

                    {withTextContent && (
                        <div className={`Textbox ${getClassName(fullWidth)}__Content__Textbox`}>
                            <Card.Header className={`${getClassName(fullWidth)}__Content__Textbox__Header`}>
                                {item.header ? item.header : 'Header'}
                            </Card.Header>
                            {fullWidth && item.content &&
                                    item.content.map(content => (
                                        <Card.Description className={`${getClassName(fullWidth)}__Content__Textbox__Description`}>
                                            {content}
                                        </Card.Description>
                                    ))}
                        </div>
                    )}

                    {withContextualMenu && leftSideMenuOptions.length > 0 &&
                        (
                            <ContextualMenus open={open} item={item} options={leftSideMenuOptions} side="Left" />
                        )}
                    {withContextualMenu && rightSideMenuOptions.length > 0 &&
                        (
                            <ContextualMenus open={open} item={item} options={rightSideMenuOptions} side="Right" />
                        )}
                </Card.Content>
            </Card>
        );
    }
}

export default PageCard;
