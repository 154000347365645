import React from 'react';
import PropTypes from 'prop-types';
import { Image, Card } from 'semantic-ui-react';
import logo from '../../../../assets/images/decksign-logo.svg';

const AuthHeader = ({
    onRedirect,
    path,
    pathName
}) => (
    <>
        <Card.Header className="card-header">
            <div className="card-header-logo">
                <Image
                    src={logo}
                />
            </div>
            {pathName && (
                <div>
                    <a
                        className="signin_redirectlink"
                        role="button"
                        tabIndex="0"
                        onClick={() => onRedirect(path)}
                    >
                        {pathName}
                    </a>

                </div>
            )
            }
        </Card.Header>
    </>

);
AuthHeader.propTypes = {
    onRedirect: PropTypes.func,
    path: PropTypes.string,
    pathName: PropTypes.string
};
AuthHeader.defaultProps = {
    path: null,
    pathName: null,
    onRedirect: null

};

export default AuthHeader;
