import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const TextboxIcon = ({ iconProps }) => (
    <Icon {...iconProps}>
        <i className="material-icons text-icon">
            view_headline
        </i>
    </Icon>
);

TextboxIcon.propTypes = {
    iconProps: PropTypes.object
};

TextboxIcon.defaultProps = {
    iconProps: PropTypes.object
};

export default TextboxIcon;
