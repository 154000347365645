import React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    List,
    Input,
    Form
} from 'semantic-ui-react';

const AutoplaySettings = ({
    onSpaceBarAutoplayControlSettingChange,
    onBlackPageEndSettingChange,
    onAutoplayDelayChange,
    autoplayDelay,
    spaceBarAutoplayControlSetting,
    pageOptions,
    endPresentationPage
}) => (
    <Form.Group className="autoplaysettings__container__form">
        <List className="autoplaysettings__container">

            <List.Item className="autoplaysettings__container__item">
                <div>
                    <span>Play / Pause with space bar</span>
                </div>
                <Form.Field>
                    <Checkbox
                        checked={spaceBarAutoplayControlSetting}
                        onChange={(e, data) => onSpaceBarAutoplayControlSettingChange(data.checked)}
                    />
                </Form.Field>
            </List.Item>

            <List.Item className="autoplaysettings__container__item">
                <div>
                    <span>Delay between pages (seconds)</span>

                </div>
                <Form.Field className="autoplaysettings__container__item__interval">

                    <Input
                        type="number"
                        value={autoplayDelay}
                        onChange={(e, data) => {
                            if (data.value.length > 4) {
                                return;
                            } if (parseInt(data.value) < 1) {
                                return;
                            }
                            onAutoplayDelayChange(parseInt(data.value));
                        }}
                        onBlur={e => {
                            if (!e.target.value) {
                                onAutoplayDelayChange(30);
                            }
                        }}
                    />

                </Form.Field>
            </List.Item>
            <List.Item className="autoplaysettings__container__item">
                <div>
                    <span>End presentation with</span>
                </div>
                <Form.Field className="autoplaysettings__container__item__dropdownselection">
                    <Form.Dropdown
                        fluid
                        selection
                        onChange={(e, data) => onBlackPageEndSettingChange(data.value)}
                        options={pageOptions}
                        defaultValue={endPresentationPage}
                    />

                </Form.Field>
            </List.Item>
        </List>
    </Form.Group>
);

AutoplaySettings.propTypes = {
    onSpaceBarAutoplayControlSettingChange: PropTypes.func.isRequired,
    onBlackPageEndSettingChange: PropTypes.func.isRequired,
    onAutoplayDelayChange: PropTypes.func.isRequired,
    autoplayDelay: PropTypes.number,
    spaceBarAutoplayControlSetting: PropTypes.bool,
    pageOptions: PropTypes.array.isRequired,
    endPresentationPage: PropTypes.string.isRequired
};

AutoplaySettings.defaultProps = {
    autoplayDelay: 30,
    spaceBarAutoplayControlSetting: false
};

export default AutoplaySettings;
