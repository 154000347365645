export default list => {
    const values = list.reduce((current, value) => ({
        ...current,
        [value]: value
    }), {});

    const handler = {
        get(target, key) {
            if (target[key]) {
                return target[key];
            }
            throw new Error(`Trying to access a invalid key ${key}`);
        }
    };

    return new Proxy(values, handler);
};
