import React from 'react';
import PropTypes from 'prop-types';

const withEmptyStateFlag = EmptyStateComponent => {
    const EmptyState = ({ value, className, ...otherProps }) => (
        <EmptyStateComponent
            {...otherProps}
            value={value || ''}
            className={!value ? `${className} empty` : className}
        />
    );

    EmptyState.propTypes = {
        value: PropTypes.any.isRequired,
        className: PropTypes.string
    };

    EmptyState.defaultProps = {
        className: ''
    };

    return EmptyState;
};

export default withEmptyStateFlag;
