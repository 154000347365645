import React from 'react';
import PropTypes from 'prop-types';
import {
    Progress,
    Icon,
    Header,
    Grid
} from 'semantic-ui-react';

import ProgressLabelFactory from './ProgressLabel/ProgressLabelFactory';

const calculatePercent = file => Math.round(((file.completedPages - 1) / file.totalPages) * 100);

const FileItem = ({ index, file, removeFile }) => (
    <Grid className="Import__content__file__item">
        <Grid.Column verticalAlign="middle" className="ImportModal" width={6}>
            <Header className="Import__content__file__item__fileName" size="tiny">{file.name}</Header>
        </Grid.Column>
        {file.progress === 'parsing' && file.totalPages > 0 && (
            <React.Fragment>
                <Grid.Column verticalAlign="middle" textAlign="center" width={4}>
                    {`Page ${file.completedPages} of ${file.totalPages}`}
                </Grid.Column>
                <Grid.Column className="Import__content__file__item__progress" verticalAlign="middle" textAlign="center" width={4}>
                    <Progress size="small" percent={calculatePercent(file)} color="blue" progress />
                </Grid.Column>
            </React.Fragment>
        )}
        {(file.progress !== ProgressLabelFactory.STATUSES.parsing || file.totalPages <= 0) && (
            <Grid.Column textAlign="center" width={8}>
                {ProgressLabelFactory.build(file)}
            </Grid.Column>
        )}
        <Grid.Column verticalAlign="middle" textAlign="center" width={2}>
            <Icon className="Import__content__file__item__remove__icon" name="close" onClick={() => removeFile(index)} />
        </Grid.Column>
    </Grid>
);

FileItem.propTypes = {
    index: PropTypes.number.isRequired,
    file: PropTypes.object.isRequired,
    removeFile: PropTypes.func.isRequired
};

export default FileItem;
