import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash/round';
import toNumber from 'lodash/toNumber';

const fixedPrecision = (value, precision) => {
    if (value === '') {
        return value;
    }
    const rounded = round(value, precision);
    // Necessary to input decimal numbers
    if (rounded === toNumber(value)) {
        return value;
    }
    return rounded;
};

const toNumberOrEmpty = value => (value === '' ? '' : toNumber(value));

const withInputToDataTransformer = transform => WrappedComponent => {
    const wrapped = props => {
        const {
            onChange
        } = props;
        if (onChange) {
            const applyTransform = (event, data) => {
                const { value } = data;
                const transformedValue = transform(value, props);
                const transformedData = {
                    ...data,
                    value: transformedValue
                };
                onChange(event, transformedData);
            };
            return (
                <WrappedComponent {...props} onChange={(e, d) => applyTransform(e, d)} />
            );
        }
        return <WrappedComponent {...props} />;
    };
    wrapped.propTypes = {
        onChange: PropTypes.func
    };
    wrapped.defaultProps = {
        onChange: () => { }
    };
    return wrapped;
};

const withPrecisionTransformer = WrappedComponent => {
    const wrapped = props => {
        const {
            error,
            onChange,
            precision,
            value
        } = props;
        const applyTransform = (event, data) => {
            const { value: dataValue } = data;
            const transformedValue = fixedPrecision(dataValue, precision);
            const transformedData = {
                ...data,
                value: transformedValue
            };
            if (onChange) {
                onChange(event, transformedData);
            }
        };
        const transformedValue = error ? value : fixedPrecision(value, precision);
        return (
            <WrappedComponent {...props} value={transformedValue} onChange={(e, d) => applyTransform(e, d)} />
        );
    };
    wrapped.propTypes = {
        error: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        precision: PropTypes.number
    };
    wrapped.defaultProps = {
        error: false,
        onChange: () => { },
        precision: 2,
        value: undefined
    };
    return wrapped;
};

const withCustomTransformers = WrappedComponent => {
    const wrapped = props => {
        const { transformers = [], ...cleanedProps } = props;
        const {
            error,
            onChange,
            value
        } = props;
        const inputToDataTransforms = transformers.map(transform => transform.inputToData);
        const dataToInputTransforms = transformers.map(transform => transform.dataToInput).reverse();
        const applyTransformList = (valueToTransform, transformList) => transformList.reduce(
            (updatedValue, transform) => transform(updatedValue, props),
            valueToTransform
        );
        const applyOnChangeTransforms = (event, data) => {
            const { value: input } = data;
            const transformedInput = applyTransformList(input, inputToDataTransforms);
            const transformedData = {
                ...data,
                value: transformedInput
            };
            if (onChange) {
                onChange(event, transformedData);
            }
        };
        const transformedValue = error ? value : applyTransformList(value, dataToInputTransforms);
        return (
            <WrappedComponent
                {...cleanedProps}
                value={transformedValue}
                onChange={(e, d) => applyOnChangeTransforms(e, d)}
            />
        );
    };

    wrapped.propTypes = {
        error: PropTypes.bool,
        onChange: PropTypes.func,
        transformers: PropTypes.arrayOf(PropTypes.shape({
            inputToData: PropTypes.func,
            dataToInput: PropTypes.func
        })),
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool
        ])
    };
    wrapped.defaultProps = {
        error: false,
        onChange: () => { },
        transformers: [],
        value: undefined
    };
    return wrapped;
};

const withFixedPrecision = withPrecisionTransformer;
const toNumberInput = withInputToDataTransformer(toNumberOrEmpty);

export {
    toNumberInput,
    withFixedPrecision,
    withCustomTransformers
};
