import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

class EyeDropper extends Component {
    static propTypes = {
        onColorPick: PropTypes.func.isRequired,
        canvasId: PropTypes.string.isRequired,
        fabricCanvas: PropTypes.bool
    }

    static defaultProps = {
        fabricCanvas: false
    }

    constructor(props) {
        super(props);
        this.ctx = null;
        this.canvasRect = null;
    }

    getUpperCanvas = () => {
        const { fabricCanvas, canvasId } = this.props;
        return fabricCanvas ? document.getElementsByClassName('upper-canvas')[0] : document.getElementById(canvasId);
    }

    onColorPick = event => {
        const { onColorPick } = this.props;
        this.getUpperCanvas().classList.remove('colorPicking');
        event.stopPropagation();
        const x = event.clientX - this.canvasRect.x;
        const y = event.clientY - this.canvasRect.y;
        const rgba = this.ctx.getImageData(x, y, 1, 1).data;
        document.removeEventListener('mousedown', this.onColorPick, true);
        onColorPick({
            rgb: {
                r: rgba[0],
                g: rgba[1],
                b: rgba[2],
                a: rgba[3]
            }
        });
    };

    onEyeDropStart = () => {
        const { canvasId } = this.props;
        this.getUpperCanvas().classList.add('colorPicking');
        const canvas = document.getElementById(canvasId);
        this.canvasRect = canvas.getBoundingClientRect();
        this.ctx = canvas.getContext('2d');
        document.addEventListener('mousedown', this.onColorPick, true);
    }

    render() {
        return (
            <Button basic size="small" icon="eye dropper" onClick={this.onEyeDropStart} />
        );
    }
}

export default EyeDropper;
