import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Pagination from './Pagination/Pagination';
import './Carousel.scss';

export default class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeElementIndex: props.initialIndexToDisplay - 1 || 0 };
        this.setActiveElementIndex = this.setActiveElementIndex.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { elements, initialIndexToDisplay } = this.props;
        const { activeElementIndex } = this.state;
        if (elements.length !== prevProps.elements.length && elements.length < activeElementIndex + 1) {
            this.setState({ activeElementIndex: initialIndexToDisplay - 1 }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    setActiveElementIndex(activePageNumber) {
        this.setState({
            activeElementIndex: activePageNumber - 1
        });
    }

    render() {
        const { elements, initialIndexToDisplay } = this.props;
        const { activeElementIndex } = this.state;
        const activeElement = elements[activeElementIndex];
        return (
            <React.Fragment>
                <Grid columns={8}>
                    <Grid.Column width={16}>
                        {activeElement}
                    </Grid.Column>
                    <Grid.Row>
                        <Grid.Column />
                        <Grid.Column width={12}>
                            <Pagination
                                initialIndexToDisplay={initialIndexToDisplay}
                                totalPages={elements.length}
                                onPageChange={activePage => this.setActiveElementIndex(activePage)}
                                hidePrevArrowWhenUseless
                                hideNextArrowWhenUseless
                            />
                        </Grid.Column>
                        <Grid.Column />
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }
}

Carousel.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.node),
    initialIndexToDisplay: PropTypes.number
};

Carousel.defaultProps = {
    elements: [],
    initialIndexToDisplay: 1
};
