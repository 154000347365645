import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'semantic-ui-react';
import ActionBtn from './ActionBtn/ActionBtn';
import Menu from './Menu/Menu';
import './SubNav.scss';

const SubNav = ({
    sectionActionLabel,
    sectionAction,
    config,
    section,
    currentShare,
    setShareLevel,
    currentAudience,
    isAudienceLevel

}) => (
    <nav className="App__subNav">
        {sectionAction && (
            <ActionBtn
                action={sectionAction}
                label={sectionActionLabel}
            />
        )}
        {config && (
            <Menu
                config={config}
                section={section}
            />
        )}
        {section === 'share' && (
            <Breadcrumb size="large">
                {currentShare.description && (
                    <>
                        <Breadcrumb.Section>Deck</Breadcrumb.Section>
                        <Breadcrumb.Divider icon="right chevron" />
                        <Breadcrumb.Section
                            link
                            onClick={() => setShareLevel(false)}
                        >
                            {currentShare.description}
                        </Breadcrumb.Section>
                    </>
                )}
                {currentAudience.audience && isAudienceLevel && (
                    <>
                        <Breadcrumb.Divider icon="right chevron" />
                        <Breadcrumb.Section
                            link
                        >
                            {currentAudience.audience}
                        </Breadcrumb.Section>
                    </>
                )}
            </Breadcrumb>
        )}
    </nav>
);

SubNav.defaultProps = {
    sectionAction: null,
    sectionActionLabel: null,
    config: null,
    currentShare: null,
    currentAudience: null,
    isAudienceLevel: null
};

SubNav.propTypes = {
    config: PropTypes.array,
    section: PropTypes.string.isRequired,
    sectionActionLabel: PropTypes.string,
    sectionAction: PropTypes.func,
    setShareLevel: PropTypes.func.isRequired,
    currentShare: PropTypes.object,
    currentAudience: PropTypes.object,
    isAudienceLevel: PropTypes.bool
};

export default SubNav;
