import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import FileItem from './FileItem/FileItem';

const PanelContent = ({ className, files, removeFile }) => (
    <List verticalAlign="middle" divided className={`Import__content ${className}`}>
        {files.map((file, index) => (
            <List.Item className="Import__content__file">
                <FileItem index={index} key={file.name} file={file} removeFile={removeFile} />
            </List.Item>
        ))}
    </List>
);

PanelContent.propTypes = {
    className: PropTypes.string.isRequired,
    files: PropTypes.array.isRequired,
    removeFile: PropTypes.func.isRequired
};

export default PanelContent;
