(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("semantic-ui-react"), require("react-dnd"), require("react-dnd-html5-backend"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define("decksignReactComponent", ["React", "semanticUIReact", "ReactDnD", "ReactDnDHTML5Backend", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["decksignReactComponent"] = factory(require("react"), require("semantic-ui-react"), require("react-dnd"), require("react-dnd-html5-backend"), require("react-router-dom"));
	else
		root["decksignReactComponent"] = factory(root["React"], root["semanticUIReact"], root["ReactDnd"], root["ReactDndHTML5Backend"], root["react-router-dom"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__84639__, __WEBPACK_EXTERNAL_MODULE__88641__, __WEBPACK_EXTERNAL_MODULE__58418__, __WEBPACK_EXTERNAL_MODULE__76507__, __WEBPACK_EXTERNAL_MODULE__56128__) => {
return 