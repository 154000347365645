import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, Icon, Form
} from 'semantic-ui-react';
import TableDefinition from './Definition/Definition';
import TableStructureFieldSets from './TableStructureFieldSets/TableStructureFieldSets';
import './TableForm.scss';

class TableForm extends React.Component {
    static propTypes = {
        defaultTableStructures: PropTypes.shape({
            horizontal: PropTypes.shape({
                columns: PropTypes.number,
                hasBandedColumns: PropTypes.bool,
                hasBandedRows: PropTypes.bool,
                hasHeaderColumn: PropTypes.bool,
                hasHeaderRow: PropTypes.bool,
                hasTotalColumn: PropTypes.bool,
                hasTotalRow: PropTypes.bool,
                rows: PropTypes.number
            }),
            vertical: PropTypes.shape({
                columns: PropTypes.number,
                hasBandedColumns: PropTypes.bool,
                hasBandedRows: PropTypes.bool,
                hasHeaderColumn: PropTypes.bool,
                hasHeaderRow: PropTypes.bool,
                hasTotalColumn: PropTypes.bool,
                hasTotalRow: PropTypes.bool,
                rows: PropTypes.number
            })
        }).isRequired,
        onAddTable: PropTypes.func.isRequired,
        onUpdateTablePlaceholder: PropTypes.func.isRequired,
        pageWidth: PropTypes.number,
        pageHeight: PropTypes.number,
        isUpdatingPlaceholder: PropTypes.bool
    }

    static defaultProps = {
        isUpdatingPlaceholder: false,
        pageWidth: 1200,
        pageHeight: 800
    }

    constructor(props) {
        super(props);

        this.handleDefinitionInput = this.handleDefinitionInput.bind(this);
        this.handleStructureChange = this.handleStructureChange.bind(this);
        this.validateTableRowsColumns = this.validateTableRowsColumns.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        const {
            defaultTableStructures: {
                vertical: {
                    columns,
                    hasBandedColumns,
                    hasBandedRows,
                    hasHeaderColumn,
                    hasHeaderRow,
                    hasTotalColumn,
                    hasTotalRow,
                    rows
                }
            },
            pageWidth,
            pageHeight
        } = props;

        this.state = {
            settings: {
                hasBandedColumns,
                hasBandedRows,
                hasHeaderColumn,
                hasHeaderRow,
                hasTotalColumn,
                hasTotalRow,
                mainAxis: 'vertical',
                maxTableWidth: pageWidth - 200,
                maxTableHeight: pageHeight - 200
            },
            rows,
            columns
        };
    }

    static TableStructureFieldSets = TableStructureFieldSets

    handleDefinitionInput(event, data) {
        this.setState({
            [data.id]: parseInt(data.value === '' || data.value < 0 ? '0' : data.value)
        });
    }

    handleStructureChange(settings) {
        const {
            columns: originalStateColumns,
            settings: {
                mainAxis: originalAxis
            },
            rows: originalStateRows
        } = this.state;
        if (settings.mainAxis !== originalAxis) {
            const {
                defaultTableStructures
            } = this.props;
            const {
                columns,
                hasBandedColumns,
                hasBandedRows,
                hasHeaderColumn,
                hasHeaderRow,
                hasTotalColumn,
                hasTotalRow,
                rows
            } = defaultTableStructures[settings.mainAxis];
            const {
                columns: originalPropsColumns,
                rows: originalPropsRows
            } = defaultTableStructures[originalAxis];

            const stateUpdate = {
                settings: {
                    ...settings,
                    hasBandedColumns,
                    hasBandedRows,
                    hasHeaderColumn,
                    hasHeaderRow,
                    hasTotalColumn,
                    hasTotalRow
                }
            };
            if (originalPropsColumns === originalStateColumns && originalPropsRows === originalStateRows) {
                stateUpdate.columns = columns;
                stateUpdate.rows = rows;
            }

            this.setState(stateUpdate);
        } else {
            this.setState({
                settings
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const {
            pageWidth,
            pageHeight,
            isUpdatingPlaceholder,
            onUpdateTablePlaceholder,
            onAddTable
        } = this.props;
        if (isUpdatingPlaceholder) {
            onUpdateTablePlaceholder({ ...this.state, pageWidth, pageHeight });
        } else {
            onAddTable({ ...this.state });
        }
    }

    validateTableRowsColumns() {
        const { rows, columns } = this.state;
        if (rows <= 0 || rows === undefined) {
            return false;
        }
        if (columns <= 0 || columns === undefined) {
            return false;
        }
        return true;
    }

    render() {
        const {
            rows, columns, settings: {
                hasBandedColumns,
                hasBandedRows,
                hasHeaderColumn,
                hasHeaderRow,
                hasTotalColumn,
                hasTotalRow,
                mainAxis
            }
        } = this.state;
        const {
            pageHeight,
            pageWidth
        } = this.props;

        return (
            <Form className="TableForm" onSubmit={this.handleSubmit}>
                <TableDefinition
                    handleDefinitionInput={this.handleDefinitionInput}
                    rowsNumber={rows}
                    columnsNumber={columns}
                />
                <TableStructureFieldSets
                    hasBandedColumns={hasBandedColumns}
                    hasBandedRows={hasBandedRows}
                    hasHeaderColumn={hasHeaderColumn}
                    hasHeaderRow={hasHeaderRow}
                    hasTotalColumn={hasTotalColumn}
                    hasTotalRow={hasTotalRow}
                    mainAxis={mainAxis}
                    onStructureChange={this.handleStructureChange}
                    pageHeight={pageHeight}
                    pageWidth={pageWidth}
                />
                <Form.Group className="TableForm__actions">
                    <Button
                        className={rows > 0 && columns > 0 ?
                            'TableForm__btn TableForm__btn--add' :
                            'TableForm__btn TableForm__btn--greyed'}
                        onClick={this.handleSubmit}
                        disabled={!this.validateTableRowsColumns()}
                        icon
                        labelPosition="left"
                    >
                        <Icon name="add" />
                        Add Table
                    </Button>
                </Form.Group>
            </Form>
        );
    }
}

export default TableForm;
