import React from 'react';
import PropTypes from 'prop-types';
import {
    List,
    Header,
    Grid
} from 'semantic-ui-react';
import Feedback from './Feedback/Feedback';

const Feedbacks = ({
    feedbacks,
    onNegativeFeedback,
    onPositiveFeedback,
    onRemoveFeedback,
    permission
}) => (
    <div className="feedbacks__section">
        <Header>
            <Header.Content className="feedbacks__header--content">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header>
                                <Header.Content>
                                    Feedback
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Header.Content>
        </Header>
        <List className="feedbacks__section__list">
            {
                Object.keys(feedbacks).map(key => (
                    <Feedback
                        key={key}
                        value={feedbacks[key]}
                        label={key}
                        disabled={!permission}
                        onNegativeFeedback={onNegativeFeedback}
                        onPositiveFeedback={onPositiveFeedback}
                        onRemoveFeedback={onRemoveFeedback}
                    />
                ))
            }
        </List>
    </div>
);

Feedbacks.propTypes = {
    feedbacks: PropTypes.shape({
        relevance: PropTypes.number,
        representation: PropTypes.number,
        agreement: PropTypes.number
    }),
    onNegativeFeedback: PropTypes.func.isRequired,
    onPositiveFeedback: PropTypes.func.isRequired,
    onRemoveFeedback: PropTypes.func.isRequired,
    permission: PropTypes.bool
};

Feedbacks.defaultProps = {
    feedbacks: {
        relevance: 0,
        representation: 0,
        agreement: 0
    },
    permission: true
};

export default Feedbacks;
