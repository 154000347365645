import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';

const Menu = ({ config, section }) => (
    <ul className="App__subNav__list">
        {config.filter(({ accessible = true }) => accessible).map(({ path, label }) => (
            <li
                className={`App__subNav__list__item App__subNav__list__item--${camelCase(label)}`}
                key={`App__subNav-list-item-${label}`}
            >
                <NavLink
                    exact
                    to={`/${section}/${path}`}
                    className="App__subNav__list__link"
                    activeClassName="App__subNav__list__link--selected"
                >
                    <FormattedMessage
                        id={`Common.SubNav.NavLink.${label}`}
                        defaultMessage={label}
                    />
                </NavLink>
            </li>
        ))}
    </ul>
);

Menu.propTypes = {
    config: PropTypes.array.isRequired,
    section: PropTypes.string.isRequired
};

export default Menu;
