import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title/Title';
import './EditableTitle.scss';

const UNTITLED = 'UNTITLED';

const createTitle = str => ((str || '').trim() === '' ? UNTITLED : str);
const removeUntitled = str => (str === UNTITLED ? '' : str);

class EditableTitle extends React.Component {
    constructor(props) {
        super(props);
        this.saveTitle = this.saveTitle.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);

        const title = removeUntitled((props.deck || {}).title);

        this.state = {
            title,
            focused: false,
            active: !!props.deck
        };
    }

    handleChange(_, { value }) {
        this.setState({
            title: value
        });
    }

    handleFocus() {
        this.setState({
            focused: true
        });
    }

    handleBlur() {
        this.saveTitle();
        this.setState({
            focused: false
        });
    }

    saveTitle() {
        const { changeTitle, deck } = this.props;
        const { title } = this.state;
        const deckTitle = removeUntitled((deck || {}).title);
        if (deckTitle !== title) {
            changeTitle(createTitle(title));
        }
    }

    render() {
        const { customCssClasses, disabled, withDefaultStyle } = this.props;
        const {
            title, focused, active
        } = this.state;
        const displayTitle = focused ? removeUntitled(title) : createTitle(title);
        return active ? (
            <Title
                customCssClasses={customCssClasses}
                disabled={disabled}
                withDefaultStyle={withDefaultStyle}
                title={displayTitle}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
            />
        ) :
            '';
    }
}

EditableTitle.defaultProps = {
    deck: null,
    customCssClasses: null,
    withDefaultStyle: null
};

EditableTitle.propTypes = {
    deck: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
    customCssClasses: PropTypes.string,
    changeTitle: PropTypes.func.isRequired,
    withDefaultStyle: PropTypes.bool
};

export default EditableTitle;
