import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-unknown-property */
const Line = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 55" className="Common__SideNav__Container__object__img">
        <rect width="55" height="8" x="372.774" y="220.274" fill={fill} fillRule="evenodd" transform="rotate(-45 -26.526 582.06)" />
    </svg>
);
/* eslint-enable react/no-unknown-property */

Line.propTypes = {
    fill: PropTypes.string
};

Line.defaultProps = {
    fill: ''
};

export default Line;
