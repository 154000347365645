import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Dropdown } from 'semantic-ui-react';
import './FitAndZoom.scss';

const FitAndZoom = ({ onFitClick, items, disabled }) => (
    <div className="FitAndZoom">
        <Button className="FitAndZoom__FitButton" disabled={disabled} icon onClick={onFitClick}><div className="material-icons">expand</div></Button>
        <Dropdown className="FitAndZoom__ZoomDropdown" disabled={disabled} icon={<Icon name="chevron down" />}>
            <Dropdown.Menu>
                {items.map(item => (
                    <Dropdown.Item key={item.label} text={item.label} onClick={item.onClick} />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    </div>
);

FitAndZoom.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func
    })),
    onFitClick: PropTypes.func,
    disabled: PropTypes.bool
};

FitAndZoom.defaultProps = {
    items: [],
    onFitClick: Function.prototype,
    disabled: false
};

export default FitAndZoom;
