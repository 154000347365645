import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import Navigation from './Navigation/Navigation';
import BottomToolbar from './BottomToolbar/BottomToolbar';
import DeckPage from './DeckPage/DeckPage';
import './Viewer.scss';

const sidebarVisible = true;

const Viewer = ({
    activePageId,
    onActivePageChange,
    onSpaceBarAutoplayControlSettingChange,
    onBlackPageEndSettingChange,
    onAutoplayDelayChange,
    onAutoplayToggle,
    onMouseButtonsControlSetting,
    onArrowKeysControlSetting,
    onSpaceBarBackspaceControlSetting,
    onPageUpPageDownControlSetting,
    onPositiveFeedback,
    onNegativeFeedback,
    onRemoveFeedback,
    onComment,
    onDeleteComment,
    onEditComment,
    onExportAsPDF,
    onPlay,
    resumeAutoplay,
    pauseAutoplay,
    onFullscreenToggle,
    onNextPageChange,
    pages,
    retroactions,
    information,
    sharePermissions,
    settings,
    hideBottomBar,
    shouldDisplayBlackPage,
    shouldDisplayWhitePage
}) => {
    const deckPageData = pages.find(x => x.id === activePageId) || {};
    let pageBackground = '';
    if (shouldDisplayWhitePage) {
        pageBackground = '__white';
    } else if (shouldDisplayBlackPage) {
        pageBackground = '__black';
    } else {
        pageBackground = '';
    }
    return (
        <div className="viewer__container">
            <div className="viewer__container__navigation">
                <Sidebar.Pushable as={Segment}>
                    <Sidebar visible={sidebarVisible} as={Menu} animation="overlay" icon="labeled" vertical width="thin">
                        <Navigation
                            activePageId={activePageId}
                            onActivePageChange={onActivePageChange}
                            pages={pages}
                        />
                    </Sidebar>
                </Sidebar.Pushable>
            </div>
            <div className={`viewer__container__deckpage${pageBackground || ''}`}>
                {pageBackground ? (<div>  </div>) : (
                    <DeckPage
                        id={deckPageData.id}
                        imageSrc={deckPageData.imageSrc}
                        onNextPageChange={onNextPageChange}
                        pageNumber={deckPageData.pageNumber}
                    />
                )
                }

            </div>
            {!hideBottomBar &&
                (
                    <div className="viewer__container__bottomnavbar">
                        <BottomToolbar
                            information={information}
                            onPlay={onPlay}
                            onFullscreenToggle={onFullscreenToggle}
                            onSpaceBarAutoplayControlSettingChange={onSpaceBarAutoplayControlSettingChange}
                            onBlackPageEndSettingChange={onBlackPageEndSettingChange}
                            onAutoplayDelayChange={onAutoplayDelayChange}
                            onAutoplayToggle={onAutoplayToggle}
                            onMouseButtonsControlSetting={onMouseButtonsControlSetting}
                            onArrowKeysControlSetting={onArrowKeysControlSetting}
                            onSpaceBarBackspaceControlSetting={onSpaceBarBackspaceControlSetting}
                            onPageUpPageDownControlSetting={onPageUpPageDownControlSetting}
                            onPositiveFeedback={onPositiveFeedback}
                            onNegativeFeedback={onNegativeFeedback}
                            onRemoveFeedback={onRemoveFeedback}
                            onComment={onComment}
                            onDeleteComment={onDeleteComment}
                            onEditComment={onEditComment}
                            onExportAsPDF={onExportAsPDF}
                            retroactions={retroactions}
                            settings={settings}
                            sharePermissions={sharePermissions}
                            resumeAutoplay={resumeAutoplay}
                            pauseAutoplay={pauseAutoplay}
                        />
                    </div>
                )}
        </div>
    );
};

Viewer.propTypes = {
    onSpaceBarAutoplayControlSettingChange: PropTypes.func.isRequired,
    onBlackPageEndSettingChange: PropTypes.func.isRequired,
    onAutoplayDelayChange: PropTypes.func.isRequired,
    onAutoplayToggle: PropTypes.func.isRequired,
    onMouseButtonsControlSetting: PropTypes.func.isRequired,
    onArrowKeysControlSetting: PropTypes.func.isRequired,
    onSpaceBarBackspaceControlSetting: PropTypes.func.isRequired,
    onPageUpPageDownControlSetting: PropTypes.func.isRequired,
    onPositiveFeedback: PropTypes.func.isRequired,
    onNegativeFeedback: PropTypes.func.isRequired,
    onRemoveFeedback: PropTypes.func.isRequired,
    onComment: PropTypes.func.isRequired,
    onDeleteComment: PropTypes.func.isRequired,
    onEditComment: PropTypes.func.isRequired,
    onPlay: PropTypes.func.isRequired,
    onExportAsPDF: PropTypes.func.isRequired,
    resumeAutoplay: PropTypes.func,
    pauseAutoplay: PropTypes.func,
    onFullscreenToggle: PropTypes.func.isRequired,
    retroactions: PropTypes.shape({
        feedbacks: PropTypes.shape({
            relevance: PropTypes.number,
            representation: PropTypes.number,
            agreement: PropTypes.number
        }),
        comments: PropTypes.arrayOf(PropTypes.string)
    }),
    information: PropTypes.shape({
        title: PropTypes.string,
        author: PropTypes.string,
        date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        pagesCount: PropTypes.number,
        activePageNumber: PropTypes.number
    }),
    settings: PropTypes.shape({
        autoplay: PropTypes.bool,
        autoplayDelay: PropTypes.number,
        blackPageEnd: PropTypes.bool,
        spaceBarAutoplayControlSetting: PropTypes.bool,
        mouseButtonsControlSetting: PropTypes.bool,
        arrowKeysControlSetting: PropTypes.bool,
        spaceBarBackspaceControlSetting: PropTypes.bool,
        pageUpPageDownControlSetting: PropTypes.bool
    }),
    activePageId: PropTypes.string,
    onActivePageChange: PropTypes.func.isRequired,
    onNextPageChange: PropTypes.func.isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        pageNumber: PropTypes.number,
        topic: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string
        }),
        thumbnailSrc: PropTypes.string,
        imageSrc: PropTypes.string
    })),
    sharePermissions: PropTypes.shape({
        comment: PropTypes.bool,
        feedback: PropTypes.bool,
        download: PropTypes.bool
    }),
    hideBottomBar: PropTypes.bool,
    shouldDisplayBlackPage: PropTypes.bool.isRequired,
    shouldDisplayWhitePage: PropTypes.bool.isRequired
};

Viewer.defaultProps = {
    retroactions: {
        feedbacks: {
            relevance: 0,
            representation: 0,
            agreement: 0
        },
        comments: []
    },
    information: {
        title: '',
        author: '',
        date: Date.now(),
        pagesCount: 0,
        activePageNumber: 0
    },
    settings: {
        autoPlayDelay: 30,
        autoPlay: false,
        blackPageEnd: false,
        spaceBarAutoplayControlSetting: false,
        mouseButtonsControlSetting: true,
        arrowKeysControlSetting: true,
        spaceBarBackspaceContrtolSetting: false
    },
    sharePermissions: {
        comment: false,
        feedback: false,
        download: false
    },
    activePageId: '',
    pages: [],
    hideBottomBar: false,
    resumeAutoplay: () => { },
    pauseAutoplay: () => { }
};

export default Viewer;
