import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Form,
    Grid
} from 'semantic-ui-react';
import '../index.scss';
import AuthHeader from '../Common/AuthHeader/AuthHeader';
import PasswordRequirements from '../Common/PasswordRequirements/PasswordRequirements';

const RecoverPassword = ({
    globalMessage,
    passwordError,
    passwordRequirements,
    loading,
    inputChanged,
    authNavConfig,
    submitForm,
    onRedirect,
    resendLink,
    confirmationError
}) => (
    <div className="auth-wrapper">
        <Grid>
            <Grid.Row>
                <Form error onSubmit={submitForm} autoComplete="nope">
                    <Card fluid className="signup_form-card">
                        <Card.Content>
                            <AuthHeader
                                onRedirect={onRedirect}
                                pathName="Sign In"
                                path={authNavConfig.login.path}
                            />

                            <div className="card-header_content">
                                Select a new password

                                <Card.Content className="subheader">
                                    You will need the reset code we sent to your email
                                    {' '}
                                    <br />
                                    address. If you need, we can
                                    {' '}
                                    <Button className="recovery_link" id="newCode_button" onClick={resendLink}>send a new code</Button>
                                    .
                                </Card.Content>
                            </div>

                            { globalMessage ? (
                                <>
                                    <Card.Content className="card-body_content">
                                        { globalMessage }
                                    </Card.Content>

                                </>
                            ) : (
                                <Card.Content className="card-body_content">
                                    <Form.Input
                                        autoComplete="new-password"
                                        type="text"
                                        size="huge"
                                        label="Password reset code"
                                        id="confirmation_code_field"
                                        name="recoveryCode"
                                        placeholder="enter code received by email"
                                        onChange={inputChanged('confirmationCode')}
                                        required
                                    />
                                    {confirmationError && <span className="signup_error_message">This is not a valid password reset code</span>}

                                    <Form.Input
                                        type="password"
                                        required
                                        size="huge"
                                        id="newPassword_field"
                                        label="Select a new password"
                                        onChange={inputChanged('password')}
                                        className={(passwordError) ? 'error' : ''}
                                    />

                                    <PasswordRequirements passwordRequirements={passwordRequirements} />

                                    {!globalMessage && <Button type="submit" id="password_reset_btn" loading={loading} fluid size="large" color="blue">Reset Password</Button>}
                                </Card.Content>

                            )}
                        </Card.Content>
                    </Card>
                </Form>
            </Grid.Row>
        </Grid>
    </div>
);

RecoverPassword.propTypes = {
    authNavConfig: PropTypes.shape({
        login: PropTypes.shape({
            path: PropTypes.string
        })
    }),
    checked: PropTypes.bool,
    globalMessage: PropTypes.string,

    inputChanged: PropTypes.func,
    validatePassword: PropTypes.func,
    loading: PropTypes.bool,
    logo: PropTypes.any,
    passwordError: PropTypes.bool,
    onRedirect: PropTypes.func,
    resendLink: PropTypes.func
}.isRequired;

export default RecoverPassword;
