import React from 'react';
import PropTypes from 'prop-types';
import './ActionBtn.scss';
import { Link } from 'react-router-dom';

const ActionBtn = ({ action, label }) => (window.location.pathname.split('/')[1] === 'plan' ? (
    <Link
        className="SubNav__actionBtn"
        role="button"
        to="/plan"
        onClick={action}
    >
        <i className="material-icons SubNav__actionBtn__icon">
            add
        </i>
        {label}
    </Link>

) : (
    <button
        type="button"
        className="SubNav__actionBtn"
        onClick={action}
    >
        <i className="material-icons SubNav__actionBtn__icon">
            add
        </i>
        {label}
    </button>
));

ActionBtn.propTypes = {
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};

export default ActionBtn;
