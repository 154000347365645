import React from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-dnd';
import { isEmpty } from 'lodash';
import HTML5Backend from 'react-dnd-html5-backend';
import TileLayout from './TileLayout/TileLayout';
import MixLayout from './MixLayout/MixLayout';
import './StoryboardList.scss';
import ToggleDisplay from './ToggleDisplay/ToggleDisplay';

const OPTIONS = [
    { view: 'mix', icon: 'list layout' },
    { view: 'tiles-no-details', icon: 'block layout' },
    { view: 'tiles', icon: 'columns' }
];

const renderCurrentView = (view, props) => {
    switch (view) {
        case 'mix':
            return (
                <MixLayout
                    {...props}
                />
            );
        case 'tiles-no-details':
            return (
                <TileLayout
                    {...props}
                />
            );
        case 'tiles':
            return (
                <TileLayout
                    {...props}
                />
            );
        default:
            return (
                <div>Wrong view type</div>
            );
    }
};

const getItemContent = item => ({
    header: item.topic.label,
    content: [
        item.topic.description
    ],
    ...item
});

class StoryboardList extends React.Component {
    constructor() {
        super();
        this.moveItem = this.moveItem.bind(this);
    }

    componentDidMount() {
        const { pages, selectedPage, selectPage } = this.props;

        if (pages && pages.length && !selectedPage) {
            selectPage(pages[0], pages[0].topic);
        }
    }

    moveItem(dragIndex, hoverIndex) {
        const { pages: oldPages, onChangePagesOrder } = this.props;
        const dragItem = oldPages[dragIndex];
        const pagesWithDraggedRemoved = [
            ...oldPages.slice(0, dragIndex),
            ...oldPages.slice(dragIndex + 1)
        ];
        const pages = [
            ...pagesWithDraggedRemoved.slice(0, hoverIndex),
            dragItem,
            ...pagesWithDraggedRemoved.slice(hoverIndex)
        ];

        onChangePagesOrder(pages);
    }

    render() {
        const {
            pages,
            view,
            toggleView,
            selectPage,
            selectedPage,
            isReordering,
            leftSideMenuOptions,
            rightSideMenuOptions,
            onMouseEnter,
            onMouseLeave
        } = this.props;
        return (
            <div className="Plan__storyboard__pages">
                <div className="Plan__storyboard__pages__header">
                    <div>
                        <p className="Plan__storyboard__pages__title">
                            Organize your Storyboard
                        </p>
                    </div>
                    {!isEmpty(pages) && <ToggleDisplay className="Plan__storyboard__pages__toggle" options={OPTIONS} currentView={view} toggleView={toggleView} />}
                </div>
                {!isEmpty(pages) ? renderCurrentView(view, {
                    pages: pages.map(page => getItemContent(page)),
                    selectPage,
                    selectedPage,
                    moveItem: this.moveItem,
                    isReordering,
                    leftSideMenuOptions,
                    rightSideMenuOptions,
                    onMouseEnter,
                    onMouseLeave
                }) : (
                    <Message
                        color="blue"
                        size="huge"
                        header="No topics selected."
                        content="Please select one or more topics in the Objectives section."
                    />
                )}
            </div>
        );
    }
}

StoryboardList.defaultProps = {
    leftSideMenuOptions: [],
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    pages: [],
    rightSideMenuOptions: [],
    selectedPage: null,
    view: 'tiles'
};

StoryboardList.propTypes = {
    isReordering: PropTypes.bool.isRequired,
    leftSideMenuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    onChangePagesOrder: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    pages: PropTypes.array,
    rightSideMenuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            key: PropTypes.string,
            type: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    selectedPage: PropTypes.object,
    selectPage: PropTypes.func.isRequired,
    toggleView: PropTypes.func.isRequired,
    view: PropTypes.string
};

export default DragDropContext(HTML5Backend)(StoryboardList);
