import React from 'react';
import {
    Tab, Segment, Button,
    Grid, Message
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Subscription from './BillingPane/Subscription';
import CardHolder from './BillingPane/CardHolder';
import Address from './BillingPane/Address';
import Payment from './BillingPane/Payment';
import './BillingPane.scss';

const BillingPane = ({
    subscription,
    plans,
    card,
    address,
    onSubscriptionChange,
    urlToPlans,
    children,
    isLoading,
    message,
    showSubmitButton,
    canSubmit,
    submit,
    onChangeAddress,
    onCancelClick,
    cardFormVisibility,
    addressFormVisibility,
    showCard,
    showAddress,
    hideBillingInfo,
    cardholder,
    onChangeCardHolder
}) => (
    <Tab.Pane>
        <Segment basic className="billingContent">
            {message && message.text && (
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={3} />
                        <Grid.Column>
                            <Message color={message.color} negative className="error-message">
                                {message.text}
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
            <Subscription
                subscription={subscription}
                plans={plans}
                urlToPlans={urlToPlans}
                isLoading={isLoading}
                onSubscriptionChange={onSubscriptionChange}
            />
            {!hideBillingInfo && (
                <React.Fragment>
                    <CardHolder
                        isLoading={isLoading}
                        cardFormVisibility={cardFormVisibility}
                        card={card}
                        showCard={showCard}
                    >
                        {children}
                    </CardHolder>
                    <Payment
                        isLoading={isLoading}
                        cardFormVisibility={cardFormVisibility}
                        showCard={showCard}
                        cardholder={cardholder}
                        onChangeCardHolder={onChangeCardHolder}
                    />
                    <Address
                        sLoading={isLoading}
                        addressFormVisibility={addressFormVisibility}
                        address={address}
                        onChangeAddress={onChangeAddress}
                        showAddress={showAddress}
                    />
                </React.Fragment>
            )}
            {showSubmitButton && (
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={2} />
                        <Grid.Column>
                            <Button type="submit" disabled={!canSubmit} className="formsubmit" floated="right" onClick={submit}>Confirm</Button>
                            <Button type="reset" className="formcancel" floated="right" onClick={() => onCancelClick()}>Cancel</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    </Tab.Pane>
);

BillingPane.propTypes = {
    subscription: PropTypes.string.isRequired,
    plans: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.any.isRequired,
        text: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    })).isRequired,
    address: PropTypes.shape({
        cardholder: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        country: PropTypes.string,
        state: PropTypes.string,
        city: PropTypes.string,
        postal_code: PropTypes.string
    }).isRequired,
    card: PropTypes.shape({
        brand: PropTypes.string,
        last4: PropTypes.string,
        exp_month: PropTypes.number,
        exp_year: PropTypes.number
    }).isRequired,
    cardholder: PropTypes.string.isRequired,
    onChangeCardHolder: PropTypes.func.isRequired,
    showCard: PropTypes.func,
    showAddress: PropTypes.func,
    cardFormVisibility: PropTypes.bool,
    addressFormVisibility: PropTypes.bool,
    showSubmitButton: PropTypes.bool.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    message: PropTypes.string,
    onSubscriptionChange: PropTypes.func.isRequired,
    onChangeAddress: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    hideBillingInfo: PropTypes.bool,
    urlToPlans: PropTypes.string,
    children: PropTypes.node.isRequired
};

BillingPane.defaultProps = {
    isLoading: false,
    message: 'Oops! Something happened and the request failed.',
    cardFormVisibility: false,
    addressFormVisibility: false,
    hideBillingInfo: false,
    showCard: Function.prototype,
    showAddress: Function.prototype,
    urlToPlans: ''
};

export default BillingPane;
