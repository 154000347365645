import React from 'react';
import {
    Grid, Select, Dimmer, Loader
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../BillingPane.scss';

const Subscription = ({
    subscription, plans, urlToPlans, isLoading, onSubscriptionChange
}) => (

    <Grid columns={2} className="subscription">
        <Grid.Row>
            <Grid.Column width={3}>
                <span>Subscription</span>
            </Grid.Column>
            <Grid.Column width={5}>
                {isLoading && (
                    <Dimmer active inverted>
                        <Loader inverted />
                    </Dimmer>
                )}
                <Select
                    placeholder="Select your plan"
                    onChange={(event, data) => onSubscriptionChange(data.value)}
                    value={subscription}
                    options={plans}
                    fluid
                />
                <div className="price-details">
                    {'Go to '}
                    <a href={urlToPlans}>Plans & Pricing</a>
                    {' details on website'}
                </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

Subscription.propTypes = {
    subscription: PropTypes.string.isRequired,
    plans: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.any.isRequired,
        text: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    })).isRequired,
    isLoading: PropTypes.bool,
    urlToPlans: PropTypes.string,
    onSubscriptionChange: PropTypes.func.isRequired
};

Subscription.defaultProps = {
    urlToPlans: '',
    isLoading: false
};

export default Subscription;
