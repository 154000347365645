import React from 'react';
import { Grid, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const AccountPaneRowDropdown = ({
    label,
    name,
    occupation,
    occupations,
    updateOccupation
}) => (
    <Grid.Row as="form" className={`AccountPane__Form__${name}`}>
        <Grid.Column width={3}>
            <label htmlFor={`profileModal-${name}`}>
                {label}
            </label>
        </Grid.Column>
        <Grid.Column>
            <Dropdown
                placeholder="Occupation"
                size="huge"
                label="Occupation (or primary interest)"
                onChange={(e, data) => updateOccupation(data.value)}
                selection
                required
                className="dropdown-occupation"
                value={occupation}
                options={occupations}
            />
        </Grid.Column>
    </Grid.Row>
);

AccountPaneRowDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    occupation: PropTypes.string.isRequired,
    occupations: PropTypes.array.isRequired,
    updateOccupation: PropTypes.func.isRequired
};

export default AccountPaneRowDropdown;
