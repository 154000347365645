import React from 'react';
// eslint-disable-next-line react/prop-types
export const UserLabel = ({ user }) => (user && (
    <p className="LogoDropdown__text">
        Signed in as
        {' '}
        <strong className="LogoDropdown__user">
            {user}
        </strong>
    </p>
));
