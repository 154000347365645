import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import TabbedSidebar from '../../Common/TabbedSidebar/TabbedSidebar';
import './ToolBar.scss';

const ToolBar = ({ children, panes, activePaneId }) => (
    <TabbedSidebar
        activePaneId={activePaneId}
        direction="right"
        panes={panes}
        visible
    >
        {children}
    </TabbedSidebar>
);

ToolBar.propTypes = {
    children: PropTypes.node,
    activePaneId: PropTypes.string,
    panes: PropTypes.arrayOf(PropTypes.objectOf({
        id: PropTypes.string,
        menuItem: PropTypes.string,
        paneContent: PropTypes.node
    }))
};

ToolBar.defaultProps = {
    children: null,
    activePaneId: 'tools',
    panes: [{
        id: 'tools',
        menuItem: 'toolbarPane',
        paneContent: (<Label> Toolbar </Label>)
    }]
};

export default ToolBar;
