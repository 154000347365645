import React from 'react';
import PropTypes from 'prop-types';
import MainNav from './MainNav/MainNav';
import './Header.scss';

const Header = ({
    hasSubNav,
    mainNavConfig,
    pageNumber,
    logoDropdown,
    subNav,
    editableTitle
}) => (
    <header className={hasSubNav ?
        'App__header App__header--hasSubNav' :
        'App__header'}
    >
        <div className="App__header__wrapper">
            <div className="App__header__Logo">
                {logoDropdown}
            </div>
            <div className="App__header__Title">
                <div className="App__subNav__currentDeck">
                    {editableTitle}
                </div>
            </div>
            <div className="App__header__Navigation">
                <MainNav
                    mainNavConfig={mainNavConfig}
                    pageNumber={pageNumber}
                />
            </div>
        </div>
        {hasSubNav && (
            subNav
        )}
    </header>
);

Header.defaultProps = {
    pageNumber: 1
};

Header.propTypes = {
    hasSubNav: PropTypes.bool.isRequired,
    mainNavConfig: PropTypes.object.isRequired,
    pageNumber: PropTypes.number,
    logoDropdown: PropTypes.element.isRequired,
    subNav: PropTypes.element.isRequired,
    editableTitle: PropTypes.element.isRequired
};

export default Header;
