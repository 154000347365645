import React from 'react';
import PropTypes from 'prop-types';
import './Counter.scss';

const Counter = ({ activePage, itemName, totalPages }) => (
    <React.Fragment>
        <span className="caroussel__pagination__counter__itemName">
            {itemName}
        </span>
        <span className="caroussel__pagination__counter__activePage">
            {activePage}
        </span>
        of
        <span className="caroussel__pagination__counter__totalPages">
            {totalPages}
        </span>
    </React.Fragment>
);

Counter.propTypes = {
    activePage: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    itemName: PropTypes.string,
    totalPages: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

Counter.defaultProps = {
    activePage: '',
    itemName: '',
    totalPages: ''
};

export default Counter;
