import React from 'react';
import PropTypes from 'prop-types';
import SecondaryMenu from '../../../Common/SecondaryMenu/SecondaryMenu';

const NavigationMenu = ({ activeView, isNavigationVisible, onActiveViewChange }) => {
    const options = [
        { label: 'Pages', id: 'pages', action: () => { onActiveViewChange('pages'); } },
        { label: 'Topics', id: 'topics', action: () => { onActiveViewChange('topics'); } },
        { label: 'Objects', id: 'shapes', action: () => { onActiveViewChange('shapes'); } }
    ];

    return (
        <SecondaryMenu activeOptionId={isNavigationVisible ? activeView : ''} options={options} />
    );
};

NavigationMenu.propTypes = {
    activeView: PropTypes.string.isRequired,
    isNavigationVisible: PropTypes.bool.isRequired,
    onActiveViewChange: PropTypes.func.isRequired
};

export default NavigationMenu;
