import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import './ToolGroupLayout.scss';

const semanticColumnCount = 16;

const computeRows = (children, defaultSize, columnFactor) => {
    const rows = [];
    let rowCount = 0;
    let currentRow = [];
    React.Children.forEach(children, child => {
        const size = (child.props.inputSize || defaultSize) * columnFactor;
        if (rowCount + size > semanticColumnCount) {
            rows.push(currentRow);
            currentRow = [];
            rowCount = size;
        } else {
            rowCount += size;
        }
        currentRow.push(child);
    });
    rows.push(currentRow);
    return rows;
};

const computeLayout = (children, defaultSize, title, columns) => {
    const columnFactor = Math.floor(16 / columns);
    const rows = computeRows(children, defaultSize, columnFactor);
    let indexCount = 0;
    return rows.map(row => (
        <Grid.Row key={`${title}_row_${row.length}_${indexCount}`} columns={columns}>
            { row.map(child => {
                const size = (child.props.inputSize || defaultSize) * columnFactor;
                const column = (
                    <Grid.Column
                        key={`${title}_row_${row.length}_column_${indexCount}_${size}`}
                        width={size}
                    >
                        {child}
                    </Grid.Column>
                );
                indexCount += 1;
                return column;
            }) }
        </Grid.Row>
    ));
};

const ToolGroupLayout = ({
    children, defaultSize, title, columns
}) => (
    <div className="toolbarGroup">
        {title && (
            <Header className="toolbar">
                {title}
            </Header>
        )}
        <Grid>
            {computeLayout(children, defaultSize, title, columns)}
        </Grid>
    </div>
);

ToolGroupLayout.propTypes = {
    children: PropTypes.array.isRequired,
    defaultSize: PropTypes.number,
    title: PropTypes.string,
    columns: PropTypes.number
};

ToolGroupLayout.defaultProps = {
    defaultSize: 4,
    title: '',
    columns: 16
};

export default ToolGroupLayout;
