import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Form
} from 'semantic-ui-react';
import AuthHeader from '../Common/AuthHeader/AuthHeader';

const SuccessPage = props => {
    const {
        globalMessage,
        authNavConfig,
        onRedirect,
        resendLink
    } = props;

    return (
        <div className="auth-wrapper">
            <Form error>
                <Card fluid className="forgot_form-card">
                    <Card.Content className="card-header_content">
                        <AuthHeader
                            onRedirect={onRedirect}
                            pathName="Sign In"
                            path={authNavConfig.login.path}
                        />
                        <div className="card-header_content">
                            Thank you!
                            <Card.Content className="subheader">
                                {' '}
                                We need to confirm your email before you can use Decksign.
                                <br />
                                {' '}
                                An email with a confirmation link has been
                                sent to the email
                                <br />
                                address you provided.
                                <br />
                                {' '}
                                If you need, we can
                                {' '}
                                {' '}
                                <Button className="redirect_links" id="resendCode_button" onClick={resendLink}> send a new confirmation link.</Button>
                            </Card.Content>

                        </div>

                        {globalMessage && (
                            <>
                                <Card.Content className="card-body_content_success">
                                    {globalMessage}
                                </Card.Content>

                            </>
                        )
                        }
                    </Card.Content>

                </Card>
            </Form>
        </div>
    );
};

SuccessPage.propTypes = {
    globalMessage: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
    authNavConfig: PropTypes.shape({
        login: PropTypes.shape({
            path: PropTypes.string
        })
    }).isRequired,
    onRedirect: PropTypes.func.isRequired,
    resendLink: PropTypes.func.isRequired

};

export default SuccessPage;
