import React from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import {
    Dropdown, Header, Icon, Image
} from 'semantic-ui-react';

import { withCustomTransformers } from '../Transformers/Transformers';
import { withCustomValidators } from '../Validators/Validators';
import withErrorRegistering from '../Validators/withErrorRegistering';
import withValidInputMemory from '../Validators/withValidInputMemory';
import withControlledInput from './withControlledInput';
import withActivatable from './withActivatable';

const formatOptions = options => options.map(opt => (opt.icon ?
    {
        value: opt.value,
        text: (
            <span>
                <Image className={opt.flipped ? 'list-icon flipped' : 'list-icon'} src={opt.icon} size="mini" />
            </span>
        )
    } :
    opt
));

const ListInput = props => {
    const {
        className,
        disabled,
        icon,
        label,
        options,
        ...otherProps
    } = props;

    return (
        <div className={`${className} dropdown ${disabled ? 'disabled' : ''}`}>
            {label.length > 0 ? <Header sub content={label} /> : ''}
            {icon.length > 0 && <Icon name={icon} />}
            <Dropdown
                {...otherProps}
                lazyLoad
                className={className}
                options={formatOptions(uniqBy(options, 'value'))}
                disabled={disabled}
            />
        </div>
    );
};

ListInput.defaultProps = {
    label: '',
    icon: '',
    className: '',
    disabled: false,
    options: []
};

ListInput.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired
    }))
};

const withValidation = withCustomValidators(ListInput);
const withTransformers = withCustomTransformers(withValidation);
const withMemory = withValidInputMemory(withTransformers);
const withErrorHandling = withErrorRegistering(withMemory);

export default withActivatable(withControlledInput(withErrorHandling));
