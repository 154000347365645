import React from 'react';
import PropTypes from 'prop-types';

export const AuthorizationTokenContext = React.createContext(null);

class AuthorizationTokenProvider extends React.Component {
    static propTypes = {
        getToken: PropTypes.func.isRequired,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]).isRequired
    };

    state = {
        authorizationToken: undefined
    };

    componentDidMount() {
        this.getAuthorizationToken();
    }

    componentDidUpdate() {
        this.getAuthorizationToken();
    }

    getAuthorizationToken() {
        const { getToken } = this.props;
        const { authorizationToken } = this.state;
        getToken()
            .then(token => {
                if (token === authorizationToken) {
                    return;
                }
                this.setState({
                    authorizationToken: token
                });
            });
    }

    render() {
        const { children } = this.props;
        const { authorizationToken } = this.state;
        return (
            <AuthorizationTokenContext.Provider value={authorizationToken}>
                {children}
            </AuthorizationTokenContext.Provider>
        );
    }
}

export default AuthorizationTokenProvider;
