import get from 'lodash/get';
import React from 'react';

import Enum from '../../../../../utility/Enum';
import DoneLabel from './DoneLabel/DoneLabel';
import FailedLabel from './FailedLabel/FailedLabel';
import ParsingLabel from './ParsingLabel/ParsingLabel';
import ReadyLabel from './ReadyLabel/ReadyLabel';
import RecoveringLabel from './RecoveringLabel/RecoveringLabel';

export default class ProgressLabelFactory {
    static STATUSES = Enum([
        'failed',
        'lost',
        'parsing',
        'ready',
        'completed',
        'recovering'
    ])

    static build(file) {
        switch (get(file, 'progress')) {
            case this.STATUSES.completed:
                return <DoneLabel />;
            case this.STATUSES.parsing:
                return <ParsingLabel />;
            case this.STATUSES.ready:
                return <ReadyLabel />;
            case this.STATUSES.recovering:
                return <RecoveringLabel />;
            case this.STATUSES.failed:
            case this.STATUSES.lost:
            default:
                return <FailedLabel />;
        }
    }
}
