import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Counter from './Counter/Counter';

export default class PaginationWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = { activePage: props.initialIndexToDisplay };
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleNextPageChange = this.handleNextPageChange.bind(this);
        this.handlePrevPageChange = this.handlePrevPageChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { totalPages } = this.props;
        const { activePage } = this.state;
        if (totalPages !== prevProps.totalPages && totalPages < activePage) {
            this.setState({ activePage: prevProps.initialIndexToDisplay }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    get isPrevArrowDisplayed() {
        const {
            hidePrevArrowWhenUseless
        } = this.props;

        const {
            activePage
        } = this.state;

        return !hidePrevArrowWhenUseless || activePage !== 1;
    }

    get isNextArrowDisplayed() {
        const {
            hideNextArrowWhenUseless,
            totalPages
        } = this.props;

        const {
            activePage
        } = this.state;

        return !hideNextArrowWhenUseless || activePage !== totalPages;
    }

    handlePageChange(activePage) {
        const { onPageChange } = this.props;
        this.setState({ activePage }, () => onPageChange(activePage));
    }

    handleNextPageChange() {
        const { totalPages } = this.props;
        const { activePage } = this.state;
        this.handlePageChange(activePage < totalPages ? activePage + 1 : activePage);
    }

    handlePrevPageChange() {
        const { activePage } = this.state;
        this.handlePageChange(activePage > 1 ? activePage - 1 : activePage);
    }

    render() {
        const { totalPages } = this.props;
        const { activePage } = this.state;
        return (
            <Grid
                columns={4}
                verticalAlign="middle"
                className="caroussel__pagination"
            >
                <Grid.Column
                    textAlign="left"
                    className="caroussel__pagination__prevPage"
                >
                    {this.isPrevArrowDisplayed && (
                        <Button
                            circular
                            icon="arrow left"
                            size="mini"
                            onClick={this.handlePrevPageChange}
                        />
                    )}
                </Grid.Column>
                <Grid.Column
                    width={8}
                    textAlign="center"
                    className="caroussel__pagination__counter"
                >
                    <Counter
                        activePage={totalPages !== 0 ? activePage : 0}
                        itemName="page"
                        totalPages={totalPages}
                    />
                </Grid.Column>
                <Grid.Column
                    textAlign="right"
                    className="caroussel__pagination__nextPage"
                >
                    {this.isNextArrowDisplayed && (
                        <Button
                            circular
                            icon="arrow right"
                            size="mini"
                            onClick={this.handleNextPageChange}
                        />
                    )}
                </Grid.Column>
            </Grid>
        );
    }
}

PaginationWrapper.propTypes = {
    totalPages: PropTypes.number.isRequired,
    hidePrevArrowWhenUseless: PropTypes.bool,
    hideNextArrowWhenUseless: PropTypes.bool,
    initialIndexToDisplay: PropTypes.number,
    onPageChange: PropTypes.func.isRequired
};

PaginationWrapper.defaultProps = {
    hidePrevArrowWhenUseless: false,
    hideNextArrowWhenUseless: false,
    initialIndexToDisplay: 1
};
