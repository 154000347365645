import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-unknown-property */
const Callout = ({ fill }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 56" className="Common__SideNav__Container__object__img">
        <polygon id="Polygon" points="25 32 48 28 55 56" fill={(fill) || 'url(#callout-a)'} />
        <ellipse id="Ellipse" cx="32" cy="28" rx="32" ry="16" fill={(fill) || 'url(#callout-a)'} />
    </svg>
);
/* eslint-enable react/no-unknown-property */

Callout.propTypes = {
    fill: PropTypes.string
};

Callout.defaultProps = {
    fill: ''
};

export default Callout;
