import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import HandledImage from '../../../../HandledImage/HandledImage';

const Page = ({
    onActivePageChange,
    id,
    pageNumber,
    thumbnailSrc,
    isSelected
}) => (
    <List.Item className="navigation__page__section" onClick={() => onActivePageChange(id)}>
        <div className="navigation__page__section_pageNumberId"><span className={isSelected ? 'navigation__page-selected navigation__page__section_pageNumber' : 'navigation__page__section_pageNumber'}>{pageNumber}</span></div>
        <div className="navigation__page__img"><HandledImage className={isSelected ? 'navigation__page-selected' : ''} src={thumbnailSrc} /></div>
    </List.Item>
);

Page.propTypes = {
    onActivePageChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    pageNumber: PropTypes.number,
    thumbnailSrc: PropTypes.string,
    isSelected: PropTypes.bool
};

Page.defaultProps = {
    id: '',
    pageNumber: 0,
    thumbnailSrc: '',
    isSelected: false
};

export default Page;
