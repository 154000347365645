import React from 'react';
import {
    Button, Form,
    Grid, Dimmer, Loader
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import InteractiveInput from '../../../BuildSection/Toolbar/Tools/InteractiveInput/InteractiveInput';
import '../BillingPane.scss';

const Payment = ({
    isLoading, cardFormVisibility, showCard, cardholder, onChangeCardHolder

}) => (
    <Grid columns={2} className="payment">
        <Grid.Row>
            <Grid.Column width={3} />
            <Grid.Column width={5}>
                {isLoading && (
                    <Dimmer active inverted>
                        <Loader inverted />
                    </Dimmer>
                )}
                {!cardFormVisibility ?
                    <Button type="button" onClick={() => showCard()}>{cardholder}</Button> :
                    (
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Form.Input
                                        label="Name on Card"
                                        value={cardholder}
                                        required
                                        inputType="string"
                                        control={InteractiveInput}
                                        onChange={(e, data) => onChangeCardHolder(data.value)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

Payment.propTypes = {
    isLoading: PropTypes.bool,
    cardFormVisibility: PropTypes.bool,
    showCard: PropTypes.func,
    cardholder: PropTypes.string.isRequired,
    onChangeCardHolder: PropTypes.func.isRequired

};

Payment.defaultProps = {
    isLoading: false,
    cardFormVisibility: false,
    showCard: Function.prototype
};

export default Payment;
