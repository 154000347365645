import React from 'react';
import PropTypes from 'prop-types';
import {
    Accordion,
    Icon,
    List,
    Checkbox
} from 'semantic-ui-react';

import Shape from './Shape/Shape';
import './ShapesLayer.scss';

class LayerShapes extends React.Component {
    constructor(props) {
        super(props);
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.state = {
            currentShapeHoverId: null,
            hoverRelativePosition: null,
            isCollapsed: props.isCollapsed
        };
    }

    componentDidUpdate(prevProps) {
        const { isCollapsed } = this.props;
        const { isCollapsed: current } = this.state;
        if (isCollapsed !== prevProps.isCollapsed && isCollapsed !== current) {
            // eslint-disable-next-line
            this.setState({
                isCollapsed
            });
        }
    }

    toggleVisibility(event) {
        const { onCollapseToggle } = this.props;
        const { isCollapsed } = this.state;
        this.setState({ isCollapsed: !isCollapsed });
        onCollapseToggle(event);
    }

    hoverChange = (hoverShapeId, hoverRelativePosition) => {
        this.setState({
            currentShapeHoverId: hoverShapeId,
            hoverRelativePosition
        });
    }

    handleShapeDrop = (sourceId, sourceParentGroupPath, targetParentGroupPath) => {
        const { onSelectAndGroupObjects, onShapeDrop } = this.props;
        const { currentShapeHoverId, hoverRelativePosition } = this.state;
        if (hoverRelativePosition === 'over') {
            onSelectAndGroupObjects([sourceId, currentShapeHoverId]);
        } else {
            onShapeDrop(
                sourceId,
                currentShapeHoverId,
                hoverRelativePosition,
                sourceParentGroupPath,
                targetParentGroupPath
            );
        }
    };

    render() {
        const {
            title,
            isLocked,
            onLockToggle,
            onGroupCollapseToggle,
            onShapeSelect,
            onShapesSelectionAppend,
            onShapeLockChange,
            onShapeVisibilityChange,
            onShapeDrag,
            onChangeName,
            onGroupSelectedObjects,
            onSelectAndGroupObjects,
            onShapesSelecteAndGrouped,
            selectedCanvasItems,
            shapes
        } = this.props;
        const {
            isCollapsed,
            onLocked
        } = this.state;

        return (
            <Accordion className="navigation__shapes__accordion" as={List}>
                { title && (
                    <Accordion.Title
                        active={!isCollapsed}
                        onMouseOver={() => this.setState({ onLocked: true })}
                        onMouseLeave={() => { this.setState({ onLocked: false }); }}
                        className="navigation__shapes__accordion__title"
                        as={List.Item}
                    >

                        <List.Icon
                            name="dropdown"
                            onClick={this.toggleVisibility}
                        />
                        <List.Content
                            role="button"
                            aria-pressed="true"
                            tabIndex="0"
                            onClick={this.toggleVisibility}
                            verticalAlign="middle"
                        >
                            {title}
                        </List.Content>

                        <List.Content
                            verticalAlign="middle"
                        >
                            <Checkbox
                                control="input"
                                type="checkbox"
                                name="lock"
                                onChange={onLockToggle}
                                checked={isLocked}
                                label={(
                                    <Icon>
                                        {(onLocked && !isLocked) ? (
                                            <i className="material-icons navigation__shapes__accordion__lock">
                                                lock_open
                                            </i>
                                        ) : (
                                            <i className="material-icons navigation__shapes__accordion__lock" />
                                        )}
                                        {(isLocked) ? (
                                            <i className="material-icons navigation__shapes__accordion__lock">
                                                lock
                                            </i>
                                        ) : (
                                            <i className="material-icons navigation__shapes__accordion__lock" />
                                        )}
                                    </Icon>
                                )}
                                hidden
                            />
                        </List.Content>
                    </Accordion.Title>
                )}

                <Accordion.Content active={!isCollapsed} className="navigation__shapes__accordion__content">
                    {shapes.map((data, index) => (
                        <Shape
                            key={data.id}
                            {...data}
                            index={index}
                            type={data.type}
                            onGroupCollapseToggle={onGroupCollapseToggle}
                            onShapeSelect={onShapeSelect}
                            onShapesSelectionAppend={onShapesSelectionAppend}
                            onShapeLockChange={onShapeLockChange}
                            onShapeVisibilityChange={onShapeVisibilityChange}
                            onDrag={onShapeDrag}
                            onDrop={this.handleShapeDrop}
                            onChangeName={onChangeName}
                            onGroupSelectedObjects={onGroupSelectedObjects}
                            onSelectAndGroupObjects={onSelectAndGroupObjects}
                            hoverChange={this.hoverChange}
                            isSelected={selectedCanvasItems.includes(data.id)}
                            shapes={data.shapes}
                            selectedCanvasItems={selectedCanvasItems}
                            isContainerLocked={isLocked}
                            onShapesSelecteAndGrouped={onShapesSelecteAndGrouped}
                        />
                    ))}
                </Accordion.Content>
            </Accordion>
        );
    }
}

const shapePropTypes = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    fill: PropTypes.object,
    isCollapsed: PropTypes.bool,
    isLocked: PropTypes.bool,
    isHidden: PropTypes.bool,
    shapes: PropTypes.arrayOf(PropTypes.object)
});

LayerShapes.propTypes = {
    title: PropTypes.string,
    isCollapsed: PropTypes.bool,
    isLocked: PropTypes.bool,
    onLockToggle: PropTypes.func.isRequired,
    onCollapseToggle: PropTypes.func,
    onShapeDrag: PropTypes.func,
    onShapeDrop: PropTypes.func.isRequired,
    onGroupCollapseToggle: PropTypes.func.isRequired,
    onShapeLockChange: PropTypes.func.isRequired,
    onShapeSelect: PropTypes.func.isRequired,
    onShapesSelectionAppend: PropTypes.func.isRequired,
    onShapeVisibilityChange: PropTypes.func.isRequired,
    onChangeName: PropTypes.func.isRequired,
    onGroupSelectedObjects: PropTypes.func.isRequired,
    onSelectAndGroupObjects: PropTypes.func.isRequired,
    onShapesSelecteAndGrouped: PropTypes.func.isRequired,
    selectedCanvasItems: PropTypes.arrayOf(PropTypes.string),
    shapes: PropTypes.arrayOf(shapePropTypes)
};

LayerShapes.defaultProps = {
    isCollapsed: false,
    isLocked: false,
    onCollapseToggle: () => { },
    onShapeDrag: () => { },
    selectedCanvasItems: [],
    shapes: [],
    title: ''
};

export default LayerShapes;
