import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Form,
    Message,
    Grid,
    Input,
    Dropdown
} from 'semantic-ui-react';
import '../index.scss';
import PasswordRequirements from '../Common/PasswordRequirements/PasswordRequirements';
import AuthHeader from '../Common/AuthHeader/AuthHeader';

const SignUp = ({
    globalMessage,
    nameError,
    termsError,
    emailError,
    passwordError,
    passwordRequirements,
    occupationError,
    duplicateError,
    loading,
    inputChanged,
    selectOccupation,
    checked,
    checkTerms,
    occupation,
    occupations,
    authNavConfig,
    signupSubmit,
    onRedirect

}) => (
    <div className="auth-wrapper">
        <Grid>
            <Grid.Row>
                <Form error onSubmit={signupSubmit}>
                    <Card fluid className="signup_form-card">
                        <Card.Content>

                            <AuthHeader
                                onRedirect={onRedirect}
                                pathName="Sign In"
                                path={authNavConfig.login.path}
                            />

                            <div className="card-header_content">
                                Sign up to try Decksign
                                <Card.Content className="subheader"> No obligation. No credit card required.</Card.Content>
                            </div>
                            {globalMessage ? (
                                <Card.Content className="card-body_content">
                                    <Message positive header={globalMessage} />
                                </Card.Content>
                            ) : (
                                <Card.Content className="card-body_content">

                                    <Form.Field>
                                        <label htmlFor="name_field" id="name_field_label">Preferred Name</label>
                                        <Input
                                            size="huge"
                                            onChange={inputChanged('name')}
                                            id="name_field"
                                            type="text"
                                            className={(nameError) ? 'error' : ''}
                                        />
                                    </Form.Field>
                                    {nameError && <span className="signup_error_message">Name cannot be blank</span>}

                                    <Form.Field>
                                        <label htmlFor="email_field" id="email_field_label">Email</label>
                                        <Input
                                            size="huge"
                                            type="text"
                                            onChange={inputChanged('email')}
                                            id="email_field"
                                            className={(emailError) ? 'error' : ''}
                                        />
                                    </Form.Field>
                                    {emailError && <span className="signup_error_message">This does not appear to be a valid email address</span>}
                                    {duplicateError && <span className="signup_error_message">This email address is already is use</span>}

                                    <Form.Field>
                                        <label id="password_field_label" htmlFor="password_field">Password</label>

                                        <Input
                                            type="password"
                                            size="huge"
                                            id="password_field"
                                            onChange={inputChanged('password')}
                                            className={(passwordError) ? 'error' : ''}
                                        />
                                    </Form.Field>

                                    <PasswordRequirements passwordRequirements={passwordRequirements} />

                                    <Form.Field>
                                        <label htmlFor="occupation_dropdown" id="occupation_field_label">Occupation (or primary interest)</label>
                                        <Dropdown
                                            placeholder="Occupation"
                                            size="huge"
                                            onChange={(e, data) => selectOccupation(data.value)}
                                            selection
                                            required
                                            id="occupation_dropdown"
                                            className={(occupationError) ? 'error' : ''}
                                            value={occupation}
                                            options={occupations}
                                        />
                                    </Form.Field>
                                    {occupationError && <span className="signup_error_message">You must select an occupation or primary interest</span>}

                                    <div className="card-body_terms">

                                        <Form.Checkbox
                                            required
                                            label={(
                                                <label htmlFor="terms_checkbox" id="terms">
                                                    I have read and agree to the Decksign
                                                    {' '}
                                                    <span>Terms of Service</span>
                                                </label>
                                            )}
                                            checked={checked}
                                            onChange={checkTerms}
                                        />
                                        {termsError && <span className="signup_error_message">You must agree to the Terms of Service to sign up</span>}

                                    </div>

                                    {!globalMessage && <Button type="submit" loading={loading} fluid size="large" color="blue">Sign Up</Button>}
                                </Card.Content>

                            )}
                        </Card.Content>
                    </Card>
                </Form>
            </Grid.Row>
        </Grid>
    </div>
);

SignUp.propTypes = {
    authNavConfig: PropTypes.shape({
        login: PropTypes.shape({
            path: PropTypes.string
        })
    }),
    checkTerms: PropTypes.bool,
    checked: PropTypes.bool,
    duplicateError: PropTypes.bool,
    emailError: PropTypes.bool,
    globalError: PropTypes.bool,
    globalMessage: PropTypes.string,
    passwordRequirements: PropTypes.array,
    inputChanged: PropTypes.func,
    validatePassword: PropTypes.func,
    loading: PropTypes.bool,
    logo: PropTypes.any,
    nameError: PropTypes.bool,
    occupation: PropTypes.string,
    occupationError: PropTypes.bool,
    occupations: PropTypes.array,
    passwordError: PropTypes.bool,
    selectOccupation: PropTypes.func,
    termsError: PropTypes.bool,
    signupSubmit: PropTypes.func,
    onRedirect: PropTypes.func
}.isRequired;

export default SignUp;
