import React from 'react';
import PropTypes from 'prop-types';
import { List, Button } from 'semantic-ui-react';

const Comment = ({
    message, onDeleteComment, onEditComment, comment
}) => (
    <List.Item>
        <List.Description>
            <div className="comment__message">{message}</div>

            <div className="comment__control">
                <Button
                    className="control__action__edit"
                    onClick={e => {
                        e.currentTarget.blur();
                        return onEditComment(comment);
                    }}
                >
                    EDIT
                </Button>
                <Button
                    className="control__action__delete"
                    onClick={e => {
                        e.currentTarget.blur();
                        return onDeleteComment(comment);
                    }}
                >
                    DELETE
                </Button>
            </div>
        </List.Description>
    </List.Item>
);

Comment.propTypes = {
    message: PropTypes.string,
    onDeleteComment: PropTypes.func.isRequired,
    onEditComment: PropTypes.func.isRequired,
    comment: PropTypes.object.isRequired
};

Comment.defaultProps = {
    message: ''
};

export default Comment;
