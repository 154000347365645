import React from 'react';
import PropTypes from 'prop-types';

const getShapePoints = isRight => (
    isRight ?
        '0 0 265 252 210 252' :
        '237.5 197 265 252 210 252'
);

/* eslint-disable react/no-unknown-property */
const Triangle = ({ fill, isRight }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" className="Common__SideNav__Container__object__img">
        <polygon fill={fill} fillRule="evenodd" points={getShapePoints(isRight)} transform="translate(-210 -197)" />
    </svg>
);
/* eslint-enable react/no-unknown-property */

Triangle.propTypes = {
    fill: PropTypes.string,
    isRight: PropTypes.bool
};

Triangle.defaultProps = {
    fill: '',
    isRight: false
};

export default Triangle;
