import React from 'react';
import PropTypes from 'prop-types';
import { withFixedIncrement } from './withFixedIncrement';

const withWheelIncrementHandler = WrappedComponent => {
    const WheelIncrementHandler = class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isInFocus: false
            };
            this.onWheel = this.onWheel.bind(this);
            this.onFocus = this.onFocus.bind(this);
            this.onBlur = this.onBlur.bind(this);
        }

        onWheel(event) {
            const {
                onWheel,
                handleIncrement,
                handleDecrement,
                value
            } = this.props;
            const { isInFocus } = this.state;
            const isEditable = isInFocus && value;
            if (isEditable) {
                if (event.deltaY > 0) {
                    handleIncrement(value);
                }
                if (event.deltaY < 0) {
                    handleDecrement(value);
                }
                event.preventDefault();
                onWheel(event);
            }
        }

        onFocus({ ...args }) {
            const { onFocus } = this.props;
            this.setState({
                isInFocus: true
            });
            onFocus(args);
        }

        onBlur({ ...args }) {
            const { onBlur } = this.props;
            this.setState({
                isInFocus: false
            });
            onBlur(args);
        }

        render = () => {
            const { handleDecrement, handleIncrement, ...cleanedProps } = this.props;// eslint-disable-line no-unused-vars
            return (
                <WrappedComponent
                    {...cleanedProps}
                    onWheel={this.onWheel}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                />
            );
        }
    };

    WheelIncrementHandler.propTypes = {
        onWheel: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        handleIncrement: PropTypes.func.isRequired,
        handleDecrement: PropTypes.func.isRequired
    };
    WheelIncrementHandler.defaultProps = {
        onWheel: () => { },
        onFocus: () => { },
        onBlur: () => { },
        value: undefined
    };
    return WheelIncrementHandler;
};

export default WrappedComponent => withFixedIncrement(withWheelIncrementHandler(WrappedComponent));
