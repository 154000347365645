import PropTypes from 'prop-types';
import React from 'react';
import intersectionBy from 'lodash/intersectionBy';
import {
    Button, Image, List, Tab
} from 'semantic-ui-react';

// const getLayoutItem = item => repoLayouts.find(({ name }) => name === item.name);
const PagePane = ({
    deck, currentPageIndex, configLayouts, repoLayouts, onItemClick
}) => (
    <Tab.Pane>
        <List className="InsertPageLayouts" divided>
            {configLayouts.map(type => (
                <List.Item
                    header={type.structure}
                    key={type.structure}
                    content={
                        (
                            <List className="WorkspaceHeader__insert__content__list" horizontal>
                                {(intersectionBy(type.layouts, repoLayouts, 'name') || []).map(item => (
                                    <List.Item className="WorkspaceHeader__insert__content__list__item" key={item.name}>
                                        <Button onClick={() => onItemClick(deck, item, currentPageIndex)}>
                                            <Image src={item.imageSrc} />
                                            <p>{item.displayName}</p>
                                        </Button>
                                    </List.Item>
                                ))}
                            </List>
                        )
                    }
                />
            ))}
        </List>
    </Tab.Pane>
);
PagePane.propTypes = {
    deck: PropTypes.object.isRequired,
    currentPageIndex: PropTypes.number.isRequired,
    configLayouts: PropTypes.arrayOf(PropTypes.object).isRequired,
    repoLayouts: PropTypes.arrayOf(PropTypes.object).isRequired,
    onItemClick: PropTypes.func.isRequired
};

export default PagePane;
